import { AutoComplete, Form, Input } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { ReactElement } from 'react';

import FormItemWithLabel from '../../../components/Form/FormItemWithLabel';
import getPath from '../../../utils/getPath';

type PayeeInputProps = {
  payees: string[];
  path: NamePath;
  onChange: (value: string) => void;
};
function PayeeInput({ onChange, path, payees }: PayeeInputProps): ReactElement {
  const options = payees.map((item) => ({ label: item, value: item }));

  return (
    <>
      <div key={payees.length} hidden>
        {payees.map((item, index) => (
          <Form.Item key={item} initialValue={item} name={getPath(path, 'payee', index)}>
            <Input type="text" />
          </Form.Item>
        ))}
      </div>
      <FormItemWithLabel initialValue={payees[0]} label="摘要" name="payee_input" path={path}>
        <AutoComplete options={options} onChange={onChange}>
          <Input />
        </AutoComplete>
      </FormItemWithLabel>
    </>
  );
}

export default PayeeInput;
