import { FileExcelOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import { format } from 'date-fns';
import { Workbook } from 'exceljs';
import { ReactElement, useCallback, useState } from 'react';
import styled from 'styled-components';

import { DocumentType } from '../../generated-api';
import useOcrPageDefinition from '../../hooks/useOcrPageDefinition';
import useDocumentListStore from '../../store/useDocumentListStore';
import { notifyError } from '../../utils/notification';
import ocrResultPageDefinitions from './defs';
import { DocumentEntities, ResultPageDocument } from './defs/types';

function convertData(
  values: ResultPageDocument<DocumentEntities>[],
  type: DocumentEntities['type'],
  outputType: string
): Workbook {
  const converter = ocrResultPageDefinitions[type].excelDataConverters?.find((converter) => {
    return converter.labelName === outputType;
  })?.converter;
  if (!converter) {
    notifyError('エラーが発生しました。');
    throw new Error(`Error occurred in convert(). not found converter in ocrResultPageDefinitions[${type}].`);
  }
  return converter(values);
}

interface Props {
  type: DocumentType;
  groupId: string;
}
export const DownloadExcelButton = ({ groupId, type }: Props): ReactElement => {
  const [outputType, setOutputType] = useState<string>();
  const { excelDataConverters } = useOcrPageDefinition(type);
  const { ocrResults } = useDocumentListStore(groupId);
  const outputTypeChoice = ocrResultPageDefinitions[type].excelDataConverters?.map((item) => item.labelName);
  const onChange = useCallback(
    (value) => {
      setOutputType(value);
    },
    [setOutputType]
  );
  const saveExcelHandler = useCallback(async () => {
    if (!outputType) {
      notifyError('出力形式を選択してください。');
      return;
    }
    if (ocrResults?.[0]) {
      const workbook = convertData(ocrResults, type, outputType);
      const uint8Array = await workbook.xlsx.writeBuffer();
      const blob = new Blob([uint8Array], { type: 'application/octet-binary' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      const today = format(Date.now(), 'yyyyMMdd');
      a.download = `${today}_${outputType}.xlsx`;
      a.click();
      a.remove();
    }
  }, [ocrResults, outputType, type]);

  return (
    <>
      {excelDataConverters && excelDataConverters.length > 0 && (
        <Wrap>
          <SelectStyle placeholder="出力形式" onChange={onChange}>
            {outputTypeChoice?.map((item) => (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            ))}
          </SelectStyle>
          <Button icon={<FileExcelOutlined />} type="primary" onClick={saveExcelHandler}>
            Excel出力
          </Button>
        </Wrap>
      )}
    </>
  );
};

const Wrap = styled.div`
  display: flex;
  gap: 8px;
  padding: 20px 0;
`;

const SelectStyle = styled(Select)`
  min-width: 320px;
`;
