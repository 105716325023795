import { DocumentType } from '../generated-api';

const ocrTypeNameDefinitions: Record<DocumentType, string> = {
  [DocumentType.FurusatoTax]: 'ふるさと納税',
  [DocumentType.MedicalBill]: '医療費領収書',
  [DocumentType.Bankbook]: '通帳',
  [DocumentType.CardStatement]: 'カード明細',
  [DocumentType.WithholdingSlip]: '(ベータ版)源泉徴収票',
  [DocumentType.Receipt]: '領収書',
  [DocumentType.PaymentRecord]: '(ベータ版)支払い調書',
  [DocumentType.Invoice]: '(ベータ版)請求書',
  [DocumentType.DualHorizontalReceipt]: '領収書',
  [DocumentType.DualVerticalReceipt]: '領収書',
  [DocumentType.GeneralTable]: '(ベータ版)表データ',
  [DocumentType.OcrError]: '読み取りエラー',
};
export default ocrTypeNameDefinitions;
