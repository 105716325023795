import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { format, parseISO } from 'date-fns';
import { ReactElement } from 'react';

import { CompanyUsage } from '../../generated-api';

type CompanyUsageTableData = Pick<CompanyUsage, 'month' | 'bill' | 'bill_with_tax'> & {
  tax: number;
  confirmed: boolean;
};

type Props = {
  companyUsageDataSource: CompanyUsageTableData[] | undefined;
  loading: boolean;
};

const CompanyUsageTable = ({ companyUsageDataSource, loading }: Props) => {
  const columns: ColumnsType<CompanyUsageTableData> = [
    {
      title: 'ご利用月',
      dataIndex: 'month',
      render: (value, record) => {
        const formattedDate = format(parseISO(value), 'yyyy/M');
        if (record.confirmed) {
          return formattedDate;
        } else {
          return '（未確定）' + formattedDate;
        }
      },
      width: 300,
      align: 'right',
    },
    {
      title: '利用料金（税抜き）',
      dataIndex: 'bill',
      render: renderColumn,
      width: 300,
      align: 'right',
    },
    {
      title: '消費税',
      dataIndex: 'tax',
      render: renderColumn,
      width: 300,
      align: 'right',
    },
    {
      title: '利用料金（税込み）',
      dataIndex: 'bill_with_tax',
      render: renderColumn,
      width: 300,
      align: 'right',
    },
  ];

  return (
    <Table
      bordered={true}
      columns={columns}
      dataSource={companyUsageDataSource}
      loading={loading}
      pagination={false}
      rowKey="month"
    />
  );
};

function renderColumn(value: number, record: CompanyUsageTableData): ReactElement {
  const formattedValue = value.toLocaleString();
  return <>{record.confirmed ? formattedValue + '円' : '（未確定）' + formattedValue + '円'}</>;
}

export default CompanyUsageTable;
