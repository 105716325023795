import { ReactNode } from 'react';
import styled from 'styled-components';

import { mediaQuery } from '../../utils/mediaQuery';
import ExternalLinks from '../ExternalLinks/ExternalLinks';
import Header from '../Header/Header';

interface Props {
  children: ReactNode;
}

const AuthPageContainer = ({ children }: Props) => {
  return (
    <>
      <Header />
      <Container>
        <Image src="/logo_login_page.png" />
        <Children>{children}</Children>
        <InfoLinkList>
          <ExternalLinks />
        </InfoLinkList>
      </Container>
    </>
  );
};

export default AuthPageContainer;

const Container = styled.div`
  width: 100%;
  height: calc(100% - 70px);
  min-height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (${mediaQuery.sp}) {
    height: 100%;
  }
`;

const Image = styled.img`
  width: 250px;
  height: 200px;
  object-fit: cover;
  margin-bottom: 48px;
  @media (${mediaQuery.sp}) {
    width: 60%;
    object-fit: contain;
    margin-bottom: 20px;
  }
`;

const InfoLinkList = styled.div`
  width: 500px;
  margin-top: 64px;
  display: flex;
  justify-content: space-between;
  @media (${mediaQuery.sp}) {
    width: 80%;
    margin-top: 10px;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
  }
`;

const Children = styled.div`
  @media (${mediaQuery.sp}) {
    width: 80%;
  }
`;
