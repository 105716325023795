import { Alert, Card, Tabs } from 'antd';
import { add, format, isThisMonth, lastDayOfMonth, parseISO, startOfMonth, sub } from 'date-fns';
import styled from 'styled-components';

import { companiesApi, companyUsageApi } from '../../api/apiClient';
import PageLayout from '../../components/Container/PageLayout';
import { CompanyUsage, DocumentType } from '../../generated-api';
import useAPIData from '../../hooks/useAPIData';
import CompanyUsageTable from './CompanyUsageTable';
import FeeTable from './FeeTable';
import StatsFormTable from './StatsFormTable';
import StatsUserTable from './StatsUserTable';

const UsagePage = () => {
  const now = new Date();
  const firstDayLastMonth = startOfMonth(sub(now, { months: 1 }));
  const lastDayLastMonth = lastDayOfMonth(firstDayLastMonth);
  const firstDayThisMonth = startOfMonth(now);
  const nextMonth = add(now, { months: 1 });
  const oneYearBefore = sub(firstDayThisMonth, { years: 1 });
  const since = format(oneYearBefore, 'yyyy-MM');
  // 利用料金別はSortKeyが'yyyy-MM'なので'2022-07'で7月分まで取得される
  // statsは'yyyy-MM-XXXXXX'なので'2022-08'で7月分まで取得される
  const companyUsageUntil = format(now, 'yyyy-MM');
  const statsUntil = format(nextMonth, 'yyyy-MM');

  const { data: companyUsageData, loading: companyUsageLoading } = useAPIData(async () => {
    const { data: companyUsages } = await companyUsageApi.companyUsageControllerGetCompanyUsage(
      since,
      companyUsageUntil
    );

    const { data: companyUsageAtNow } = await companyUsageApi.companyUsageControllerGetCompanyUsageAtNow();
    const filterdCompanyUsages = companyUsages.filter((companyUsage) => companyUsage.month != companyUsageAtNow.month);
    const companyUsagesWithNow = [...filterdCompanyUsages, companyUsageAtNow];
    companyUsagesWithNow.sort((a, b) => {
      return a.month > b.month ? -1 : 1;
    });

    //当該月の利用料金と利用枚数はテーブル用データとは別に取り出しておく
    const lastMonth = format(sub(now, { months: 1 }), 'yyyy-MM');
    const lastMonthUsage = companyUsagesWithNow.find((companyUsage) => {
      return companyUsage.month === lastMonth;
    });

    const companyUsageDataSource = companyUsagesWithNow.map((companyUsage: CompanyUsage) => {
      return {
        ...companyUsage,
        tax: companyUsage.bill_with_tax - companyUsage.bill,
        confirmed: isThisMonth(parseISO(companyUsage.month)) ? false : true,
      };
    });
    return { lastMonthUsage, companyUsageDataSource };
  });

  const { data: statisticsFormData, loading: statisticsFormLoading } = useAPIData(async () => {
    const { data: statisticsForm } = await companyUsageApi.companyUsageControllerGetStatisticsPerForm(
      since,
      statsUntil
    );
    const monthToData = new Map<string, Partial<Record<DocumentType, number>>>();
    for (const usage of statisticsForm.statistics) {
      const monthStat = monthToData.get(usage.month) ?? {};
      monthStat[usage.ocr_type] = usage.usage;
      monthToData.set(usage.month, monthStat);
    }
    const tableRows = [...monthToData.entries()].map(([month, usages]) => ({
      ...usages,
      month,
      confirmed: isThisMonth(parseISO(month)) ? false : true,
      total: Object.values(usages).reduce((previous, current) => previous + current, 0),
    }));
    return { statisticsFormDataSource: tableRows };
  });

  const { data: statisticsUserData, loading: statisticsUserLoading } = useAPIData(async () => {
    const { data: statisticsUser } = await companyUsageApi.companyUsageControllerGetStatisticsPerUser(
      since,
      statsUntil
    );
    const monthToData = new Map<string, Record<string, number>>();
    const userNamesSet = new Set<string>();
    for (const usage of statisticsUser.statistics) {
      const monthStat = monthToData.get(usage.month) ?? {};
      monthStat[usage.user_name] = usage.usage;
      monthToData.set(usage.month, monthStat);
      userNamesSet.add(usage.user_name);
    }
    const tableRows = [...monthToData.entries()].map(([month, usages]) => ({
      statsUser: usages,
      month,
      confirmed: isThisMonth(parseISO(month)) ? false : true,
    }));
    return { userNames: [...userNamesSet], statisticsUserDataSource: tableRows };
  });

  const { data: planForBillingDetail, loading: planLoading } = useAPIData(async () => {
    const { data } = await companiesApi.companyControllerGetPlanAtLastMonth();
    return data;
  });
  const hasPlan = planForBillingDetail !== null;

  const { TabPane } = Tabs;

  return (
    <PageLayout heading="利用履歴 (管理者画面)">
      <SummaryContainer>
        <Title>{format(now, 'yyyy年M月')}ご請求額</Title>
        <SubTitle>
          {format(firstDayLastMonth, 'yyyy/M/d')}～{format(lastDayLastMonth, 'yyyy/M/d')} 使用分
        </SubTitle>
        <Notes> ※使用量にはβ版機能の使用枚数も含まれます</Notes>
        <UsageItemContainer>
          <UsageItem>
            <UsageItemName>請求額</UsageItemName>
            <UsageItemValue>{companyUsageData?.lastMonthUsage?.bill_with_tax.toLocaleString() ?? '-'}</UsageItemValue>
            <UsageItemUnit>円</UsageItemUnit>
          </UsageItem>
          <UsageItem>
            <UsageItemName>使用量</UsageItemName>
            <UsageItemValue>{companyUsageData?.lastMonthUsage?.usage ?? '-'}</UsageItemValue>
            <UsageItemUnit>枚</UsageItemUnit>
          </UsageItem>
        </UsageItemContainer>
        {!planLoading && !hasPlan && <Alert message="プランが見つかりません" type="error" />}
        {hasPlan && (
          <FeeSummaryTable
            loading={planLoading && companyUsageLoading}
            plan={planForBillingDetail}
            usage={companyUsageData?.lastMonthUsage}
          />
        )}
      </SummaryContainer>

      <Title>利用履歴の詳細</Title>
      <TableContainer>
        <Tabs defaultActiveKey="1" size="large">
          <TabPane key="1" tab="月別料金">
            <CompanyUsageTable
              companyUsageDataSource={companyUsageData?.companyUsageDataSource}
              loading={companyUsageLoading}
            />
          </TabPane>
          <TabPane key="2" tab="帳票別">
            <StatsFormTable
              formDataSource={statisticsFormData?.statisticsFormDataSource}
              loading={statisticsFormLoading}
            />
          </TabPane>
          <TabPane key="3" tab="実施者別">
            <StatsUserTable
              loading={statisticsUserLoading}
              userDataSource={statisticsUserData?.statisticsUserDataSource}
              userNames={statisticsUserData?.userNames}
            />
          </TabPane>
        </Tabs>
      </TableContainer>
    </PageLayout>
  );
};

export default UsagePage;

const SummaryContainer = styled(Card)`
  width: 60%;
  margin-bottom: 32px;
`;

const FeeSummaryTable = styled(FeeTable)`
  padding-top: 24px;
  width: 300;
`;

const Title = styled.h3`
  font-size: 24px;
`;

const SubTitle = styled.div`
  font-size: 20px;
`;

const Notes = styled.div``;

const UsageItemContainer = styled.div`
  margin: 12px auto;
  width: 30%;
`;

const UsageItem = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  width: 100%;
  font-size: 12px;
`;

const UsageItemName = styled.span`
  margin-right: auto;
`;

const UsageItemValue = styled.span`
  font-size: 30px;
`;

const UsageItemUnit = styled.span`
  margin-left: 10px;
`;

const TableContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
