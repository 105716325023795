import { Form, Input } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { ReactElement } from 'react';
import styled from 'styled-components';

import { TransferDestination } from '../../../generated-api';
import getPath from '../../../utils/getPath';
import { FluctuationButton } from '../FluctuationButton';

export const DetailRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(80px, 1fr)) 70px 8rem minmax(80px, 1fr) 100px;
  grid-gap: 8px;
  align-items: baseline;
`;
const RowHeaderLabel = styled.div`
  font-weight: bold;
  padding-left: 4px;
`;

export function TransferDestinationHeader(): ReactElement {
  return (
    <DetailRow>
      <RowHeaderLabel>金融機関名</RowHeaderLabel>
      <RowHeaderLabel>支店名</RowHeaderLabel>
      <RowHeaderLabel>口座種別</RowHeaderLabel>
      <RowHeaderLabel>口座番号･記号番号(ゆうちょ)</RowHeaderLabel>
      <RowHeaderLabel>口座名義</RowHeaderLabel>
    </DetailRow>
  );
}

type Props = {
  addRow: (index: number) => void;
  hiddenRemove: boolean;
  path: NamePath;
  removeRow: (index: number) => void;
  rowId: string;
  rowIndex: number;
  transferDestination: TransferDestination;
};

function TransferDestinationRow({
  addRow,
  hiddenRemove,
  path,
  removeRow,
  rowId,
  rowIndex,
  transferDestination,
}: Props): ReactElement {
  const detailRowPath = getPath(path, 'transfer_destination', rowId);
  return (
    <>
      <DetailRow>
        <Form.Item initialValue={transferDestination.bank_name} name={getPath(detailRowPath, 'bank_name')}>
          <Input />
        </Form.Item>
        <Form.Item initialValue={transferDestination.branch_name} name={getPath(detailRowPath, 'branch_name')}>
          <Input />
        </Form.Item>
        <Form.Item initialValue={transferDestination.account_type} name={getPath(detailRowPath, 'account_type')}>
          <Input />
        </Form.Item>
        <Form.Item initialValue={transferDestination.account_number} name={getPath(detailRowPath, 'account_number')}>
          <Input />
        </Form.Item>
        <Form.Item initialValue={transferDestination.account_holder} name={getPath(detailRowPath, 'account_holder')}>
          <Input />
        </Form.Item>
        <FluctuationButton add={addRow} hiddenRemove={hiddenRemove} index={rowIndex} remove={removeRow} />
      </DetailRow>
    </>
  );
}

export default TransferDestinationRow;
