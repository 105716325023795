import { Select, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import { ReactElement, ReactNode, useMemo, useState } from 'react';
import styled from 'styled-components';

import { DocumentCardStatementEntity } from '../../../generated-api';
import { convertVariableTableFormatToCsv } from '../../../utils/convertCSVColumns';
import ensureDefined from '../../../utils/ensureDefined';
import ocrTypeNameDefinitions from '../../../utils/ocrTypeNameDefinitions';
import {
  VariableLengthInputForm,
  VariableLengthInputFormResult,
  variableLengthInputFormResultToEntity,
} from '../VariableLengthInputForm';
import { CSVConvertResult, FormComponentProps, OcrResultPageDefinition, ResultPageDocument } from './types';

function CardStatementFormInput({ entity, setChanged }: FormComponentProps<DocumentCardStatementEntity>): ReactElement {
  return <VariableLengthInputForm entity={entity} setChanged={setChanged} />;
}

function expandCardStatementTable(record: ResultPageDocument<DocumentCardStatementEntity>): ReactNode {
  if (record.error) {
    return;
  }
  const columnNames = ensureDefined(record.latest_revision.column_names);

  const rows = ensureDefined(record.latest_revision.rows);

  const data = rows.map((row) => {
    return row.reduce<Record<string, string>>((accumulator, current, index) => {
      accumulator[columnNames[index]] = current;
      return accumulator;
    }, {});
  });

  return (
    <Table dataSource={data} pagination={false}>
      {columnNames.map((name) => {
        return <Column key={name} dataIndex={name} title={name} />;
      })}
    </Table>
  );
}

function convertCardStatementCsv(data: ResultPageDocument<DocumentCardStatementEntity>[]): CSVConvertResult {
  return {
    csv: convertVariableTableFormatToCsv(data),
  };
}

type CalcSumProps = {
  record: DocumentCardStatementEntity;
};

function CalcSum({ record }: CalcSumProps) {
  const [selectColumnIndex, setSelectColumnIndex] = useState(-1);
  const value = useMemo(
    () =>
      selectColumnIndex >= 0
        ? record?.rows
            ?.map((row) => parseInt(row[selectColumnIndex]))
            .filter((value) => !isNaN(value))
            .reduce((acc, curr) => acc + curr, 0)
        : null,
    [record?.rows, selectColumnIndex]
  );

  return (
    <CalcSumContainer>
      <Select placeholder="計算対象列" style={{ width: '150px' }} onChange={setSelectColumnIndex}>
        {record.column_names?.map((name, index) => (
          <Select.Option key={index} value={index}>
            {name}
          </Select.Option>
        ))}
      </Select>
      <div className="result">
        {value?.toLocaleString('ja-JP', { currency: 'JPY' }) ?? (
          <span style={{ color: '#c3c3c3', userSelect: 'none' }}>未計算</span>
        )}
      </div>
    </CalcSumContainer>
  );
}

const cardStatementResultPageDefinition: OcrResultPageDefinition<DocumentCardStatementEntity> = {
  name: ocrTypeNameDefinitions.card_statement,
  tabs: [
    {
      tableColumns: [
        {
          dataIndex: ['latest_revision'],
          title: '合計金額計算',
          render: (record: DocumentCardStatementEntity) => <CalcSum record={record} />,
        },
      ],
      tableExpandable: {
        expandedRowRender: expandCardStatementTable,
      },
      csvColumns: {},
    },
  ],
  formComponent: CardStatementFormInput,
  formDataToEntity: (form: VariableLengthInputFormResult<DocumentCardStatementEntity>) =>
    variableLengthInputFormResultToEntity(form),
  csvDataConverters: [
    {
      converter: convertCardStatementCsv,
    },
  ],
};
export default cardStatementResultPageDefinition;

const CalcSumContainer = styled.div`
  display: flex;
  min-width: 250px;

  .result {
    display: inline-block;
    margin-left: 5px;
    padding: 4px 6px;
    flex-grow: 1;
    text-align: right;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    min-width: 50px;
  }
`;
