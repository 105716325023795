import { Alert } from 'antd';
import { useRecoilValueLoadable } from 'recoil';

import LoadingIcon from '../../components/Loading/LoadingIcon';
import { selfCompanyRepresentativeUserNameSelector } from '../../store/useCompanyStore';

export default function NotYetAgreed(): JSX.Element {
  const { contents: representativeUserName, state } = useRecoilValueLoadable(selfCompanyRepresentativeUserNameSelector);

  if (state === 'loading') {
    return <LoadingIcon />;
  }

  if (state == 'hasError') {
    return (
      <Alert
        message="アプリケーションに問題が発生している可能性があります。お手数をおかけしますが、しばらく後でもう一度お試しください。"
        type="error"
      />
    );
  }

  return <span>ログインするためには管理者（{representativeUserName}様）による利用約款への同意が必要です。</span>;
}
