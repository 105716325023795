import { Card, Tabs } from 'antd';
import { ReactNode, useMemo, useState } from 'react';

import {
  DocumentDualHorizontalReceiptEntity,
  DocumentDualVerticalReceiptEntity,
  DocumentReceiptEntity,
} from '../../../generated-api';
import ocrTypeNameDefinitions from '../../../utils/ocrTypeNameDefinitions';
import ReceiptTable from '../Receipt/ReceiptTable';
import {
  formDataToEntity as receiptFormDataToEntity,
  FormResult as ReceiptFormResult,
  ReceiptFormInputs,
  receiptsToCsv,
} from './receiptResultPageDefinition';
import { CSVConvertResult, FormComponentProps, OcrResultPageDefinition, ResultPageDocument } from './types';

const { TabPane } = Tabs;
type DocumentDualReceiptEntity = DocumentDualHorizontalReceiptEntity | DocumentDualVerticalReceiptEntity;

function DualReceiptFormComponent({
  changed,
  documentId,
  entity,
  groupId,
  setChanged,
}: FormComponentProps<DocumentDualReceiptEntity>) {
  const [tab, setTab] = useState('0');
  const formEntities: DocumentReceiptEntity[] = useMemo(
    () =>
      entity.receipts.map((receipt) => ({
        type: receipt.type,
        payment_date: receipt.payment_date,
        payee: receipt.payee,
        business_number: receipt.business_number,
        receipt_details: receipt.receipt_details,
      })),
    [entity.receipts]
  );

  return (
    <>
      <Tabs defaultActiveKey={tab} onChange={setTab}>
        {formEntities.map((form, index) => (
          <TabPane key={index} forceRender tab={`領収書${index + 1}`} tabKey={index.toString()}>
            <ReceiptFormInputs
              changed={changed}
              documentId={documentId}
              entity={form}
              groupId={groupId}
              path={['resultReceipts', index]}
              setChanged={setChanged}
            />
          </TabPane>
        ))}
      </Tabs>
    </>
  );
}

function expandDualReceiptTable(record: ResultPageDocument<DocumentDualReceiptEntity>): ReactNode {
  if (record.error) {
    return;
  }
  return (
    <>
      {record.latest_revision.receipts.map((receipt, receiptIndex) => (
        <Card key={receiptIndex}>
          <ReceiptTable receipt={receipt} />
        </Card>
      ))}
    </>
  );
}

type FormResult = {
  resultReceipts: Record<number, ReceiptFormResult>;
};
function createFormDataToEntity<T extends DocumentDualReceiptEntity>(type: T['type']): (data: FormResult) => T {
  return (data: FormResult) => {
    const receipts = Object.values(data.resultReceipts);
    const newReceipts = receipts.map((receipt) => receiptFormDataToEntity(receipt));
    return { receipts: newReceipts, type: type } as T;
  };
}

function convertDualReceiptCsv<T extends DocumentDualReceiptEntity>(data: ResultPageDocument<T>[]): CSVConvertResult {
  const receipts = data.flatMap((item) => {
    return item.latest_revision.receipts
      .map((receipt, index) => ({
        summary: {
          ファイル名: item.parsed_path.fullFileName,
          ページ数: item.page?.toString() ?? '-',
          ページ内番号: (index + 1).toString(),
          支払い日付: receipt.payment_date ?? '',
          摘要: receipt.payee?.[0] ?? '',
          事業者番号: receipt.business_number ?? '',
        },
        latest_revision: receipt,
      }))
      .filter((item) => !isEmptyReceipt(item.latest_revision));
  });
  return receiptsToCsv(receipts);
}

function isEmptyReceipt(receipt: DocumentReceiptEntity): boolean {
  if (!receipt.payment_date && !receipt.payee?.[0] && !receipt.business_number) {
    return true;
  }
  return false;
}

function dualReceiptResultPageDefinition<T extends DocumentDualReceiptEntity>(
  type: T['type'],
  name: string
): OcrResultPageDefinition<T> {
  return {
    name: name,
    tabs: [
      {
        csvColumns: {},
        tableColumns: [],
        tableExpandable: {
          expandedRowRender: expandDualReceiptTable,
        },
      },
    ],
    formComponent: DualReceiptFormComponent,
    formDataToEntity: createFormDataToEntity(type),
    csvDataConverters: [
      {
        converter: convertDualReceiptCsv,
      },
    ],
  };
}

export const dualHorizontalReceiptResultPageDefinition =
  dualReceiptResultPageDefinition<DocumentDualHorizontalReceiptEntity>(
    'dual_horizontal_receipt',
    ocrTypeNameDefinitions.dual_horizontal_receipt
  );

export const dualVerticalReceiptResultPageDefinition =
  dualReceiptResultPageDefinition<DocumentDualVerticalReceiptEntity>(
    'dual_vertical_receipt',
    ocrTypeNameDefinitions.dual_vertical_receipt
  );
