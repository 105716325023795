export function downloadCSV(fileName: string, data: string) {
  const blob = createTextBlobWithBOM(data);

  downloadBlob(fileName, blob);
}

export function downloadBlob(fileName: string, blob: Blob) {
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  a.download = fileName;

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function createTextBlobWithBOM(data: string, mime = 'text/csv') {
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const blob = new Blob([bom, data], { type: mime });

  return blob;
}
