export default function parseFilePath(fullFilePath: string): ReturnType {
  const [topLevelDirName, ...rest] = fullFilePath.split('/');
  const fullFileName = rest[rest.length - 1] ?? fullFilePath;
  const match = fullFileName.match(/^(.*)\.(.*)$/);

  if (!match) {
    // 拡張子がない場合はしょうがないので fullFileName を fileName として返す
    return {
      topLevelDirName: rest.length > 0 ? topLevelDirName : undefined,
      fullFileName: fullFileName,
      fileName: fullFileName,
    };
  } else {
    const [, fileName, ext] = match;
    return {
      topLevelDirName: rest.length > 0 ? topLevelDirName : undefined,
      fileName,
      fullFileName,
      ext,
    };
  }
}

type ReturnType = {
  topLevelDirName?: string;
  fileName: string;
  fullFileName: string;
  ext?: string;
};
