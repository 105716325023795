import { Input, InputNumber, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import ExcelJS, { Alignment, Borders, Cell, Fill, Font, Workbook } from 'exceljs';
import { ReactElement, useMemo } from 'react';

import FormItemWithLabel from '../../../components/Form/FormItemWithLabel';
import FilterIcon from '../../../components/Icons/FilterIcon';
import { DocumentMedicalBillEntity } from '../../../generated-api';
import convertCSVColumns from '../../../utils/convertCSVColumns';
import ensureDefined from '../../../utils/ensureDefined';
import ocrTypeNameDefinitions from '../../../utils/ocrTypeNameDefinitions';
import FilterDropdown from '../FilterDropdown';
import useResultFilter from '../useResultFilter';
import renderTableColumn from './renderTableColumn';
import {
  CSVConvertResult,
  FormComponentProps,
  OcrResultPageDefinition,
  OcrResultPageTableComponentProps,
  ResultPageDocument,
} from './types';

type MedicalBillStatsEntity = {
  fileName?: string;
  patientName?: string;
  hospitalName?: string;
  address?: string;
  totalAmount: number;
};

function medicalBillStatsGrouping(entity: ResultPageDocument<DocumentMedicalBillEntity>[]) {
  const result = entity.reduce<Record<string, MedicalBillStatsEntity>>((acc, curr) => {
    const {
      latest_revision: record,
      parsed_path: { fullFileName },
    } = curr;
    // 受診者, 医療機関 で groupBy して医療費の合計を計算する
    const key = `${record.patient_name}/${record.hospital_name}`;
    if (acc[key] == null) {
      acc[key] = {
        fileName: fullFileName,
        address: record.address?.trim(),
        hospitalName: record.hospital_name,
        patientName: record.patient_name,
        totalAmount: record.amount ?? 0,
        ...curr,
      };
    } else {
      acc[key].totalAmount += record.amount ?? 0;
      const addr = acc[key]?.address;
      // 住所の表示をまとめる
      // 文字数が少ないものを優先する
      if (addr != null && record.address != null && record.address.length < addr.length) {
        const trimmedAddr = record.address.trim();
        if (trimmedAddr.length > 0) {
          // 住所欄が空白でない場合のみ適用
          acc[key].address = trimmedAddr;
        }
      }
    }
    return acc;
  }, {});

  return Object.values(result).flatMap((a) => a);
}

function MedicalBillFormInputs({ entity }: FormComponentProps<DocumentMedicalBillEntity>): ReactElement {
  return (
    <>
      <FormItemWithLabel label="日付" name="date">
        <Input defaultValue={entity.date} />
      </FormItemWithLabel>
      <FormItemWithLabel label="金額" name="amount">
        <InputNumber defaultValue={entity.amount} />
      </FormItemWithLabel>
      <FormItemWithLabel label="住所" name="address">
        <Input defaultValue={entity.address} />
      </FormItemWithLabel>
      <FormItemWithLabel label="医療機関名" name="hospital_name">
        <Input defaultValue={entity.hospital_name} />
      </FormItemWithLabel>
      <FormItemWithLabel label="受診者名" name="patient_name">
        <Input defaultValue={entity.patient_name} />
      </FormItemWithLabel>
    </>
  );
}

function convertToExcelForTatsujin(values: ResultPageDocument<DocumentMedicalBillEntity>[]): Workbook {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet('医療費に係る領収書等医療費(医療費通知以外)', {
    views: [{ showGridLines: false }],
  });

  const borderStyle: Partial<Borders> = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  };
  const fontStyle: Partial<Font> = {
    name: 'メイリオ',
    size: 9,
    color: { theme: 1 },
  };
  const headerFillStyle: Fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFD9D9D9' },
  };
  const headerAlignmentStyle: Partial<Alignment> = { vertical: 'middle', wrapText: true };

  const setBaseStyle = (cell: Cell) => {
    cell.border = borderStyle;
    cell.font = fontStyle;
  };
  const setHeaderStyle = (cell: Cell) => {
    setBaseStyle(cell);
    cell.alignment = headerAlignmentStyle;
  };

  sheet.eachRow((row) => {
    row.height = 15;
  });
  // 中途半端なWidthを指定してる理由は下記URLを参照
  // https://learn.microsoft.com/en-us/previous-versions/office/developer/office-2010/cc802410(v=office.14)?redirectedfrom=MSDN
  const columnWidth = 17.62;

  const headerBusinessCode = sheet.addRow(['事業者コード', '']);
  headerBusinessCode.eachCell(setHeaderStyle);
  headerBusinessCode.getCell(1).fill = headerFillStyle;
  headerBusinessCode.getCell(2).alignment = { ...headerAlignmentStyle, horizontal: 'left' };

  const headerBusinessName = sheet.addRow(['事業者名', '']);
  headerBusinessName.eachCell(setHeaderStyle);
  headerBusinessName.getCell(1).fill = headerFillStyle;
  headerBusinessName.getCell(2).alignment = { ...headerAlignmentStyle, horizontal: 'left' };

  const headerDataName = sheet.addRow(['データ名称', '医療費に係る領収書等医療費(医療費通知以外)']);
  headerDataName.height = 35.75;
  headerDataName.eachCell((cell) => {
    setHeaderStyle(cell);
    cell.fill = headerFillStyle;
  });

  const columnRow = sheet.addRow([
    '医療を受けた方の氏名',
    '病院・薬局などの支払先の名称_上段',
    '病院・薬局などの支払先の名称_下段',
    '医療費の区分_診療_治療_該当区分',
    '医療費の区分_介護保険サービス_該当区分',
    '医療費の区分_医薬品購入_該当区分',
    '医療費の区分_その他の医療費_該当区分',
    '支払った医療費の額',
    '(4)のうち生命保険や社会保険などで補てんされる金額',
  ]);
  columnRow.height = 35.75;
  columnRow.eachCell((cell, number) => {
    setHeaderStyle(cell);
    cell.border = {
      ...borderStyle,
      top: { style: 'double' },
    };
    cell.fill = headerFillStyle;
    sheet.getColumn(number).width = columnWidth;
  });

  const stats = medicalBillStatsGrouping(values);

  const isPharmacy = (name: string) => name.includes('薬局');

  stats.forEach((value) => {
    const hospitalName = value.hospitalName ?? '';
    const row = sheet.addRow([
      value.patientName ?? '',
      hospitalName,
      '',
      isPharmacy(hospitalName) ? '' : '該当',
      '',
      isPharmacy(hospitalName) ? '該当' : '',
      '',
      value.totalAmount?.toString() ?? '',
      '',
    ]);
    row.eachCell(setBaseStyle);
    row.height = 15;
  });

  return workbook;
}

function MedicalBillStatsTable({
  loading,
  results,
  tabIndex,
}: OcrResultPageTableComponentProps<DocumentMedicalBillEntity>) {
  const record = useMemo(() => medicalBillStatsGrouping(results), [results]);
  const { applyFilter, filterItems, filteredResults } = useResultFilter(
    record,
    medicalBillResultPageDefinition,
    tabIndex
  );

  const createFilterDropDown = (dataIndex: string) => ({
    filterDropdown:
      filterItems[tabIndex][dataIndex] != null ? (
        <FilterDropdown onApply={(v) => applyFilter(tabIndex, [dataIndex], v)} />
      ) : null,
    filterIcon: () => <FilterIcon enabled={!!filterItems[tabIndex][dataIndex]} />,
  });

  return (
    <Table
      dataSource={filteredResults}
      loading={loading}
      pagination={{ defaultPageSize: 20, showSizeChanger: true, position: ['topRight', 'bottomRight'] }}>
      <Column dataIndex="patientName" {...createFilterDropDown('patientName')} title="受診者名" />
      <Column dataIndex="hospitalName" {...createFilterDropDown('hospitalName')} title="医療機関名" />
      <Column dataIndex="address" {...createFilterDropDown('address')} title="住所" />
      <Column dataIndex="totalAmount" sorter={(a: number, b: number) => a - b} title="合計金額" />
    </Table>
  );
}

const medicalBillResultPageDefinition: OcrResultPageDefinition<DocumentMedicalBillEntity> = {
  name: ocrTypeNameDefinitions.medical_bill,
  formComponent: MedicalBillFormInputs,
  tabs: [
    {
      name: '詳細',
      csvColumns: {
        ファイル名: ['parsed_path', 'fullFileName'],
        ページ数: ['page'],
        日付: ['latest_revision', 'date'],
        金額: ['latest_revision', 'amount'],
        住所: ['latest_revision', 'address'],
        医療機関名: ['latest_revision', 'hospital_name'],
        受診者名: ['latest_revision', 'patient_name'],
      },
      tableColumns: [
        { dataIndex: ['latest_revision', 'date'], title: '日付', render: renderTableColumn },
        { dataIndex: ['latest_revision', 'amount'], title: '金額', render: renderTableColumn, sort: true },
        { dataIndex: ['latest_revision', 'address'], title: '住所', render: renderTableColumn, searchFilter: true },
        {
          dataIndex: ['latest_revision', 'hospital_name'],
          title: '医療機関名',
          render: renderTableColumn,
          searchFilter: true,
        },
        {
          dataIndex: ['latest_revision', 'patient_name'],
          title: '受診者名',
          render: renderTableColumn,
          searchFilter: true,
        },
      ],
    },
    {
      name: '集計',
      csvColumns: {
        ファイル名: ['fileName'],
        受診者: ['patientName'],
        医療機関名: ['hospitalName'],
        住所: ['address'],
        合計金額: ['totalAmount'],
      },
      tableColumns: [
        { dataIndex: ['patientName'], title: '受診者名', render: renderTableColumn, searchFilter: true },
        { dataIndex: ['hospitalName'], title: '医療機関名', render: renderTableColumn, searchFilter: true },
        { dataIndex: ['address'], title: '住所', render: renderTableColumn, searchFilter: true },
        { dataIndex: ['totalAmount'], title: '合計金額', render: renderTableColumn, sort: true },
      ],
      tableComponent: MedicalBillStatsTable,
    },
  ],
  csvDataConverters: [
    {
      labelName: '詳細',
    },
    {
      labelName: '集計',
      converter: (values: ResultPageDocument<DocumentMedicalBillEntity>[]) => {
        // convertCSVColumns に渡す csvColumns が必要なので引っ張ってくる
        // FIXME: そもそも DownloadCsvButton のほうで convertCSVColumns を通す流れにすれば必要ないはず
        const { csvColumns } = medicalBillResultPageDefinition.tabs[1];
        const result = medicalBillStatsGrouping(values);
        return {
          fileNameSuffix: '集計',
          csv: convertCSVColumns(
            result.map<MedicalBillStatsEntity>((row) => ({
              fileName: row.fileName,
              patientName: row.patientName,
              hospitalName: row.hospitalName,
              address: row.address,
              totalAmount: row.totalAmount,
            })),
            csvColumns
          ),
        };
      },
    },
    {
      labelName: '両方',
      converter: (values: ResultPageDocument<DocumentMedicalBillEntity>[]) => {
        // convertCSVColumns に渡す csvColumns が必要なので引っ張ってくる
        // FIXME: そもそも DownloadCsvButton のほうで convertCSVColumns を通す流れにすれば必要ないはず
        const { csvColumns } = medicalBillResultPageDefinition.tabs[0];
        const statConverter = ensureDefined(medicalBillResultPageDefinition.csvDataConverters?.[1].converter);
        return [
          {
            fileNameSuffix: '',
            csv: convertCSVColumns(values, csvColumns),
          },
          statConverter(medicalBillStatsGrouping(values)) as CSVConvertResult,
        ];
      },
    },
  ],
  excelDataConverters: [
    {
      labelName: '達人インポートフォーマット',
      converter: convertToExcelForTatsujin,
    },
  ],
};
export default medicalBillResultPageDefinition;
