import { Button } from 'antd';
import axios from 'axios';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useAsync } from 'react-use';
import styled from 'styled-components';

import { companiesApi, usersApi } from '../../api/apiClient';
import { ContentContainer } from '../../components/Container/ContentContainer';
import { User } from '../../generated-api';
import { notifyError, notifySuccess } from '../../utils/notification';
import UserTable from './UserTable';

const UserListPage = () => {
  const history = useHistory();
  const { loading, value } = useAsync(async () => {
    try {
      const { data } = await companiesApi.companyControllerGetUserList();
      return {
        users: data.map(
          (item): User => ({
            ...item,
          })
        ),
      };
    } catch (e: unknown) {
      if (axios.isAxiosError(e) && e.response?.status == 403) {
        notifyError('閲覧権限を持っていません。');
      }
    }
  }, []);

  const createUser = useCallback(() => {
    history.push('/users/create');
  }, [history]);

  const editUser = useCallback(
    (userId: string, user: User) => {
      history.push(`/users/edit/${userId}`, { ...user });
    },
    [history]
  );

  const deleteUser = useCallback(async (userId: string) => {
    try {
      const { data: user } = await usersApi.userControllerDeleteUser(userId);
      notifySuccess(`ユーザ｢${user.name}｣を削除しました`);
    } catch (e: unknown) {
      notifyError('エラーが発生しました。');
    }
  }, []);

  return (
    <Container>
      <Heading>
        <PageTitle>組織ユーザ管理</PageTitle>
        <Button type="primary" onClick={createUser}>
          新規作成
        </Button>
      </Heading>
      <ContentContainer>
        <UserTable loading={loading} users={value?.users} onClickDelete={deleteUser} onClickEdit={editUser} />
      </ContentContainer>
    </Container>
  );
};

export default UserListPage;

const Container = styled.div`
  width: 80%;
  padding: 24px;
`;

const Heading = styled.h3`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid 2px #5a89d0;
  padding-right: 40px;
  margin-bottom: 32px;
`;

const PageTitle = styled.h3`
  font-weight: 600;
  font-size: 24px;
  color: #5a89d0;
  margin-bottom: 0;
`;
