import { SaveOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input } from 'antd';
import { FormInstance, useForm } from 'antd/lib/form/Form';
import { Store } from 'antd/lib/form/interface';
import { ComponentType, Dispatch, ReactElement, SetStateAction, useCallback, useState } from 'react';
import { useAsyncFn } from 'react-use';
import styled from 'styled-components';

import { Document } from '../../../generated-api';
import { DocumentEntities, EditModalEntity, FormComponentProps, OcrResultPageDefinition } from '../defs/types';

const StyledCard = styled(Card)`
  flex: 1 1;
  display: flex;
  flex-direction: column;

  > .ant-card-body {
    display: flex;
    flex-direction: column;
  }
`;
const SaveCloseButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledButton = styled(Button)`
  margin-left: 10px;
`;

interface Props {
  groupId: string;
  documentId: string;
  // entity の詳細な型は各ページの定義が持つためこのコンポーネントには表出しない
  entity: EditModalEntity;
  changed: boolean;
  setChanged: (newValue: boolean) => void;
  onSaveButtonClick: (newDocument: Document) => Promise<void>;
  onClose: () => void;
  setForm: Dispatch<SetStateAction<FormInstance<unknown> | undefined>>;
  def: OcrResultPageDefinition<DocumentEntities>;
  save: (values: unknown) => Promise<void>;
  redrawCount: number;
}

export default function DocumentEditForm(props: Props): ReactElement {
  const {
    changed,
    def,
    documentId,
    entity,
    groupId,
    onClose,
    onSaveButtonClick,
    redrawCount,
    save,
    setChanged,
    setForm,
  } = props;
  const [initialFormValue] = useState(entity.entity);

  const [form] = useForm<EditModalEntity['entity']>();
  setForm(form);

  const [savedState, onSavedAsync] = useAsyncFn(save, [save, onSaveButtonClick]);

  const onChange = useCallback(() => {
    setChanged(true);
  }, [setChanged]);

  const FormComponent = def.formComponent as ComponentType<FormComponentProps<unknown>>;
  return (
    <StyledCard>
      <Form<typeof initialFormValue>
        form={form}
        initialValues={initialFormValue as Store}
        onFinish={onSavedAsync}
        onValuesChange={onChange}>
        <FormComponent
          key={redrawCount}
          changed={changed}
          documentId={documentId}
          entity={entity.entity}
          groupId={groupId}
          setChanged={setChanged}
        />
        <Form.Item hidden name="type">
          <Input />
        </Form.Item>
      </Form>
      <SaveCloseButtons>
        <StyledButton onClick={onClose}>閉じる</StyledButton>
        <StyledButton
          disabled={!changed}
          htmlType="submit"
          loading={savedState.loading}
          type="primary"
          onClick={form.submit}>
          <SaveOutlined /> 保存
        </StyledButton>
      </SaveCloseButtons>
    </StyledCard>
  );
}
