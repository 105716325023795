import { useCallback, useEffect, useRef, useState } from 'react';

export default function useInterval(callback: () => void | Promise<void>, ms: number) {
  const timer = useRef(-1);
  const [timerRunning, setTimerRunning] = useState(false);

  const start = () => {
    if (timer.current === -1) {
      callback();
      timer.current = window.setInterval(() => {
        callback();
      }, ms);
      setTimerRunning(true);
    }
  };

  const clear = useCallback(() => {
    if (timer.current !== -1) {
      clearInterval(timer.current);
    }
    timer.current = -1;
    setTimerRunning(false);
  }, [timer]);

  useEffect(() => {
    return () => {
      clear();
    };
  }, [clear, timer]);

  return [start, clear, timerRunning] as const;
}
