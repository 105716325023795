import { useCallback } from 'react';
import { atomFamily, useRecoilState } from 'recoil';

type Param = {
  groupId: string;
  documentId: string;
};

const bankBookSelectedColumnNameAtom = atomFamily<string | null, Param>({
  key: 'bankBookSelectedColumnNameAtom',
  default: null,
});

export default function useBankbookStore(groupId: string, documentId: string) {
  const [selectedColumnName, setBankBookValue] = useRecoilState(
    bankBookSelectedColumnNameAtom({ groupId, documentId })
  );

  const setSelectedColumnName = useCallback(
    (value: string | undefined) => {
      setBankBookValue(value ?? null);
    },
    [setBankBookValue]
  );

  return {
    selectedColumnName,
    setSelectedColumnName,
  };
}
