import { useCallback, useMemo } from 'react';
import { atomFamily, DefaultValue, selectorFamily, useRecoilState } from 'recoil';

import { documentApi } from '../api/apiClient';
import parseFilePath from '../components/Lib/parseFilePath';
import { Document, PatchDocumentRequest } from '../generated-api';
import { DocumentEntities, ResultPageDocument } from '../page/OcrResult/defs/types';

const documentListAtom = atomFamily<ResultPageDocument[] | null, string>({
  default: null,
  key: 'documentListAtom',
});

type Param = {
  groupId: string;
  documentId: string;
};

const getDocument = selectorFamily<ResultPageDocument<DocumentEntities> | undefined, Param>({
  key: 'useDocumentStore/getDocument',
  get:
    ({ documentId, groupId }) =>
    ({ get }) => {
      const documentList = get(documentListAtom(groupId));

      const document = documentList?.find((doc) => doc.document_id === documentId);

      return document;
    },
  set:
    ({ documentId, groupId }) =>
    ({ get, set }, newValue) => {
      if (newValue instanceof DefaultValue) {
        return newValue;
      }

      if (newValue == null) {
        return;
      }
      const documentList = get(documentListAtom(groupId))?.slice();

      const documentIndex = documentList?.findIndex((doc) => doc.document_id === documentId);
      if (documentList == null || documentIndex == null || documentIndex === -1) {
        return;
      }

      documentList.splice(documentIndex, 1, newValue);

      set(documentListAtom(groupId), documentList);
    },
});

export default function useDocumentListStore(groupId: string) {
  const [ocrResults, setOcrResults] = useRecoilState(documentListAtom(groupId));

  const fetchDocumentList = useCallback(async () => {
    const data = await getDocumentListByGroupId(groupId);
    setOcrResults(data);
  }, [groupId, setOcrResults]);

  const updateDocument = useCallback(
    async (documentId: string, editedResult: DocumentEntities) => {
      const newDocument = await patchDocument(groupId, documentId, {
        edited_ocr_result: editedResult,
      });
      const document: ResultPageDocument<DocumentEntities> = {
        ...newDocument,
        parsed_path: parseFilePath(newDocument.user_file_path),
      };

      setOcrResults(
        (prevResults) =>
          prevResults?.map((result) => {
            if (result.document_id == document.document_id) {
              return document;
            }
            return result;
          }) ?? null
      );
    },
    [groupId, setOcrResults]
  );

  const deleteDocument = useCallback(
    async (documentId: string) => {
      await deleteDocumentById(groupId, documentId);
      await fetchDocumentList();
    },
    [fetchDocumentList, groupId]
  );

  return useMemo(
    () => ({
      ocrResults,
      totalPageCount: ocrResults?.length ?? null,
      actions: {
        fetchDocumentList,
        updateDocument,
        deleteDocument,
      },
    }),
    [ocrResults, fetchDocumentList, updateDocument, deleteDocument]
  );
}

export function useDocument<T extends DocumentEntities>(groupId: string, documentId: string) {
  const [document, setDocument] = useRecoilState(getDocument({ groupId, documentId }));

  // FIXME: こういうセッターじゃなくて本当は recoil 側で API のデータを管理するようして更新すればいいはず
  const setPatchedDocument = useCallback(
    (document: Document) => {
      const documentEntity: ResultPageDocument<DocumentEntities> = {
        ...document,
        parsed_path: parseFilePath(document.user_file_path),
      };
      setDocument(documentEntity);
    },
    [setDocument]
  );

  return { document: document as ResultPageDocument<T> | undefined, setPatchedDocument };
}

async function patchDocument(groupId: string, documentId: string, document: PatchDocumentRequest) {
  const { data } = await documentApi.documentControllerPatchDocument(groupId, documentId, document);

  return data;
}

async function deleteDocumentById(groupId: string, documentId: string) {
  await documentApi.documentControllerDeleteDocument(groupId, documentId);
}

async function getDocumentListByGroupId(groupId: string): Promise<ResultPageDocument[]> {
  const { data: documents } = await documentApi.documentControllerGetDocuments(groupId);

  return documents
    .sort((lhs, rhs) => lhs.user_file_path.localeCompare(rhs.user_file_path) || (lhs.page ?? -1) - (rhs.page ?? -1))
    .map((document) => ({ ...document, parsed_path: parseFilePath(document.user_file_path) }));
}
