import {
  CloseOutlined,
  FileSearchOutlined,
  HomeOutlined,
  MenuOutlined,
  PayCircleOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  SolutionOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { Divider, List } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { mediaQuery } from '../../utils/mediaQuery';
import ExternalLinks from '../ExternalLinks/ExternalLinks';
import AdminCheckLink from './AdminCheckLink';

type Item = { path: string; title: string; requireAdmin?: boolean; icon: ReactElement };
const pageData: Item[] = [
  { path: '/', title: 'ホーム', requireAdmin: false, icon: <HomeOutlined /> },
  { path: '/documents/search', title: '過去読み取り結果', requireAdmin: false, icon: <FileSearchOutlined /> },
  { path: '/usage', title: '利用履歴', requireAdmin: true, icon: <PayCircleOutlined /> },
  { path: '/users', title: '組織ユーザ管理', requireAdmin: true, icon: <TeamOutlined /> },
  { path: '/settings', title: '設定', requireAdmin: true, icon: <SettingOutlined /> },
];

const staticPageData: Item[] = [
  { path: '/help', title: 'ヘルプ', icon: <QuestionCircleOutlined /> },
  { path: '/consultation', title: '無料相談会', icon: <SolutionOutlined /> },
  // { path: '/announcements', title: 'お知らせ' },
];

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);

  const onCollapse = (collapsed: boolean) => {
    setCollapsed(collapsed);
  };

  return (
    <Container breakpoint="md" collapsed={collapsed} collapsedWidth="66" onCollapse={onCollapse}>
      <Children>
        <InternalLinks>
          {collapsed ? (
            <ItemDiv>
              <MenuOutlined style={{}} onClick={() => setCollapsed(false)} />
            </ItemDiv>
          ) : (
            <CloseButton>
              <CloseOutlined onClick={() => setCollapsed(true)} />
            </CloseButton>
          )}
          <List
            dataSource={pageData}
            renderItem={(item) => {
              return (
                <List.Item>
                  <ItemDiv>
                    <AdminCheckLink path={item.path} requireAdmin={item.requireAdmin}>
                      {collapsed ? item.icon : item.title}
                    </AdminCheckLink>
                  </ItemDiv>
                </List.Item>
              );
            }}
          />
          <Divider />
          <List
            dataSource={staticPageData}
            renderItem={(item) => {
              return (
                <List.Item>
                  <ItemDiv>
                    <PageLink to={item.path}>{collapsed ? item.icon : item.title}</PageLink>
                  </ItemDiv>
                </List.Item>
              );
            }}
          />
        </InternalLinks>
        {!collapsed && (
          <Info>
            <ExternalLinks />
          </Info>
        )}
      </Children>
    </Container>
  );
};

export default Sidebar;

const Container = styled(Sider)`
  background-color: #f0f0f0;
  padding: 16px 24px;
`;

const Children = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  white-space: nowrap;
`;

const ItemDiv = styled.div`
  @media (${mediaQuery.sp}) {
    margin: 0 auto;
    text-align: center;
  }
`;

const InternalLinks = styled.div``;

const CloseButton = styled.div`
  text-align: end;
`;

const PageLink = styled(Link)`
  color: #000;
`;

const Info = styled(List.Item)`
  font-size: 12px;
  padding: 6px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;
