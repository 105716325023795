import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useSearchParam } from 'react-use';

import { DocumentType } from '../../generated-api';

export type OcrResultSearchParam = {
  type?: DocumentType;
  user?: string;
  since?: string;
  until?: string;
  fileName?: string;
};

export default function useSearchQuery() {
  const navigate = useHistory();
  const documentTypeQuery = useSearchParam('type') as DocumentType | null;

  const userIdQuery = useSearchParam('user');
  const sinceQuery = useSearchParam('since');
  const untilQuery = useSearchParam('until');
  const fileNameQuery = useSearchParam('fileName');

  const [documentType, setDocumentType] = useState<DocumentType | undefined>(documentTypeQuery ?? undefined);
  const [userId, setUserId] = useState<string | undefined>(userIdQuery ?? undefined);
  const [since, setSince] = useState<string | undefined>(sinceQuery ?? undefined);
  const [until, setUntil] = useState<string | undefined>(untilQuery ?? undefined);
  const [fileName, setFileName] = useState<string | undefined>(fileNameQuery ?? undefined);

  const setQuery = useCallback(
    (param: OcrResultSearchParam) => {
      const paramRecord: Record<string, string> = param;
      const query = Object.keys(paramRecord).reduce(
        (acc, curr) =>
          paramRecord[curr] == null
            ? acc
            : {
                ...acc,
                [curr]: paramRecord[curr],
              },
        {}
      );

      const searchParam = new URLSearchParams(query);

      setDocumentType(param.type);
      setUserId(param.user);
      setSince(param.since);
      setUntil(param.until);
      setFileName(param.fileName);
      navigate.replace(`/documents/search?${searchParam}`);
    },
    [navigate]
  );

  const values = useMemo(
    () => ({
      type: documentType ?? undefined,
      user: userId ?? undefined,
      since: since ?? undefined,
      until: until ?? undefined,
      fileName: fileName ?? undefined,
    }),
    [documentType, since, until, userId, fileName]
  );

  return {
    values,
    setQuery,
  };
}
