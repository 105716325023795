import { Button, Form, Spin } from 'antd';
import { Rule } from 'antd/lib/form';
import { useForm } from 'antd/lib/form/Form';
import Select, { SelectValue } from 'antd/lib/select';
import { useCallback, useMemo } from 'react';
import { useAsyncFn, useMount } from 'react-use';
import styled from 'styled-components';

import { documentApi } from '../../api/apiClient';
import { DocumentBankBookEntity } from '../../generated-api';
import useBankbookStore from '../../store/useBankbookStore';
import { useDocument } from '../../store/useDocumentListStore';
import ensureDefined from '../../utils/ensureDefined';
import { notifyError } from '../../utils/notification';
import { calcBalance } from './calcBalance';
import { ResultPageDocument } from './defs/types';

interface Props {
  documentId: string;
  groupId: string;
  columnNameMapper: Map<string, string>;
}

interface InnerProps extends Props {
  document: ResultPageDocument<DocumentBankBookEntity>;
}

const selectColumnRules: Rule[] = [{ required: true, message: '選択してください' }];
const selectFormWidth = '14rem';

export default function BalanceCalcForm({ columnNameMapper, documentId, groupId }: Props) {
  const { document } = useDocument<DocumentBankBookEntity>(groupId, documentId);

  // 削除操作後、削除した documentId, groupId への参照が残っているせいでエラーになるので
  // ここで document が undefined であるかのチェックをする
  if (document == null) {
    return <></>;
  }

  return (
    <BalanceCalcFormInner
      columnNameMapper={columnNameMapper}
      document={document}
      documentId={documentId}
      groupId={groupId}
    />
  );
}
function BalanceCalcFormInner({ columnNameMapper, document, documentId, groupId }: InnerProps) {
  const { setPatchedDocument } = useDocument<DocumentBankBookEntity>(groupId, documentId);

  const { document_id, group_id, latest_revision } = document;
  const [calc] = useForm();

  const defaultSelectValue = useMemo(() => {
    return latest_revision.column_names?.find((el) => {
      return el == '残高' || el == 'balance';
    });
  }, [latest_revision.column_names]);
  const { selectedColumnName, setSelectedColumnName } = useBankbookStore(group_id, document_id);

  const value = selectedColumnName || defaultSelectValue;

  const balance = useMemo(
    () =>
      value && latest_revision.rows && latest_revision.column_names
        ? calcBalance(value, latest_revision.rows, latest_revision.column_names, columnNameMapper)
        : null,
    [columnNameMapper, latest_revision.column_names, latest_revision.rows, value]
  );

  const changedValue = useCallback(
    (value: SelectValue): void => {
      setSelectedColumnName(ensureDefined(value).toString());
    },
    [setSelectedColumnName]
  );

  const [updateBalanceState, updateBalance] = useAsyncFn(
    async (newBalance: string[]): Promise<void> => {
      const { column_names, rows } = latest_revision;
      const newEntity = {
        rows,
        column_names,
        balance_calculation_result: newBalance,
        type: 'bankbook',
      };

      const { data: newDocument } = await documentApi.documentControllerPatchDocument(group_id, document_id, {
        edited_ocr_result: newEntity as DocumentBankBookEntity,
      });

      setPatchedDocument(newDocument);
    },
    [latest_revision, group_id, document_id]
  );

  const onFinish = async () => {
    if (balance == null) {
      notifyError('残高計算 対象列が選択されていません');
      return;
    }
    await updateBalance(balance);
  };

  useMount(() => {
    // マウント時に残高計算がまだされていなければ計算結果を保存する
    if (latest_revision.balance_calculation_result == null && balance != null) {
      updateBalance(balance);
    }
    setSelectedColumnName(value);
  });

  return (
    <FormContainer form={calc} onFinish={onFinish}>
      <FormItem rules={selectColumnRules}>
        <Select
          defaultValue={defaultSelectValue}
          placeholder="残高列を選択してください"
          style={{ width: selectFormWidth }}
          onChange={changedValue}>
          {latest_revision.column_names?.map((name) => {
            return (
              <Select.Option key={name} value={name}>
                {columnNameMapper.get(name) ?? name}
              </Select.Option>
            );
          })}
        </Select>
      </FormItem>
      <FormItem>
        <Button disabled={updateBalanceState.loading} htmlType="submit" style={{ width: 88 }}>
          {updateBalanceState.loading ? <Spin size="small" /> : '残高計算'}
        </Button>
      </FormItem>
    </FormContainer>
  );
}

const FormContainer = styled(Form)`
  display: flex;
  gap: 8px;
`;

const FormItem = styled(Form.Item)`
  margin-bottom: 0;
`;
