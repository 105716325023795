import { Input, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import { ReactElement } from 'react';

import FormItemWithLabel from '../../../components/Form/FormItemWithLabel';
import FilterIcon from '../../../components/Icons/FilterIcon';
import { DocumentWithholdingSlipEntity } from '../../../generated-api';
import ocrTypeNameDefinitions from '../../../utils/ocrTypeNameDefinitions';
import FilterDropdown from '../FilterDropdown';
import useResultFilter from '../useResultFilter';
import AdditionalWithholdingSlipForm from '../withholdingSlip/AdditionalWithholdingSlipForm';
import renderTableColumn from './renderTableColumn';
import { OcrResultPageDefinition, OcrResultPageTableComponentProps } from './types';

interface Props {
  entity: DocumentWithholdingSlipEntity;
}
function WithholdingSlipFormInputs({ entity }: Props): ReactElement {
  return (
    <>
      <FormItemWithLabel label="受給者氏名" labelWidth="8rem" name="earner_name">
        <Input defaultValue={entity.earner_name} />
      </FormItemWithLabel>
      <FormItemWithLabel label="受給者番号" labelWidth="8rem" name="earner_number">
        <Input defaultValue={entity.earner_number} />
      </FormItemWithLabel>
      <FormItemWithLabel label="受給者住所" labelWidth="8rem" name="earner_address">
        <Input defaultValue={entity.earner_address} />
      </FormItemWithLabel>
      <FormItemWithLabel label="生年月日" labelWidth="8rem" name="date_of_birth">
        <Input defaultValue={entity.date_of_birth} />
      </FormItemWithLabel>
      <FormItemWithLabel label="支払者氏名" labelWidth="8rem" name="employer_name">
        <Input defaultValue={entity.employer_name} />
      </FormItemWithLabel>
      <FormItemWithLabel label="支払者個人番号/法人番号 " labelWidth="10.5rem" name="employer_number">
        <Input defaultValue={entity.employer_number} />
      </FormItemWithLabel>
      <FormItemWithLabel label="支払者住所" labelWidth="8rem" name="employer_address">
        <Input defaultValue={entity.employer_address} />
      </FormItemWithLabel>
      <FormItemWithLabel label="支払者電話番号" labelWidth="8rem" name="employer_phone">
        <Input defaultValue={entity.employer_phone} />
      </FormItemWithLabel>
      <FormItemWithLabel label="種別" labelWidth="8rem" name="income_type">
        <Input defaultValue={entity.income_type} />
      </FormItemWithLabel>
      <FormItemWithLabel label="支払金額" labelWidth="8rem" name="payment_amount">
        <Input defaultValue={entity.payment_amount} />
      </FormItemWithLabel>
      <FormItemWithLabel label="源泉徴収税額" labelWidth="8rem" name="withholding_amount">
        <Input defaultValue={entity.withholding_amount} />
      </FormItemWithLabel>
      <FormItemWithLabel label="社会保険料" labelWidth="8rem" name="social_insurance">
        <Input defaultValue={entity.social_insurance} />
      </FormItemWithLabel>
      <FormItemWithLabel label="生命保険料の控除額" labelWidth="8rem" name="life_insurance_deduction">
        <Input defaultValue={entity.life_insurance_deduction} />
      </FormItemWithLabel>
      <FormItemWithLabel label="地震保険料の控除額" labelWidth="8rem" name="earthquake_insurance_deduction">
        <Input defaultValue={entity.earthquake_insurance_deduction} />
      </FormItemWithLabel>
      <FormItemWithLabel label="住宅借入金等特別控除額" labelWidth="10.5rem" name="house_loan_deduction">
        <Input defaultValue={entity.house_loan_deduction} />
      </FormItemWithLabel>
      <FormItemWithLabel label="その他" labelWidth="8rem" name="other">
        <Input defaultValue={entity.other} />
      </FormItemWithLabel>
      <AdditionalWithholdingSlipForm entity={entity} />
    </>
  );
}

const csvColumns = {
  ファイル名: ['parsed_path', 'fullFileName'],
  ページ数: ['page'],
  受給者氏名: ['latest_revision', 'earner_name'],
  受給者番号: ['latest_revision', 'earner_number'],
  受給者住所: ['latest_revision', 'earner_address'],
  生年月日: ['latest_revision', 'date_of_birth'],
  支払者氏名: ['latest_revision', 'earner_name'],
  支払者個人番号もしくは法人番号: ['latest_revision', 'employer_number'],
  支払者住所: ['latest_revision', 'employer_address'],
  支払者電話番号: ['latest_revision', 'employer_phone'],
  種別: ['latest_revision', 'income_type'],
  支払金額: ['latest_revision', 'payment_amount'],
  源泉徴収税額: ['latest_revision', 'withholding_amount'],
  社会保険料: ['latest_revision', 'social_insurance'],
  生命保険料の控除額: ['latest_revision', 'life_insurance_deduction'],
  地震保険料の控除額: ['latest_revision', 'earthquake_insurance_deduction'],
  住宅借入金等特別控除額: ['latest_revision', 'house_loan_deduction'],
  配偶者特別控除額: ['latest_revision', 'spouse_deduction'],
  配偶者の合計所得: ['latest_revision', 'spouse_income'],
  新生命保険料: ['latest_revision', 'new_life_insurance'],
  旧生命保険料: ['latest_revision', 'old_life_insurance'],
  介護医療保険料: ['latest_revision', 'care_insurance'],
  新個人年金保険料: ['latest_revision', 'new_personal_pension_insurance'],
  旧個人年金保険料: ['latest_revision', 'old_personal_pension_insurance'],
  その他: ['latest_revision', 'other'],
};

function EarnerAndEmployerTable({
  loading,
  results,
  tabIndex,
}: OcrResultPageTableComponentProps<DocumentWithholdingSlipEntity>) {
  const { applyFilter, filterItems, filteredResults } = useResultFilter(
    results,
    withholdingSlipResultPageDefinition,
    tabIndex
  );
  const createFilterDropDown = (dataIndex: string[]) => {
    return {
      filterDropdown:
        filterItems[tabIndex][dataIndex.join(',')] != null ? (
          <FilterDropdown onApply={(v) => applyFilter(tabIndex, dataIndex, v)} />
        ) : null,
      filterIcon: () => <FilterIcon enabled={!!filterItems[tabIndex][dataIndex.join(',')]} />,
    };
  };
  return (
    <Table
      dataSource={filteredResults}
      loading={loading}
      pagination={{ defaultPageSize: 20, showSizeChanger: true, position: ['topRight', 'bottomRight'] }}>
      <Column
        dataIndex={['latest_revision', 'earner_name']}
        {...createFilterDropDown(['latest_revision', 'earner_name'])}
        title="受給者氏名"
      />
      <Column
        dataIndex={['latest_revision', 'earner_number']}
        {...createFilterDropDown(['latest_revision', 'earner_number'])}
        title="受給者番号"
      />
      <Column
        dataIndex={['latest_revision', 'earner_address']}
        {...createFilterDropDown(['latest_revision', 'earner_address'])}
        title="受給者住所"
      />
      <Column
        dataIndex={['latest_revision', 'date_of_birth']}
        {...createFilterDropDown(['latest_revision', 'date_of_birth'])}
        title="生年月日"
      />
      <Column
        dataIndex={['latest_revision', 'employer_name']}
        {...createFilterDropDown(['latest_revision', 'employer_name'])}
        title="支払者氏名"
      />
      <Column
        dataIndex={['latest_revision', 'employer_number']}
        {...createFilterDropDown(['latest_revision', 'employer_name'])}
        title="支払者個人番号/法人番号 "
      />
      <Column
        dataIndex={['latest_revision', 'employer_address']}
        {...createFilterDropDown(['latest_revision', 'employer_address'])}
        title="支払者住所"
      />
      <Column
        dataIndex={['latest_revision', 'employer_phone']}
        {...createFilterDropDown(['latest_revision', 'employer_phone'])}
        title="支払者電話番号"
      />
    </Table>
  );
}

function InsuranceTable({
  loading,
  results,
  tabIndex,
}: OcrResultPageTableComponentProps<DocumentWithholdingSlipEntity>) {
  const { applyFilter, filterItems, filteredResults } = useResultFilter(
    results,
    withholdingSlipResultPageDefinition,
    tabIndex
  );
  const createFilterDropDown = (dataIndex: string[]) => {
    return {
      filterDropdown:
        filterItems[tabIndex][dataIndex.join(',')] != null ? (
          <FilterDropdown onApply={(v) => applyFilter(tabIndex, dataIndex, v)} />
        ) : null,
      filterIcon: () => <FilterIcon enabled={!!filterItems[tabIndex][dataIndex.join(',')]} />,
    };
  };
  return (
    <Table
      dataSource={filteredResults}
      loading={loading}
      pagination={{ defaultPageSize: 20, showSizeChanger: true, position: ['topRight', 'bottomRight'] }}>
      <Column
        dataIndex={['latest_revision', 'earner_name']}
        {...createFilterDropDown(['latest_revision', 'earner_name'])}
        title="受給者氏名"
      />
      <Column dataIndex={['latest_revision', 'social_insurance']} title="社会保険料" />
      <Column dataIndex={['latest_revision', 'life_insurance_deduction']} title="生命保険料の控除額" />
      <Column dataIndex={['latest_revision', 'earthquake_insurance_deduction']} title="地震保険料の控除額" />
      <Column dataIndex={['latest_revision', 'house_loan_deduction']} title="住宅借入金等特別控除の額" />
    </Table>
  );
}

const withholdingSlipResultPageDefinition: OcrResultPageDefinition<DocumentWithholdingSlipEntity> = {
  name: ocrTypeNameDefinitions.withholding_slip,
  tabs: [
    {
      name: '要旨',
      csvColumns: csvColumns,
      tableColumns: [
        {
          dataIndex: ['latest_revision', 'earner_name'],
          title: '受給者氏名',
          render: renderTableColumn,
          searchFilter: true,
        },
        { dataIndex: ['latest_revision', 'payment_amount'], title: '支払金額', render: renderTableColumn },
        {
          dataIndex: ['latest_revision', 'withholding_amount'],
          title: '源泉徴収税額',
          render: renderTableColumn,
        },
        {
          dataIndex: ['latest_revision', 'employer_name'],
          title: '支払者氏名',
          render: renderTableColumn,
          searchFilter: true,
        },
      ],
    },
    {
      name: '受給者/支払者',
      csvColumns: csvColumns,
      tableColumns: [
        {
          dataIndex: ['latest_revision', 'earner_name'],
          title: '受給者氏名',
          render: renderTableColumn,
          searchFilter: true,
        },
        {
          dataIndex: ['latest_revision', 'earner_number'],
          title: '受給者番号',
          render: renderTableColumn,
          searchFilter: true,
        },
        {
          dataIndex: ['latest_revision', 'earner_address'],
          title: '受給者住所',
          render: renderTableColumn,
          searchFilter: true,
        },
        {
          dataIndex: ['latest_revision', 'date_of_birth'],
          title: '生年月日',
          render: renderTableColumn,
          searchFilter: true,
        },
        {
          dataIndex: ['latest_revision', 'employer_name'],
          title: '支払者氏名',
          render: renderTableColumn,
          searchFilter: true,
        },
        {
          dataIndex: ['latest_revision', 'employer_address'],
          title: '支払者住所',
          render: renderTableColumn,
          searchFilter: true,
        },
        {
          dataIndex: ['latest_revision', 'employer_phone'],
          title: '支払者電話番号',
          render: renderTableColumn,
          searchFilter: true,
        },
      ],
      tableComponent: EarnerAndEmployerTable,
    },
    {
      name: '保険料等',
      csvColumns: csvColumns,
      tableColumns: [
        {
          dataIndex: ['latest_revision', 'earner_name'],
          title: '受給者氏名',
          render: renderTableColumn,
          searchFilter: true,
        },
        {
          dataIndex: ['latest_revision', 'social_insurance'],
          title: '社会保険料',
          render: renderTableColumn,
        },
        {
          dataIndex: ['latest_revision', 'life_insurance_deduction'],
          title: '生命保険料の控除額',
          render: renderTableColumn,
        },
        {
          dataIndex: ['latest_revision', 'earthquake_insurance_deduction'],
          title: '地震保険料の控除額',
          render: renderTableColumn,
        },
        {
          dataIndex: ['latest_revision', 'house_loan_deduction'],
          title: '住宅借入金等特別控除の額',
          render: renderTableColumn,
        },
      ],
      tableComponent: InsuranceTable,
    },
  ],
  formComponent: WithholdingSlipFormInputs,
};

export default withholdingSlipResultPageDefinition;
