import styled from 'styled-components';

import StaticPageLayout from '../../components/Container/StaticPageLayout';

const FreeConsultationPage = () => {
  return (
    <StaticPageLayout>
      <Title>無料相談会のご案内</Title>
      <Content>
        <p>毎週木曜日16-17時にOCR無料相談会を開催しております。</p>
        <p>ぜひお気軽にご参加ください。</p>
        <p>●Ez-AiOCRに関するご質問にその場でお答えします！</p>
        <p>●以下のZOOMよりご自由にご参加ください。</p>
        <a href="https://us02web.zoom.us/j/6181711280?pwd=TU50MWpTRHNrN0hiWms4MVRvUzBmdz09">
          https://us02web.zoom.us/j/6181711280?pwd=TU50MWpTRHNrN0hiWms4MVRvUzBmdz09
        </a>
      </Content>
    </StaticPageLayout>
  );
};

export default FreeConsultationPage;

const Title = styled.h1`
  color: #8da9db;
  font-weight: 600;
  margin-bottom: 24px;
`;

const Content = styled.div`
  width: 800px;
  padding: 0 48px;
`;
