import styled from 'styled-components';

import { ReactComponent as DualHorizontalReceiptSvg } from '../../asset/dual_horizontal_receipt.svg';
import { ReactComponent as DualVerticalReceiptSvg } from '../../asset/dual_vertical_receipt.svg';
import { DocumentType } from '../../generated-api';

const Guide = styled.div`
  margin-bottom: 48px;
`;

interface Props {
  documentType: DocumentType | null;
}

export default function DocumentTypeGuide({ documentType }: Props) {
  switch (documentType) {
    case DocumentType.DualHorizontalReceipt:
      return (
        <Guide>
          <p>
            下記のように1枚の画像・PDFに横に2枚ある形式
            <br />
            ※中央の位置で2つに分割しているので、領収書をバランスよく配置してください。
          </p>
          <DualHorizontalReceiptSvg />
        </Guide>
      );
    case DocumentType.DualVerticalReceipt:
      return (
        <Guide>
          <p>
            下記のように1枚の画像・PDFに縦に2枚ある形式
            <br />
            ※中央の位置で2つに分割しているので、領収書をバランスよく配置してください。
          </p>
          <DualVerticalReceiptSvg />
        </Guide>
      );
    default:
      return <Guide />;
  }
}
