import { Button, Card, Form, Input, Radio, Space, Typography } from 'antd';
import { Rule } from 'antd/lib/form';
import { useForm } from 'antd/lib/form/Form';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { UserRole } from '../../generated-api';

type Props = {
  onSave: (inputs: UserEditInputs) => void;
  user?: object;
};

export type UserEditInputs = {
  name: string;
  email: string;
  role: UserRole;
};

const nameRules: Rule[] = [{ required: true, message: '入力必須項目' }];

const emailRules: Rule[] = [
  { required: true, message: '入力必須項目' },
  { type: 'email', message: '有効なメールアドレスを入力してください' },
];

const roleRules: Rule[] = [{ required: true, message: '選択必須項目' }];

const UserEditForm = ({ onSave, user }: Props) => {
  const [form] = useForm();
  const history = useHistory();

  const pageBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const save = useCallback(() => {
    onSave(form.getFieldsValue());
  }, [form, onSave]);

  return (
    <Card>
      <UserCreateForm form={form} initialValues={user}>
        <Item>
          <Label>名前</Label>
          <FormItem name="name" rules={nameRules}>
            <TextInput />
          </FormItem>
        </Item>
        <Item>
          <Label>メールアドレス</Label>
          <FormItem name="email" rules={emailRules}>
            <TextInput />
          </FormItem>
        </Item>
        <Item>
          <Label>権限</Label>
          <FormItem name="role" rules={roleRules}>
            <Radio.Group>
              <Radio value="member">一般ユーザ</Radio>
              <Radio value="admin">管理者ユーザ</Radio>
            </Radio.Group>
          </FormItem>
        </Item>
        <FormItem>
          <Space>
            <Button htmlType="submit" type="primary" onClick={save}>
              登録
            </Button>
            <Button onClick={pageBack}>キャンセル</Button>
          </Space>
        </FormItem>
      </UserCreateForm>
    </Card>
  );
};

export default UserEditForm;

const UserCreateForm = styled(Form)``;

const Item = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const FormItem = styled(Form.Item)`
  margin-bottom: 0;
`;

const Label = styled(Typography.Text)`
  width: 160px;
  display: flex;
  align-items: center;
`;

const TextInput = styled(Input)`
  width: 320px;
`;
