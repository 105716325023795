import { Card, Tabs } from 'antd';
import Column from 'antd/lib/table/Column';
import { ExpandableConfig } from 'antd/lib/table/interface';
import { addHours, format, parseISO } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useAsync, useMount } from 'react-use';
import styled from 'styled-components';

import FilterIcon from '../../components/Icons/FilterIcon';
import useEditModalContext, { EditModalContextProvider } from '../../context/useEditModalContext';
import { DocumentType } from '../../generated-api';
import useOcrPageDefinition from '../../hooks/useOcrPageDefinition';
import useDocumentGroupStore from '../../store/useDocumentGroupStore';
import useDocumentListAutoUpdateStore from '../../store/useDocumentListAutoUpdateStore';
import useDocumentListStore from '../../store/useDocumentListStore';
import { Paths } from '../../types/Paths';
import getPropertyOf from '../../utils/getPropertyOf';
import { notifySuccess } from '../../utils/notification';
import { DocumentEntities, ResultPageDocument } from './defs/types';
import { DownloadCsvButton } from './DownloadCsvButton';
import { DownloadExcelButton } from './DownloadExcelButton';
import FilterDropdown from './FilterDropdown';
import ResultSummary from './OcrResultTable/ResultSummary';
import ResultTable from './OcrResultTable/ResultTable';
import useResultFilter from './useResultFilter';

type Params = {
  type: DocumentType;
  group: string;
};

type Props = {
  type: DocumentType;
  groupId: string;
  tabIndex: number;
};

export default function OcrResultPage({ match }: RouteComponentProps<Params>) {
  const groupId = match.params.group;
  const type = match.params.type;
  const [tabIndex, setTabIndex] = useState(0);
  const definition = useOcrPageDefinition(type);
  return (
    <EditModalContextProvider groupId={groupId} type={type}>
      <Card>
        <Summary groupId={groupId} type={type} />
        {/* タブが2個以上定義されていればタブを表示する */}
        {definition.tabs.length >= 2 && (
          <Tabs defaultActiveKey={tabIndex.toString()} onTabClick={(key) => setTabIndex(parseInt(key))}>
            {definition.tabs.map((tab, index) => (
              <Tabs.TabPane key={index} tab={tab.name} tabKey={index.toString()} />
            ))}
          </Tabs>
        )}
        <Table groupId={groupId} tabIndex={tabIndex} type={type} />
      </Card>
    </EditModalContextProvider>
  );
}

type TableProps = Props;

function Table({ groupId, tabIndex, type }: TableProps) {
  const definition = useOcrPageDefinition(type);
  const tabDefinition = definition.tabs[tabIndex];
  const { actions, ocrResults } = useDocumentListStore(groupId);
  const { open } = useEditModalContext();

  const fetchDocumentState = useAsync(async () => actions.fetchDocumentList());

  const { applyFilter, filterItems, filteredResults } = useResultFilter<ResultPageDocument<DocumentEntities>>(
    ocrResults,
    definition,
    tabIndex
  );

  // tabIndex が切り替わったときにフィルタ表示を更新するために敢えてメモをしている
  const filterItem = useMemo(() => filterItems[tabIndex], [filterItems, tabIndex]);

  useEffect(() => {
    // ocrResults の読み込みが終わったら一覧を更新する
    applyFilter(tabIndex, undefined, undefined);
  }, [applyFilter, fetchDocumentState, ocrResults, tabIndex]);

  const onDeleteDocument = useCallback(
    async (documentId: string) => {
      await actions.deleteDocument(documentId);
      notifySuccess('削除しました');
      await actions.fetchDocumentList();
    },
    [actions]
  );

  const onClickEdit = useCallback(
    (documentId: string) => {
      open(documentId);
    },
    [open]
  );

  const TableComponent = tabDefinition.tableComponent;

  return TableComponent ? (
    <TableComponent
      loading={fetchDocumentState.loading}
      results={(ocrResults ?? []) as ResultPageDocument<never>[]}
      tabIndex={tabIndex}
    /> // 型はなんでもよいので never
  ) : (
    <ResultTable
      expandable={tabDefinition.tableExpandable as ExpandableConfig<ResultPageDocument<DocumentEntities>>}
      loading={fetchDocumentState.loading}
      results={filteredResults ?? []}
      onClickEdit={onClickEdit}
      onConfirmDelete={onDeleteDocument}>
      {tabDefinition.tableColumns.map((def) => (
        // 型はなんでもよいので never
        <Column<ResultPageDocument<never>>
          key={def.dataIndex.join('.')}
          dataIndex={def.dataIndex}
          filterDropdown={
            filterItem[def.dataIndex.join()] != null ? (
              <FilterDropdown onApply={(v) => applyFilter(tabIndex, def.dataIndex, v)} />
            ) : null
          }
          filterIcon={() => <FilterIcon enabled={!!filterItem[def.dataIndex.join()]} />}
          render={(value, record, index) => (record.error ? '-' : def.render?.(value, record, index))}
          sorter={
            def.sort
              ? (a, b) =>
                  parseInt(getPropertyOf(a, def.dataIndex as Paths<unknown>) as string, 10) -
                  parseInt(getPropertyOf(b, def.dataIndex as Paths<unknown>) as string, 10)
              : undefined
          }
          title={def.title}
        />
      ))}
    </ResultTable>
  );
}

type SummaryProps = Omit<Props, 'tabIndex'>;

function Summary({ groupId, type }: SummaryProps) {
  const { autoUpdateTimerEnable } = useDocumentListAutoUpdateStore(groupId);
  const { totalPageCount } = useDocumentListStore(groupId);
  const { actions, documentGroup, executedUserId, failedFileCount, forbidden, succeededFileCount } =
    useDocumentGroupStore(groupId);

  const [firstLoading, setFirstLoading] = useState(true);

  useMount(async () => {
    await actions.fetchDocumentGroup();
    setFirstLoading(false);
  });

  const uploadedAt = useMemo(
    () =>
      documentGroup?.uploaded_at
        ? format(addHours(parseISO(documentGroup?.uploaded_at ?? ''), 9), 'yyyy-MM-dd HH:mm:ss')
        : '',
    [documentGroup?.uploaded_at]
  );

  const documentStatus = firstLoading || autoUpdateTimerEnable ? 'loading' : forbidden ? 'forbidden' : 'completed';

  return (
    <>
      <ResultSummary
        documentType={type}
        executedUserId={executedUserId ?? null}
        failedFileCount={failedFileCount ?? null}
        filesCount={documentGroup?.files_count ?? null}
        status={documentStatus}
        succeededFileCount={succeededFileCount ?? null}
        totalPageCount={totalPageCount ?? null}
        uploadedAt={uploadedAt}></ResultSummary>
      <DownloadButtons>
        <DownloadCsvButton groupId={groupId} type={type} />
        <DownloadExcelButton groupId={groupId} type={type} />
      </DownloadButtons>
    </>
  );
}

const DownloadButtons = styled.div`
  display: flex;
  gap: 24px;
`;
