import { Modal } from 'antd';
import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { usersApi } from '../../api/apiClient';
import PageLayout from '../../components/Container/PageLayout';
import { EditUserRequest } from '../../generated-api';
import useAPIData from '../../hooks/useAPIData';
import { notifyError, notifySuccess } from '../../utils/notification';
import UserEditForm, { UserEditInputs } from './UserEditForm';

const UserEditPage = () => {
  const history = useHistory();
  const param = useParams<{ userId: string }>();
  const { data: user } = useAPIData(async () => {
    const { data } = await usersApi.userControllerGetUser(param.userId);
    return data;
  });

  const editUser = useCallback(
    (input: UserEditInputs) => {
      Modal.confirm({
        title: 'ユーザの情報を保存しますか？',
        okText: 'はい',
        cancelText: 'いいえ',
        onOk: async () => {
          try {
            const request: EditUserRequest = input;
            const id = param.userId;
            await usersApi.userControllerEditUser(id, request);
            notifySuccess('ユーザの情報を保存しました。');
            history.push('/users');
          } catch (error) {
            notifyError('エラーが発生しました。');
          }
        },
      });
    },
    [history, param]
  );

  return <PageLayout heading="組織ユーザ編集">{user && <UserEditForm user={user} onSave={editUser} />}</PageLayout>;
};

export default UserEditPage;
