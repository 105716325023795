import { selector } from 'recoil';

import { companiesApi } from '../api/apiClient';

export const selfCompanyRepresentativeUserNameSelector = selector({
  key: 'selfCompanyRepresentativeUserNameSelector',
  get: async () => {
    const { data } = await companiesApi.companyControllerGetCompanyRepresentativeUser();

    return data.representative_user_name;
  },
});
