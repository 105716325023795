import { Form, Input } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { ReactElement, useCallback, useMemo } from 'react';
import { useAsync, useList } from 'react-use';
import short from 'short-uuid';
import styled from 'styled-components';

import { accountingCategoriesApi, accountingCategoryCompletionApi } from '../../../api/apiClient';
import { DocumentReceiptEntity, ReceiptDetail } from '../../../generated-api';
import getPath from '../../../utils/getPath';
import ReceiptDetailRow, { ReceiptDetailHeader } from './ReceiptDetailRow';

const ReceiptDetailSection = styled.div`
  padding: 8px;
  margin-bottom: 24px;
  border: 1px solid #f0f0f0;
  display: grid;
  grid-gap: 8px;
  align-items: center;
  overflow: auto;
  grid-auto-rows: 40px;
`;
const ReceiptDetailCalcDescription = styled.div`
  font-size: 12px;
  color: #ff4d4f;
  white-space: nowrap;
`;
const ReceiptDetailLabel = styled.div`
  margin-bottom: 12px;
`;

const PREDICT_ACCOUNT_MAX_CACHE_SIZE = 100;
const predictAccountCache = new Map<string, string | undefined>();

async function predictAccountCategoryFromPayee(payee: string): Promise<string | undefined> {
  // 安全のためにキャッシュサイズを超えたらAPIを呼び出さない
  if (payee === '' || payee === '-') {
    return undefined;
  }
  if (predictAccountCache.has(payee)) {
    return predictAccountCache.get(payee);
  }
  if (predictAccountCache.size > PREDICT_ACCOUNT_MAX_CACHE_SIZE) {
    return undefined;
  }
  const { data } =
    await accountingCategoryCompletionApi.accountingCategoryCompletionControllerPredictAccountingCategory({
      payee: payee,
    });
  predictAccountCache.set(payee, data.account);
  return data.account;
}

type ReceiptDetailFormProps = {
  entity: DocumentReceiptEntity;
  inputPayee: string | null;
  changed: boolean;
  setChanged: (newValue: boolean) => void;
  path: NamePath;
};
function ReceiptDetailForm({ changed, entity, inputPayee, path, setChanged }: ReceiptDetailFormProps): ReactElement {
  const { value: accountCategories } = useAsync(async () => {
    const { data } = await accountingCategoriesApi.accountingCategoriesControllerGetAccountingCategories();
    return data.filter((item) => item.in_used);
  }, []);

  const { value: predictedAccount } = useAsync(async () => {
    return await predictAccountCategoryFromPayee(inputPayee ?? '');
  }, [inputPayee]);

  const details = useMemo(() => {
    return (
      entity.receipt_details?.reduce<Record<string, ReceiptDetail>>((accumulator, current) => {
        accumulator[short.generate()] = current;
        return accumulator;
      }, {}) ?? {}
    );
  }, [entity.receipt_details]);

  const [rowIds, { insertAt: insertRowAt, removeAt: removeRowAt }] = useList(Object.keys(details));

  const defaultValue = useMemo(() => {
    return {
      tax_percent: undefined,
      amount_without_tax: 0,
      tax_amount: 0,
      amount_with_tax: 0,
      account: predictedAccount ?? '-',
      account_code: accountCategories?.find((item) => item.account === predictedAccount)?.account_code ?? '-',
      transaction_content: '',
    };
  }, [predictedAccount, accountCategories]);

  if (rowIds.length === 0) {
    const rowId = short.generate();
    rowIds.push(rowId);
    details[rowId] = defaultValue;
  }

  const addRow = useCallback(
    (index: number) => {
      const rowId = short.generate();
      details[rowId] = defaultValue;
      insertRowAt(index + 1, rowId);
      setChanged(true);
    },
    [insertRowAt, setChanged, details, defaultValue]
  );
  const removeRow = useCallback(
    (index: number) => {
      removeRowAt(index);
      setChanged(true);
    },
    [removeRowAt, setChanged]
  );

  return (
    <>
      <ReceiptDetailLabel>明細</ReceiptDetailLabel>
      <ReceiptDetailSection>
        <ReceiptDetailHeader />
        <div key={rowIds.length} hidden>
          {rowIds.map((rowId, index) => (
            // 列の並びを維持するために index と rowId の関係を object で保持する
            // FIXME: rowId を value として持つと rowIds が更新されても value が更新されないため name にキーとして持たせる
            // FYI: Form.Item の value を動的に変化させるためには form.setFieldValue を使う必要がある
            <Form.Item key={rowId} initialValue={rowId} name={getPath(path, 'rowIndices', index, rowId)}>
              <Input type="text" />
            </Form.Item>
          ))}
        </div>
        {rowIds.map((id, index) => (
          <ReceiptDetailRow
            key={id}
            accountCategories={accountCategories ?? []}
            addRow={addRow}
            changed={changed}
            detail={details[id] ?? defaultValue}
            hiddenRemove={rowIds.length <= 1}
            inputPayee={inputPayee}
            path={path}
            predictedAccount={predictedAccount ?? null}
            removeRow={removeRow}
            rowId={id}
            rowIndex={index}
          />
        ))}
        <ReceiptDetailCalcDescription>
          「税込み金額」を入力して「税率」を変更すると、 自動的に「税抜き金額」と「税額」が計算されます。
        </ReceiptDetailCalcDescription>
      </ReceiptDetailSection>
    </>
  );
}

export default ReceiptDetailForm;
