import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { format, parseISO } from 'date-fns';

type StatsUserTableData = {
  statsUser: Record<string, number>;
  month: string;
  confirmed: boolean; // 未確定ならfalse
};

type Props = {
  userDataSource: StatsUserTableData[] | undefined;
  userNames: string[] | undefined;
  loading: boolean;
};

const StatsUserTable = ({ loading, userDataSource, userNames }: Props) => {
  const children: ColumnsType<StatsUserTableData> = (userNames ?? []).map((element: string) => {
    return {
      title: element,
      dataIndex: element,
      render: (_, record) => {
        const userOfColumn = element;
        const value = record.statsUser[userOfColumn] ?? 0;
        return value.toLocaleString() + '枚';
      },
      width: 200,
      align: 'right',
    };
  });
  const userColumns: ColumnsType<StatsUserTableData> = [
    {
      title: 'ご利用月',
      dataIndex: 'month',
      render: (value, record) => {
        const formattedDate = format(parseISO(value), 'yyyy/M');
        if (record.confirmed) {
          return formattedDate;
        } else {
          return '（未確定）' + formattedDate;
        }
      },
      width: 300,
      align: 'right',
    },
    {
      title: '利用枚数',
      children: children,
    },
  ];

  return (
    <Table
      bordered={true}
      columns={userColumns}
      dataSource={userDataSource}
      loading={loading}
      pagination={false}
      rowKey="month"
    />
  );
};

export default StatsUserTable;
