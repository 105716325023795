import { Button, FormInstance, Modal, Spin } from 'antd';
import { ReactElement, useCallback, useEffect, useReducer, useState } from 'react';
import SplitPane from 'react-split-pane';
import styled from 'styled-components';

import { documentApi } from '../../../api/apiClient';
import DocumentImage from '../../../components/Container/DocumentImage';
import { Document } from '../../../generated-api';
import useOcrPageDefinition from '../../../hooks/useOcrPageDefinition';
import { useDocument } from '../../../store/useDocumentListStore';
import getErrorBrief from '../../../utils/getErrorBrief';
import { notifyError, notifySuccess } from '../../../utils/notification';
import { DocumentEntities, EditModalEntity, OcrResultPageDefinition } from '../defs/types';
import DocumentEditForm from './DocumentEditForm';
import Navigator, { Action } from './Navigator';

type Props = {
  entity: EditModalEntity;
  currentIndex: number;
  documentId: string;
  groupId: string;
  hasNext: boolean;
  hasPrev: boolean;
  onClickNext: () => void;
  onClickPrev: () => void;
  onSaveButtonClick: (document: Document) => Promise<void>;
  onClose: (editedDocumentId: string | undefined) => void;
};

export default function DocumentEditModal({
  currentIndex,
  documentId,
  entity,
  groupId,
  hasNext,
  hasPrev,
  onClickNext,
  onClickPrev,
  onClose,
  onSaveButtonClick,
}: Props): ReactElement | null {
  const [changed, setChanged] = useState(false);
  const [form, setForm] = useState<FormInstance<unknown> | undefined>(undefined);
  const { document, setPatchedDocument } = useDocument(groupId, documentId);
  // document が存在しなければ一覧からは取り除かれているはずなので、
  // document が null であることによる ocr_error の表示はされない想定
  const def = useOcrPageDefinition(document?.ocr_type ?? 'ocr_error');

  // form を強制的に再描画するためのカウンタ
  const [redrawCount, redraw] = useReducer((prev: number) => prev + 1, 0);

  // values にはモーダル内のフォームの値が入る
  const save = useCallback(
    async (values: unknown) => {
      try {
        const newEntityValues = def.formDataToEntity?.(values) ?? values;
        const response = await documentApi.documentControllerPatchDocument(groupId, documentId, {
          edited_ocr_result: newEntityValues as DocumentEntities,
        });
        setPatchedDocument(response.data);
        onSaveButtonClick(response.data);
        // form の再描画
        redraw();
        notifySuccess('変更を保存しました。');
        setChanged(false);
      } catch (error) {
        console.error(error);
        notifyError(getErrorBrief(error));
      }
    },
    [def, documentId, groupId, onSaveButtonClick, setChanged, setPatchedDocument]
  );

  const confirm = useCallback(
    async (action: Action): Promise<boolean> => {
      if (changed) {
        const result = await openDiscardConfirmModal(action, form, save);
        if (!result) {
          return false;
        }
        setChanged(false);
      }
      return true;
    },
    [changed, form, save]
  );

  const close = useCallback(async () => {
    if (await confirm('閉じる')) {
      onClose(documentId);
    }
  }, [confirm, documentId, onClose]);

  const see = useCallback(
    async (documentId: string) => {
      await documentApi.documentControllerSeeDocument(groupId, documentId);
    },
    [groupId]
  );

  useEffect(() => {
    if (documentId) {
      see(documentId);
    }
  }, [documentId, see]);

  if (documentId == null) {
    return null;
  }

  return (
    <Modal
      centered
      cancelButtonProps={{ style: { display: 'none' } }}
      footer={null}
      maskClosable={false}
      okButtonProps={{ style: { display: 'none' } }}
      style={{ minWidth: '700px' }}
      visible={true}
      width="90vw"
      onCancel={() => onClose(documentId)}>
      <Container
        defaultSize="60%"
        maxSize={-260}
        minSize={500}
        primary="second"
        resizerStyle={{ background: '#f0f0f0', width: '5px', cursor: 'col-resize' }}
        style={{ position: 'relative' }}>
        <ImageSide>
          <DocumentImage key={documentId} documentId={documentId} groupId={groupId} />
        </ImageSide>
        <UserInteractiveSide>
          <Navigator
            confirm={confirm}
            entity={entity}
            hasNext={hasNext}
            hasPrev={hasPrev}
            onClickNext={onClickNext}
            onClickPrev={onClickPrev}
          />
          <DocumentEditForm
            key={`${currentIndex}-${redrawCount}`}
            changed={changed}
            def={def as OcrResultPageDefinition<DocumentEntities>}
            documentId={documentId}
            entity={entity}
            groupId={groupId}
            redrawCount={redrawCount}
            save={save}
            setChanged={setChanged}
            setForm={setForm}
            onClose={close}
            onSaveButtonClick={onSaveButtonClick}
          />
        </UserInteractiveSide>
      </Container>
    </Modal>
  );
}

const Container = styled(SplitPane)`
  display: flex;
  width: 100%;
  padding-top: 15px;
`;
const UserInteractiveSide = styled.div`
  margin-left: 8px;
`;
const ImageSide = styled.div`
  margin-right: 8px;
`;

async function openDiscardConfirmModal(
  action: Action,
  form: FormInstance<unknown> | undefined,
  save: (values: unknown) => Promise<void>
): Promise<boolean> {
  return new Promise<boolean>((resolve) => {
    Modal.confirm({
      title: '内容が変更されています。',
      content: <ConfirmModalContent action={action} form={form} resolve={resolve} save={save} />,
      okButtonProps: { style: { display: 'none' } },
      cancelButtonProps: { style: { display: 'none' } },
      width: '550px',
    });
  });
}

const Buttons = styled.div`
  margin-top: 32px;
  display: flex;
  gap: 8px;
`;
interface ConfirmModalContentProps {
  resolve: (result: boolean) => void;
  action: Action;
  form: FormInstance<unknown> | undefined;
  save: (values: unknown) => Promise<void>;
}
const ConfirmModalContent = ({ action, form, resolve, save }: ConfirmModalContentProps) => {
  const [loading, setLoading] = useState(false);
  const onBack = useCallback(() => {
    resolve(false);
    Modal.destroyAll();
  }, [resolve]);
  const onSave = useCallback(async () => {
    resolve(true);
    setLoading(true);
    form && (await save(form.getFieldsValue()));
    Modal.destroyAll();
  }, [form, resolve, save]);
  const onDiscard = useCallback(() => {
    resolve(true);
    Modal.destroyAll();
  }, [resolve]);
  return (
    <Buttons>
      <Button onClick={onBack}>戻る</Button>
      <Button type="primary" onClick={onSave}>
        <Spin size="small" spinning={loading}>
          変更を保存して{action}
        </Spin>
      </Button>
      <Button danger type="primary" onClick={onDiscard}>
        変更を破棄して{action}
      </Button>
    </Buttons>
  );
};
