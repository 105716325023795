import { Alert, Button, Form, Input } from 'antd';
import { Rule } from 'antd/lib/form';
import useForm from 'antd/lib/form/hooks/useForm';
import axios from 'axios';
import { useCallback, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { useAsyncFn, useMount, useSearchParam } from 'react-use';
import styled from 'styled-components';

import { authApi } from '../../api/apiClient';
import AuthPageContainer from '../../components/Container/AuthPageContainer';
import { AuthError } from '../../generated-api';
import { useAuthStore, useCommitLogin } from '../../store/useAuthStore';
import { mediaQuery } from '../../utils/mediaQuery';
import { notifyApplicationError, notifyError } from '../../utils/notification';

type FormValues = {
  email: string;
  password: string;
};

const mailFormRules: Rule[] = [
  { required: true, message: 'この項目は必須です' },
  { type: 'email', message: '有効なメールアドレスを入力してください' },
];
const passwordFormRules: Rule[] = [{ required: true, message: 'この項目は必須です' }];
const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? '';

const errorMessages: Record<AuthError, string> = {
  [AuthError.UserNotFound]: 'メールアドレスまたはパスワードが違います。',
  [AuthError.CompanyInactivated]: '解約済みです。',
};

const LoginPage = () => {
  const commitLogin = useCommitLogin();
  const [form] = useForm();
  const history = useHistory();
  const { isAuthorized } = useAuthStore();
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loginDisabled, setLoginDisabled] = useState(true);

  // ログイン後にリダイレクトするパス(ref: src/api/apiClient.tsx)
  const redirectPath = useSearchParam('r');

  useNotifyTimeout();

  const enableLogin = useCallback((value) => {
    if (value) {
      setLoginDisabled(false);
    } else {
      setLoginDisabled(true);
    }
  }, []);

  const [loginState, login] = useAsyncFn(
    async ({ email, password }) => {
      try {
        setErrorMessage(null);
        const token = recaptchaRef.current?.getValue() ?? '';

        const { data } = await authApi.authControllerLogin({
          email,
          password,
          token,
        });
        await commitLogin(data.access_token);
        if (redirectPath != null && redirectPath !== '/') {
          // useHistory の push は redirectPath が絶対パスだったとしても相対パスとして扱ってくれるので
          // 踏み台にされたりはしない
          history.push(redirectPath);
        } else {
          history.push('/');
        }
      } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === 401) {
          console.error(e.request);
          setErrorMessage(
            errorMessages[e.response?.data.reason as AuthError] ??
              e.response?.data.reason ??
              e.response?.data.message ??
              'アプリケーションに問題が発生している可能性があります。お手数をおかけしますが、しばらく後でもう一度お試しください。'
          );
        } else {
          console.error(e);
          notifyApplicationError();
        }
      } finally {
        recaptchaRef.current?.reset();
      }
    },
    [commitLogin, history, redirectPath]
  );

  return isAuthorized ? (
    <Redirect to={!!redirectPath && redirectPath !== '/' ? redirectPath : '/'} />
  ) : (
    <AuthPageContainer>
      <Form<FormValues> form={form} layout="vertical" requiredMark={false} onFinish={login}>
        {errorMessage && <Alert message={errorMessage} type="error" />}
        <FormItem label="メールアドレス" name="email" rules={mailFormRules}>
          <FormInput type="email" />
        </FormItem>
        <FormItem label="パスワード" name="password" rules={passwordFormRules}>
          <FormInput type="password" />
        </FormItem>
        <FormItem>
          <ReCAPTCHA ref={recaptchaRef} sitekey={siteKey} onChange={enableLogin}></ReCAPTCHA>
        </FormItem>
        <FormItem>
          <LoginButton block disabled={loginDisabled} htmlType="submit" loading={loginState.loading} type="primary">
            ログイン
          </LoginButton>
        </FormItem>
      </Form>
      <PasswordForgetLink to="/password/forget">パスワードを忘れた場合</PasswordForgetLink>
    </AuthPageContainer>
  );
};

export default LoginPage;

const FormItem = styled(Form.Item)`
  width: 500px;
  @media (${mediaQuery.sp}) {
    width: 100%;
  }
`;

const FormInput = styled(Input)`
  height: 45px;
  @media (${mediaQuery.sp}) {
    width: 100%;
  }
`;

const LoginButton = styled(Button)`
  height: 50px;
  @media (${mediaQuery.sp}) {
    width: 100%;
  }
`;

const PasswordForgetLink = styled(Link)`
  width: 500px;
  @media (${mediaQuery.sp}) {
    width: 100%;
  }
`;

// クエリパラメータを見て再ログイン通知を出す
function useNotifyTimeout() {
  const history = useHistory();
  const expired = useSearchParam('expired');
  const location = useLocation();

  useMount(() => {
    if (expired != null) {
      notifyError('タイムアウトしました。\n再ログインしてください。');
      const param = new URLSearchParams(location.search);
      // リロードしても再度出ないようにする
      param.delete('expired');
      history.replace({ search: param.toString() });
    }
  });
}
