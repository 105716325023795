import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { ReactElement, useCallback } from 'react';
import styled from 'styled-components';

interface Props {
  hiddenRemove?: boolean;
  index: number;
  add: (index: number) => void;
  remove: (index: number) => void;
  reverse?: boolean;
}

export const FluctuationButton = ({ add, hiddenRemove, index, remove, reverse }: Props): ReactElement => {
  const onAdd = useCallback(() => add(index), [index, add]);
  const onRemove = useCallback(() => remove(index), [index, remove]);
  return (
    <Container style={{ flexDirection: reverse ? 'row-reverse' : 'row' }}>
      <Button type="primary" onClick={onAdd}>
        <PlusCircleOutlined />
      </Button>
      {!hiddenRemove ? (
        <Button danger onClick={onRemove}>
          <MinusCircleOutlined />
        </Button>
      ) : (
        // ボタンの代わりのスペース
        <div style={{ width: '45px' }} />
      )}
    </Container>
  );
};

const Container = styled(Space)`
  margin-bottom: 8px;
`;
