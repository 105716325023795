import { useCallback, useEffect, useState } from 'react';

export default function useAPIData<T>(callBack: () => Promise<T>) {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  const reset = useCallback(() => {
    setData(null);
    setLoading(false);
    setError(null);
  }, []);

  useEffect(() => {
    if (!loading && data == null && error == null) {
      (async () => {
        setLoading(true);
        try {
          const data = await callBack();
          setData(data);
        } catch (e) {
          setError(e);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [callBack, data, error, loading]);

  return { data, loading, error, reset };
}
