import { Alert, Button, Modal } from 'antd';
import Input from 'antd/lib/input/Input';
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useAsyncFn } from 'react-use';
import styled from 'styled-components';

import { accountingCategoriesApi } from '../../api/apiClient';
import getErrorBrief from '../../utils/getErrorBrief';
import { notifyError } from '../../utils/notification';

const StyledInput = styled(Input)`
  margin-bottom: 8px;
`;

interface Props {
  open: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  updateAccountingCategories: () => void;
  existingAccountNames: Set<string>;
}
const AccountingCategoryCreateModal = ({
  existingAccountNames,
  open,
  setModalOpen,
  updateAccountingCategories,
}: Props) => {
  const [accountName, setAccountName] = useState<string | undefined>(undefined);
  const [accountCode, setAccountCode] = useState<string | undefined>(undefined);

  const [state, addAccount] = useAsyncFn(async () => {
    try {
      accountName &&
        (await accountingCategoriesApi.accountingCategoriesControllerCreateOrUpdateAccountingCategory({
          account: accountName,
          account_code: accountCode,
        }));
    } catch (error) {
      notifyError(getErrorBrief(error));
    }
  }, [accountName, accountCode]);

  const exists = useMemo(() => existingAccountNames.has(accountName || ''), [accountName, existingAccountNames]);

  const onOk = useCallback(async () => {
    await addAccount();
    setModalOpen(false);
    setAccountName(undefined);
    setAccountCode(undefined);
    updateAccountingCategories();
  }, [setModalOpen, updateAccountingCategories, addAccount]);

  const onClose = useCallback(() => {
    setModalOpen(false);
    setAccountName(undefined);
    setAccountCode(undefined);
  }, [setModalOpen]);

  const onChangeAccountName = useCallback((e) => setAccountName(e.target.value), []);
  const onChangeAccountCode = useCallback((e) => setAccountCode(e.target.value), []);

  return (
    <Modal
      centered
      footer={[
        <Button key="cancel" onClick={onClose}>
          キャンセル
        </Button>,
        <Button key="register" disabled={!accountName || exists} loading={state.loading} type="primary" onClick={onOk}>
          登録
        </Button>,
      ]}
      title="勘定科目追加"
      visible={open}
      onCancel={onClose}
      onOk={onOk}>
      <StyledInput
        required
        placeholder="勘定科目名を入力してください"
        prefix="勘定科目名: "
        value={accountName}
        onChange={onChangeAccountName}
      />
      <StyledInput
        placeholder="勘定科目コードを入力してください"
        prefix="勘定科目コード: "
        value={accountCode}
        onChange={onChangeAccountCode}
      />
      {exists && <Alert showIcon message="すでに同じ名称の勘定科目が存在します。" type="error" />}
    </Modal>
  );
};

export default AccountingCategoryCreateModal;
