import { Button, InputNumber } from 'antd';
import { useState } from 'react';
import { useAsyncRetry } from 'react-use';
import styled from 'styled-components';

import { companiesApi } from '../../api/apiClient';
import { notifyError, notifySuccess } from '../../utils/notification';
import { SettingItemHeaderLabel } from './SettingItemHeaderLabel';
import { SettingItemLayout } from './SettingItemLayout';

const StyledButton = styled(Button)`
  margin-left: 16px;
`;

const RetentionPeriodSetting = () => {
  const [value, setValue] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const { retry, value: period } = useAsyncRetry(async () => {
    const { data: company } = await companiesApi.companyControllerGetCompany();
    const period = company.data_retention_period;
    period && setValue(period);
    setLoading(false);
    return period;
  });

  const save = async () => {
    if (value === null || value === undefined) {
      notifyError('保持期間を入力してください');
      return;
    } else if (value < 1 || value > 90) {
      notifyError('保持期間は1日以上90日以下で入力してください');
      return;
    }
    setLoading(true);
    await companiesApi.companyControllerChangeRetentionPeriod({ period: value });
    retry();
    notifySuccess('保持期間を変更しました');
  };
  return (
    <SettingItemLayout>
      <SettingItemHeaderLabel>読み取りデータ保持期間の設定</SettingItemHeaderLabel>
      <p>
        読み取り結果、アップロードしたファイルが削除されます。
        <br />
        ※読み取り履歴(読み取り日時、実施者、ファイル名等)は残ります。
      </p>
      <p>最大保持期間は90日です。</p>
      <InputNumber defaultValue={90} max={90} min={1} value={value} onChange={setValue} />
      日以前のデータを自動的に削除する
      <StyledButton disabled={!value || period === value} loading={loading} type="primary" onClick={save}>
        保存
      </StyledButton>
    </SettingItemLayout>
  );
};

export default RetentionPeriodSetting;
