import { Button, Modal, Space } from 'antd';
import { useCallback } from 'react';
import { useRecoilValueLoadable } from 'recoil';

import { User } from '../../generated-api';
import { meSelectorAsync } from '../../store/useMeStore';

type Props = {
  user: User;
  onClickEdit: (userId: string, user: User) => void;
  onClickDelete: (userId: string) => void;
};

const UserAdministrationButtons = ({ onClickDelete, onClickEdit, user }: Props) => {
  const meLoadable = useRecoilValueLoadable(meSelectorAsync);
  const editUser = useCallback(() => onClickEdit(user.user_id, user), [user, onClickEdit]);
  const deleteUser = useCallback(() => {
    Modal.confirm({
      title: `ユーザ｢${user.name}｣を削除しますか？`,
      okText: 'はい',
      cancelText: 'いいえ',
      onOk: () => onClickDelete(user.user_id),
    });
  }, [user, onClickDelete]);

  return (
    <Space size="middle">
      <Button type="primary" onClick={editUser}>
        編集
      </Button>
      {meLoadable.state == 'hasValue' && (
        <Button danger disabled={meLoadable.contents.user_id == user.user_id} type="primary" onClick={deleteUser}>
          ユーザ削除
        </Button>
      )}
    </Space>
  );
};

export default UserAdministrationButtons;
