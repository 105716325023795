import { NamePath } from 'antd/lib/form/interface';
import Input from 'antd/lib/input/Input';
import { ReactElement, ReactNode } from 'react';

import FormItemWithLabel from '../../../components/Form/FormItemWithLabel';
import { DocumentInvoiceEntity, InvoiceDetail, Tax, TransferDestination } from '../../../generated-api';
import ocrTypeNameDefinitions from '../../../utils/ocrTypeNameDefinitions';
import InvoiceDetailForm from '../Invoice/InvoiceDetailForm';
import { InvoiceTable } from '../Invoice/InvoiceTable';
import TransferDestinationForm from '../Invoice/TransferDestinationForm';
import TrainingPermissionCheckbox from '../TrainingPermissionCheckbox';
import renderTableColumn from './renderTableColumn';
import { CSVConvertResult, OcrResultPageDefinition, ResultPageDocument } from './types';

type StyledInputProps = {
  label: string;
  name: string;
  path?: NamePath;
};
function StyledInput({ label, name, path }: StyledInputProps): ReactElement {
  return (
    <FormItemWithLabel label={label} labelWidth="7rem" name={name} path={path}>
      <Input />
    </FormItemWithLabel>
  );
}

type FormProps = {
  documentId: string;
  entity: DocumentInvoiceEntity;
  groupId: string;
  setChanged: (newValue: boolean) => void;
  path?: NamePath;
};
function InvoiceFormInputs({ documentId, entity, groupId, setChanged, path = [] }: FormProps): ReactElement {
  return (
    <>
      <StyledInput label="請求日" name="invoice_date" path={path} />
      <StyledInput label="請求元事業者名" name="biller_company_name" path={path} />
      <StyledInput label="請求元事業者番号" name="biller_company_number" path={path} />
      <StyledInput label="請求先事業者名" name="billing_company_name" path={path} />
      <TransferDestinationForm
        path={path}
        setChanged={setChanged}
        transferDestinations={entity.transfer_destinations ?? []}
      />
      <InvoiceDetailForm entity={entity} path={path} setChanged={setChanged} />
      <TrainingPermissionCheckbox documentId={documentId} groupId={groupId} />
    </>
  );
}

export function buildInvoiceDetail(entity: DocumentInvoiceEntity): InvoiceDetail {
  const initialTaxPercent = entity.tax?.[0].percent ?? 10;
  const initialTaxAmount = entity.tax?.[0].amount ?? 0;
  const initialAmountWithTax = entity.total_invoice_amount ?? 0;
  const initialAmountWithoutTax = initialAmountWithTax == 0 ? 0 : initialAmountWithTax - initialTaxAmount;
  const initialAccount = entity.invoice_details?.[0].account ?? '';
  const initialAccountCode = entity.invoice_details?.[0].account_code ?? '';
  return {
    tax_percent: initialTaxPercent,
    amount_without_tax: initialAmountWithoutTax,
    tax_amount: initialTaxAmount,
    amount_with_tax: initialAmountWithTax,
    account: initialAccount,
    account_code: initialAccountCode,
  };
}

function expandInvoiceTable(record: ResultPageDocument<DocumentInvoiceEntity>): ReactNode {
  if (record.error) {
    return;
  }
  const initialDetail = buildInvoiceDetail(record.latest_revision);
  return <InvoiceTable invoiceDetails={record.latest_revision.invoice_details ?? [initialDetail]} />;
}

type FormResult = {
  invoice_date: string;
  biller_company_name: string;
  biller_company_number: string;
  billing_company_name: string;
  transfer_destination: Record<string, TransferDestination>;
  invoice_detail: Record<string, InvoiceDetail>;
  total_invoice_amount: number;
  invoiceDetailRowIndices: Record<string, string>[];
  transferDestinationRowIndices: Record<string, string>[];
};
function formDataToEntity(data: FormResult): DocumentInvoiceEntity {
  const transferDestinationRows = data.transferDestinationRowIndices.map((row) => Object.keys(row)[0]);
  const transferDestinations = transferDestinationRows.map((rowId) => data.transfer_destination[rowId]);
  const invoiceDetailRows = data.invoiceDetailRowIndices.map((row) => Object.keys(row)[0]);
  const invoiceDetails = invoiceDetailRows.map((rowId) => data.invoice_detail[rowId]);
  const tax: Tax[] = invoiceDetails.map((detail) => ({ percent: detail.tax_percent, amount: detail.tax_amount }));
  const totalInvoiceAmount = invoiceDetails.reduce(
    (accumulator, current) => accumulator + (current.amount_with_tax ?? 0),
    0
  );

  return {
    invoice_date: data.invoice_date,
    biller_company_name: data.biller_company_name,
    biller_company_number: data.biller_company_number,
    billing_company_name: data.billing_company_name,
    transfer_destinations: transferDestinations,
    invoice_details: invoiceDetails,
    total_invoice_amount: totalInvoiceAmount,
    tax: tax,
    type: 'invoice',
  };
}

function convertInvoiceCsv(data: ResultPageDocument<DocumentInvoiceEntity>[]): CSVConvertResult {
  const invoices = data.map((item) => {
    return {
      summary: {
        ファイル名: item.parsed_path.fullFileName,
        ページ数: item.page?.toString() ?? '-',
        請求日: item.latest_revision.invoice_date ?? '',
        請求元事業者名: item.latest_revision.biller_company_name ?? '',
        請求元事業者番号: item.latest_revision.biller_company_number ?? '',
        請求先事業者名: item.latest_revision.billing_company_name ?? '',
      },
      latest_revision: item.latest_revision,
    };
  });

  const csvData = invoices.flatMap((item) => {
    const transferDestinations = item.latest_revision.transfer_destinations?.reduce<Record<string, string>>(
      (accumulator, destination, index) => {
        accumulator[`金融機関名_${index + 1}`] = destination.bank_name ?? '';
        accumulator[`支店名_${index + 1}`] = destination.branch_name ?? '';
        accumulator[`口座種別_${index + 1}`] = destination.account_type ?? '';
        accumulator[`口座番号･記号番号(ゆうちょ)_${index + 1}`] = destination.account_number ?? '';
        accumulator[`口座名義_${index + 1}`] = destination.account_holder ?? '';
        return accumulator;
      },
      {
        金融機関名_1: '',
        支店名_1: '',
        口座種別_1: '',
        ['口座番号･記号番号(ゆうちょ)_1']: '',
        口座名義_1: '',
      }
    );

    const initialInvoiceDetail = buildInvoiceDetail(item.latest_revision);

    const defaultDetail = {
      税率: initialInvoiceDetail.tax_percent?.toString() ?? '10',
      税抜き金額: initialInvoiceDetail.amount_without_tax?.toString() ?? '',
      税額: initialInvoiceDetail.tax_amount?.toString() ?? '',
      税込み金額: initialInvoiceDetail.amount_with_tax?.toString() ?? '',
      勘定科目名: initialInvoiceDetail.account ?? '-',
      勘定科目コード: initialInvoiceDetail.account_code ?? '-',
      金融機関名_1: transferDestinations?.['金融機関名_1'] ?? '',
      支店名_1: transferDestinations?.['支店名_1'] ?? '',
      口座種別_1: transferDestinations?.['口座種別_1'] ?? '',
      ['口座番号･記号番号(ゆうちょ)_1']: transferDestinations?.['口座番号･記号番号(ゆうちょ)_1'] ?? '',
      口座名義_1: transferDestinations?.['口座名義_1'] ?? '',
    };

    const row = item.latest_revision.invoice_details?.map((detail) => {
      return {
        ...item.summary,
        税率: detail.tax_percent?.toString() ?? '',
        税抜き金額: detail.amount_without_tax?.toString() ?? '',
        税額: detail.tax_amount?.toString() ?? '',
        税込み金額: detail.amount_with_tax?.toString() ?? '',
        勘定科目名: detail.account ?? '',
        勘定科目コード: '-',
        ...transferDestinations,
      };
    }) ?? [{ ...item.summary, ...defaultDetail }];
    return row;
  });

  return { csv: csvData };
}

const invoiceResultPageDefinition: OcrResultPageDefinition<DocumentInvoiceEntity> = {
  name: ocrTypeNameDefinitions.invoice,
  tabs: [
    {
      csvColumns: {},
      tableColumns: [
        { dataIndex: ['latest_revision', 'invoice_date'], title: '請求日', render: renderTableColumn },
        { dataIndex: ['latest_revision', 'biller_company_name'], title: '請求元事業者名', render: renderTableColumn },
        {
          dataIndex: ['latest_revision', 'biller_company_number'],
          title: '請求元事業者番号',
          render: renderTableColumn,
        },
        { dataIndex: ['latest_revision', 'billing_company_name'], title: '請求先事業者名', render: renderTableColumn },
      ],
      tableExpandable: {
        expandedRowRender: expandInvoiceTable,
      },
    },
  ],
  formDataToEntity,
  formComponent: InvoiceFormInputs,
  csvDataConverters: [
    {
      converter: convertInvoiceCsv,
    },
  ],
};

export default invoiceResultPageDefinition;
