import { Form, Input, Space } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { ComponentType, ReactElement } from 'react';
import styled from 'styled-components';

import getPath from '../../utils/getPath';
import { FluctuationButton } from './FluctuationButton';

const BlankContainer = () => {
  return <></>;
};
interface Props {
  columnNames: Record</* columnId */ string, /* name */ string>;
  columnIds: string[];
  addColumn: (index: number) => void;
  removeColumn: (index: number) => void;
  prependHeaderComponent?: ComponentType;
  path?: NamePath;
}

export const ColumnNameLabel = ({
  addColumn,
  columnIds,
  columnNames,
  removeColumn,
  path,
  prependHeaderComponent: PrependHeaderComponent = BlankContainer,
}: Props): ReactElement => {
  return (
    <ColumnContainer>
      <PrependHeaderComponent />
      <Labels>
        {columnIds.map((columnId, index) => {
          return (
            <Columns key={columnId}>
              <FluctuationButton
                reverse
                add={addColumn}
                hiddenRemove={columnIds.length <= 1}
                index={index}
                remove={removeColumn}
              />
              {/* nameプロパティにcolumn_namesを指定するとentityと被ってバグる */}
              <Form.Item initialValue={columnNames[columnId] ?? `new column`} name={getPath(path, 'columns', columnId)}>
                <ColumnInput />
              </Form.Item>
            </Columns>
          );
        })}
      </Labels>
    </ColumnContainer>
  );
};

const ColumnContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Labels = styled(Space)`
  display: flex;
  margin-left: calc(1rem + 8px);
  align-items: flex-start;
`;

const Columns = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ColumnInput = styled(Input)`
  width: 150px;
`;
