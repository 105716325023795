import { Descriptions } from 'antd';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import PageLayout from '../../components/Container/PageLayout';
import { meSelector } from '../../store/useMeStore';

const MyPage = () => {
  const { email, name, role } = useRecoilValue(meSelector);
  const roleName = role == 'admin' ? '管理者' : '一般ユーザ';
  return (
    <PageLayout heading="マイページ">
      <UserInfo bordered colon={false} column={1}>
        <Descriptions.Item label="名前">{name}</Descriptions.Item>
        <Descriptions.Item label="メールアドレス">{email}</Descriptions.Item>
        <Descriptions.Item label="権限">{roleName}</Descriptions.Item>
      </UserInfo>
    </PageLayout>
  );
};

export default MyPage;

const UserInfo = styled(Descriptions)`
  max-width: 768px;
`;
