import { DeleteOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { useCallback, useState } from 'react';
import styled from 'styled-components';

type Props = {
  onApply: (value: string) => void;
};

export default function FilterDropdown({ onApply }: Props) {
  const [value, setValue] = useState('');

  const onApplyHandler = useCallback(
    (v: string) => {
      onApply(v);
    },
    [onApply]
  );

  const onClear = () => {
    onApply('');
    setValue('');
  };

  return (
    <div style={{ display: 'flex', border: '1px solid #ccc', boxShadow: '2px 3px 6px #aaa' }}>
      <Input.Search
        autoFocus
        value={value}
        onChange={({ currentTarget: { value } }) => setValue(value)}
        onPressEnter={() => onApplyHandler(value)}
        onSearch={() => onApplyHandler(value)}
      />
      <Button icon={<DeleteIcon />} style={{ marginLeft: '-2px' }} type="default" onClick={onClear} />
    </div>
  );
}

const DeleteIcon = styled(DeleteOutlined)`
  svg {
    fill: rgba(255, 0, 0, 0.45);
  }
`;
