import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { format, parseISO } from 'date-fns';

import { DocumentType } from '../../generated-api';
import ocrTypeNameDefinitions from '../../utils/ocrTypeNameDefinitions';

interface StatsFormTableData extends Partial<Record<DocumentType, number>> {
  month: string;
  total: number;
  confirmed: boolean; // 未確定ならfalse
}

type Props = {
  formDataSource: StatsFormTableData[] | undefined;
  loading: boolean;
};

const StatsFormTable = ({ formDataSource, loading }: Props) => {
  const columns: ColumnsType<StatsFormTableData> = [
    {
      title: 'ご利用月',
      dataIndex: 'month',
      render: (value, record) => {
        const formattedDate = format(parseISO(value), 'yyyy/M');
        if (record.confirmed) {
          return formattedDate;
        } else {
          return '（未確定）' + formattedDate;
        }
      },
      width: 300,
      align: 'right',
    },
    {
      title: '利用枚数',
      children: [
        {
          title: ocrTypeNameDefinitions.furusato_tax,
          dataIndex: 'furusato_tax',
          render: renderColumn,
          width: 240,
          align: 'right',
        },
        {
          title: ocrTypeNameDefinitions.medical_bill,
          dataIndex: 'medical_bill',
          render: renderColumn,
          width: 240,
          align: 'right',
        },
        {
          title: ocrTypeNameDefinitions.bankbook,
          dataIndex: 'bankbook',
          render: renderColumn,
          width: 240,
          align: 'right',
        },
        {
          title: ocrTypeNameDefinitions.card_statement,
          dataIndex: 'card_statement',
          render: renderColumn,
          width: 240,
          align: 'right',
        },
        {
          title: ocrTypeNameDefinitions.withholding_slip,
          dataIndex: 'withholding_slip',
          render: renderColumn,
          width: 240,
          align: 'right',
        },
        {
          title: ocrTypeNameDefinitions.receipt,
          dataIndex: 'receipt',
          render: renderColumn,
          width: 240,
          align: 'right',
        },
        {
          title: ocrTypeNameDefinitions.payment_record,
          dataIndex: 'payment_record',
          render: renderColumn,
          width: 240,
          align: 'right',
        },
        {
          title: ocrTypeNameDefinitions.general_table,
          dataIndex: 'general_table',
          render: renderColumn,
          width: 240,
          align: 'right',
        },
        {
          title: ocrTypeNameDefinitions.invoice,
          dataIndex: 'invoice',
          render: renderColumn,
          width: 240,
          align: 'right',
        },
        {
          title: '合計枚数',
          dataIndex: 'total',
          render: renderColumn,
          width: 240,
          align: 'right',
        },
      ],
    },
  ];

  return (
    <Table
      bordered={true}
      columns={columns}
      dataSource={formDataSource}
      loading={loading}
      pagination={false}
      rowKey="month"
    />
  );
};

function renderColumn(value: number) {
  return <>{(value ?? 0).toLocaleString() + '枚'}</>;
}

export default StatsFormTable;
