import { createContext, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';

import { Document, DocumentType } from '../generated-api';
import useOcrPageDefinition from '../hooks/useOcrPageDefinition';
import { EditModalEntity } from '../page/OcrResult/defs/types';
import DocumentEditModal from '../page/OcrResult/DocumentEdit/DocumentEditModal';
import useDocumentListStore from '../store/useDocumentListStore';

interface EditModalContextType {
  open: (documentId: string) => void;
}

const EditModalContext = createContext<EditModalContextType>({
  open: () => {
    /* nop */
  },
});

type Props = PropsWithChildren<{
  groupId: string;
  type: DocumentType;
}>;

export default function useEditModalContext() {
  const { open } = useContext(EditModalContext);
  return { open };
}

export function EditModalContextProvider({ children, groupId, type }: Props) {
  // モーダルを開いたときに最初に表示する documentId を保持してモーダルの状態を制御する
  // モーダルの「次へ」「前へ」を押しても変化しない
  const [initialDocumentId, setInitialDocumentId] = useState<string>();

  const { actions, ocrResults } = useDocumentListStore(groupId);

  const def = useOcrPageDefinition(type);

  const modalEntities = useMemo<EditModalEntity<unknown>[] | null>(() => {
    if (def.entityToModalEntities) {
      return def.entityToModalEntities(ocrResults ?? []);
    } else {
      return (
        ocrResults?.map((item) => ({
          documentId: item.document_id,
          fileName: item.parsed_path.fullFileName,
          entity: item.latest_revision,
          groupId: item.group_id,
          pageNumber: item.page ?? 0,
        })) ?? null
      );
    }
  }, [def, ocrResults]);

  const [currentIndex, setCurrentIndex] = useState(0);

  const open = useCallback(
    (documentId: string) => {
      const index = modalEntities?.findIndex((item) => item.documentId === documentId);
      if (index == null || index === -1) {
        // ここには来ない想定だが、万が一起きても一応動くように Error は throw しない
        console.error(`[ModalContext]: Unexpected document(documentId=${documentId})`);
        setCurrentIndex(0);
      } else {
        setCurrentIndex(index);
      }
      setInitialDocumentId(documentId);
    },
    [modalEntities]
  );

  const onSaveDocument = useCallback(async (_document: Document) => {
    // nop
  }, []);

  const onClose = useCallback(
    (_documentId: string | undefined) => {
      setInitialDocumentId(undefined);
      actions.fetchDocumentList();
      setCurrentIndex(0);
    },
    [actions]
  );

  const value: EditModalContextType = useMemo(
    () => ({
      open,
      onSaveDocument,
    }),
    [onSaveDocument, open]
  );

  return (
    <EditModalContext.Provider value={value}>
      {children}
      {modalEntities && initialDocumentId && (
        <DocumentEditModal
          currentIndex={currentIndex}
          documentId={modalEntities[currentIndex].documentId}
          entity={modalEntities[currentIndex]}
          groupId={groupId}
          hasNext={modalEntities[currentIndex + 1] != null}
          hasPrev={modalEntities[currentIndex - 1] != null}
          onClickNext={() => setCurrentIndex((prev) => prev + 1)}
          onClickPrev={() => setCurrentIndex((prev) => prev - 1)}
          onClose={onClose}
          onSaveButtonClick={onSaveDocument}
        />
      )}
    </EditModalContext.Provider>
  );
}
