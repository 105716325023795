import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useCallback, useState } from 'react';
import styled from 'styled-components';

import { documentApi } from '../../../api/apiClient';
import useDocumentListStore from '../../../store/useDocumentListStore';
import { notifyError } from '../../../utils/notification';
import { DocumentEntities, ResultPageDocument } from '../defs/types';

interface Props {
  record: ResultPageDocument<DocumentEntities>;
}

export default function FileNameColumn({ record }: Props) {
  const [editable, setEditable] = useState(false);
  return editable ? (
    <EditForm record={record} setEditable={setEditable} />
  ) : (
    <FileName
      filePath={record.user_file_path}
      fullFileName={record.parsed_path.fullFileName}
      setEditable={setEditable}
    />
  );
}

interface FileNameProps {
  filePath: string;
  fullFileName: string;
  setEditable: (editable: boolean) => void;
}
function FileName({ filePath, fullFileName, setEditable }: FileNameProps) {
  const edit = useCallback(() => {
    setEditable(true);
  }, [setEditable]);
  return (
    <FileNameContainer>
      <FileNameLabel title={filePath}>{fullFileName}</FileNameLabel>
      <Button icon={<EditOutlined />} type="text" onClick={edit} />
    </FileNameContainer>
  );
}

interface EditColumnProps {
  record: ResultPageDocument<DocumentEntities>;
  setEditable: (editable: boolean) => void;
}
function EditForm({ record, setEditable }: EditColumnProps) {
  const [form] = useForm();
  const [changed, setChanged] = useState(false);
  const { actions } = useDocumentListStore(record.group_id);

  const save = useCallback(async () => {
    if (!record.s3_path) {
      notifyError('ファイルが見つかりませんでした。');
      return;
    }
    await documentApi.documentControllerChangeFileName(record.group_id, {
      fileName: form.getFieldValue('fileName'),
      s3FilePath: record.s3_path,
    });
    await actions.fetchDocumentList();
    setEditable(false);
  }, [actions, form, record.group_id, record.s3_path, setEditable]);
  const cancel = useCallback(() => {
    setEditable(false);
  }, [setEditable]);
  const onChange = useCallback(() => {
    setChanged(true);
  }, []);

  return (
    <FormContainer form={form} onFinish={save} onValuesChange={onChange}>
      <FormItem initialValue={record.parsed_path.fullFileName} name="fileName">
        <Input />
      </FormItem>
      <FormItem name="edit">
        <Button disabled={!changed} htmlType="submit" icon={<CheckOutlined />} type="primary" />
      </FormItem>
      <FormItem name="cancel">
        <Button danger icon={<CloseOutlined />} type="primary" onClick={cancel} />
      </FormItem>
    </FormContainer>
  );
}

const FileNameContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FileNameLabel = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  max-width: 500px;
`;

const FormContainer = styled(Form)`
  display: flex;
  gap: 8px;
  width: 100%;
  min-width: 200px;
`;

const FormItem = styled(Form.Item)`
  margin-bottom: 0;
`;
