import { useAsyncRetry } from 'react-use';

import { documentApi } from '../../api/apiClient';
import PageLayout from '../../components/Container/PageLayout';
import SearchForm from './SearchForm';
import SearchTable from './SearchTable';
import useSearchQuery from './useSearchQuery';

const SearchPage = () => {
  const { setQuery, values: searchQuery } = useSearchQuery();
  const {
    loading,
    retry,
    value: searchResults,
  } = useAsyncRetry(async () => {
    const { data: searchResults } = await documentApi.documentControllerSearch(
      searchQuery.type,
      searchQuery.user,
      searchQuery.since,
      searchQuery.until,
      searchQuery.fileName
    );
    return searchResults;
  }, [searchQuery]);

  return (
    <PageLayout heading="過去読み取り結果">
      <SearchForm
        defaultValue={searchQuery}
        onSubmit={async (values) => {
          setQuery(values);
          retry();
        }}
      />
      <SearchTable loading={loading} results={searchResults ?? []} />
    </PageLayout>
  );
};

export default SearchPage;
