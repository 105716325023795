import { Button, Modal } from 'antd';
import Input from 'antd/lib/input/Input';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useAsyncFn } from 'react-use';
import styled from 'styled-components';

import { accountingCategoriesApi } from '../../api/apiClient';
import { notifyError } from '../../utils/notification';

const StyledInput = styled(Input)`
  margin-bottom: 8px;
`;

interface Props {
  open: boolean;
  accountId?: string;
  accountName?: string;
  initAccountCode?: string;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  updateAccountingCategories: () => void;
}
const AccountingCategoryEditModal = ({
  accountName,
  initAccountCode,
  open,
  setModalOpen,
  updateAccountingCategories,
}: Props) => {
  const [accountCode, setAccountCode] = useState<string | undefined>(undefined);

  const [state, editAccountCode] = useAsyncFn(async () => {
    try {
      accountCode &&
        accountName &&
        (await accountingCategoriesApi.accountingCategoriesControllerCreateOrUpdateAccountingCategory({
          account: accountName,
          account_code: accountCode,
        }));
    } catch (error) {
      notifyError('エラーが発生しました。');
    }
  }, [accountCode]);

  const onOk = useCallback(async () => {
    await editAccountCode();
    setModalOpen(false);
    setAccountCode(undefined);
    updateAccountingCategories();
  }, [setModalOpen, updateAccountingCategories, editAccountCode]);

  const onClose = useCallback(() => {
    setModalOpen(false);
    setAccountCode(undefined);
  }, [setModalOpen]);

  const onChangeAccountCode = useCallback((e) => setAccountCode(e.target.value), []);

  return (
    <Modal
      centered
      footer={[
        <Button key="cancel" onClick={onClose}>
          キャンセル
        </Button>,
        <Button key="register" disabled={!accountCode} loading={state.loading} type="primary" onClick={onOk}>
          登録
        </Button>,
      ]}
      title="勘定科目コード編集"
      visible={open}
      onCancel={onClose}
      onOk={onOk}>
      <StyledInput disabled prefix="勘定科目名: " value={accountName} />
      <StyledInput
        placeholder="勘定科目コードを入力してください"
        prefix="勘定科目コード: "
        value={accountCode ?? initAccountCode}
        onChange={onChangeAccountCode}
      />
    </Modal>
  );
};

export default AccountingCategoryEditModal;
