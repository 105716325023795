import { Form, Input, Select } from 'antd';
import { useCallback, useState } from 'react';
import styled from 'styled-components';

import { additionalFormChoice } from './additionalFormChoice';

interface AdditionalFormProps {
  availableOptions: string[];
  selectedOptions: string[];
  setSelectedOptions: (options: string[]) => void;
}
const AdditionalFormRoot = styled.div`
  display: flex;
  gap: 8px;
`;
const InputForm = styled(Form.Item)`
  flex-grow: 1;
`;

export function AdditionalFormRow({ availableOptions, selectedOptions, setSelectedOptions }: AdditionalFormProps) {
  const [formName, setFormName] = useState<string>();
  const [current, setCurrent] = useState<string>();
  const onChange = useCallback(
    (value: string) => {
      const newSelectedOptions = selectedOptions.filter((item) => item !== current);
      setSelectedOptions([...newSelectedOptions, value]);
      setFormName(value);
      setCurrent(value);
    },
    [current, selectedOptions, setSelectedOptions]
  );
  return (
    <AdditionalFormRoot>
      <Form.Item>
        <Select
          options={availableOptions.map((item) => ({ label: additionalFormChoice[item], value: item }))}
          placeholder="選択してください"
          style={{ width: 160 }}
          onChange={onChange}
        />
      </Form.Item>
      <InputForm name={formName}>
        <Input />
      </InputForm>
    </AdditionalFormRoot>
  );
}
