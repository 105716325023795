import { ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 1000px;
  padding: 0 24px;
`;

interface Props {
  children: ReactNode;
}
export const ContentContainer = ({ children }: Props) => {
  return <Container>{children}</Container>;
};
