import { Button, Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Rule } from 'antd/lib/form';
import { useHistory } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import styled from 'styled-components';

import { authApi } from '../../api/apiClient';
import AuthPageContainer from '../../components/Container/AuthPageContainer';
import useQueryParams from '../../hooks/useQueryParams';
import { useAuthStore } from '../../store/useAuthStore';
import { useFetchMe } from '../../store/useMeStore';

const passwordRules: Rule[] = [{ required: true, message: 'この項目は必須です' }];
const confirmRules: Rule[] = [
  { required: true, message: 'この項目は必須です' },
  ({ getFieldValue }) => ({
    async validator(_, value) {
      if (!value || getFieldValue('password') === value) {
        return;
      }
      throw new Error('入力した2つのパスワードが一致しません');
    },
  }),
];

const PasswordChangePage = () => {
  const query = useQueryParams();
  const [form] = useForm();
  const history = useHistory();
  const { isAuthorized } = useAuthStore();
  const fetchMe = useFetchMe();

  const [state, change] = useAsyncFn(async () => {
    const password: string = form.getFieldValue('password');

    const user_id = query.get('user_id') ?? '';
    const expire = Number(query.get('expire'));
    const token = query.get('token') ?? '';
    const signature = query.get('signature') ?? '';

    if (isAuthorized) {
      await authApi.authControllerChangePassword({ password });
      await fetchMe();
    } else {
      await authApi.authControllerChangePasswordWithSignature({
        user_id,
        token,
        expire,
        password,
        signature,
      });
      history.push('/login');
      return;
    }

    history.push('/');
  }, [form, history, query, fetchMe, isAuthorized]);

  return (
    <AuthPageContainer>
      <Form form={form} layout="vertical" requiredMark={false}>
        <FormItem label="新しいパスワード" name="password" rules={passwordRules}>
          <FormInput type="password" />
        </FormItem>
        <FormItem dependencies={['password']} label="新しいパスワード再入力" name="confirm" rules={confirmRules}>
          <FormInput type="password" />
        </FormItem>
        <FormItem>
          <SubmitButton block htmlType="submit" loading={state.loading} type="primary" onClick={change}>
            パスワード変更
          </SubmitButton>
        </FormItem>
      </Form>
    </AuthPageContainer>
  );
};

export default PasswordChangePage;

const FormItem = styled(Form.Item)`
  width: 500px;
`;

const FormInput = styled(Input.Password)`
  height: 45px;
`;

const SubmitButton = styled(Button)`
  height: 50px;
`;
