import { Table } from 'antd';
import Column from 'antd/lib/table/Column';
import { ReactElement } from 'react';

import { InvoiceDetail } from '../../../generated-api';

type Props = {
  invoiceDetails: InvoiceDetail[];
};

export function InvoiceTable({ invoiceDetails }: Props): ReactElement {
  const data: Record<string, string | number>[] = invoiceDetails?.map((detail, index) => {
    return {
      key: index,
      tax_percent: detail.tax_percent ?? 10,
      amount_without_tax: detail.amount_without_tax ?? 0,
      tax_amount: detail.tax_amount ?? 0,
      amount_with_tax: detail.amount_with_tax ?? 0,
      account: detail.account ?? '-',
    };
  });

  return (
    <Table dataSource={data} pagination={false}>
      <Column key="tax_percent" dataIndex="tax_percent" title="税率" />
      <Column key="amount_without_tax" dataIndex="amount_without_tax" title="税抜き金額" />
      <Column key="tax_amount" dataIndex="tax_amount" title="税額" />
      <Column key="amount_with_tax" dataIndex="amount_with_tax" title="税込み金額" />
      <Column key="account" dataIndex="account" title="勘定科目名" />
    </Table>
  );
}
