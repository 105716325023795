import { CSVData } from '../page/OcrResult/defs/types';

export default function createCSVData(input: CSVData): string {
  const headers = Array.from(new Set(input.flatMap((record) => Object.keys(record))));

  return input
    .reduce(
      (acc, curr) => [...acc, headers.map((header) => `"${escapeCSVValue(curr[header])}"`).join(',')],
      [headers.map((head) => `"${escapeCSVValue(head)}"`).join(',')]
    )
    .join('\n');
}

function escapeCSVValue(value: string | undefined) {
  const stringValue = String(value ?? '');
  return stringValue?.replaceAll('"', '""') ?? '';
}
