import { Spin } from 'antd';
import { CSSProperties } from 'styled-components';

type Props = {
  style?: CSSProperties;
};

const LoadingIcon = ({ style }: Props) => <Spin size="default" style={style} />;

export default LoadingIcon;
