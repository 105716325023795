import parseCurrencyInt from '../../utils/parseCurrencyInt';

export function calcBalance(
  selectValue: string,
  rows: string[][],
  columnNames: string[],
  columnNameMapper: Map<string, string>
) {
  const getSpecifiedColumnIndex = (specifiedColumnName: string) => {
    return columnNames.findIndex((el) => el == specifiedColumnName || el == columnNameMapper.get(specifiedColumnName));
  };

  const withdrawalsColumnIndex = getSpecifiedColumnIndex('withdrawals');
  const depositsColumnIndex = getSpecifiedColumnIndex('deposits');

  const columnIndex = getSpecifiedColumnIndex(selectValue);

  const calcResult = rows.map((row, index) => {
    const prevRow = rows[index - 1];
    if (!prevRow) {
      return '';
    }

    const withdrawalsValue = row[withdrawalsColumnIndex];
    const depositsValue = row[depositsColumnIndex];

    const currentRowValue = row[columnIndex];
    const prevRowValue = prevRow[columnIndex];
    if ((withdrawalsValue && depositsValue) || !currentRowValue || !prevRowValue) {
      return 'false';
    }

    // if (withdrawalsValue || depositsValue) {
    //   const balance =
    //     parseCurrencyInt(prevRowValue) -
    //     parseCurrencyInt(withdrawalsValue || '0') +
    //     parseCurrencyInt(depositsValue || '0');
    //   return (balance === parseCurrencyInt(currentRowValue)).toString();
    // }

    const balance = Math.abs(parseCurrencyInt(currentRowValue) - parseCurrencyInt(prevRowValue)).toString();
    return row.some((value) => value === balance).toString();
  });

  return calcResult;
}
