import { Table, Tooltip } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { format, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';

import { SuccessIcon } from '../../components/Icons/StatusIcon';
import UserName from '../../components/User/UserName';
import { DocumentType, GetSearchResponse } from '../../generated-api';
import ocrTypeNameDefinitions from '../../utils/ocrTypeNameDefinitions';

interface Props {
  results: GetSearchResponse[];
  loading: boolean;
}

const columns: ColumnsType<GetSearchResponse> = [
  {
    title: '読み取り日時',
    dataIndex: ['uploaded_at'],
    render: (readDate) => format(parseISO(readDate), 'yyyy-MM-dd'),
    width: '8rem',
  },
  { title: '実施者', dataIndex: ['user_id'], render: (userId) => <UserName userId={userId} /> },
  {
    title: '帳票の種類',
    dataIndex: ['ocr_type'],
    render: (documentType: DocumentType) => ocrTypeNameDefinitions[documentType],
    width: '8rem',
  },
  {
    title: '全データ確認済み',
    render: (_, record) => <SeenAll seenDocuments={record.seen_documents} totalPage={record.total_page} />,
    width: '6rem',
  },
  {
    title: 'ファイル名',
    dataIndex: ['file_names'],
    render: (fileNames) => fileNames && fileNames.join(', '),
  },
  { title: '総ページ数', dataIndex: ['total_page'], width: '8rem' },
  {
    title: '',
    render: (_, record) =>
      record.deleted_at ? (
        <Tooltip title="保存期間外のため削除されました">削除済</Tooltip>
      ) : (
        <DetailButton documentType={record.ocr_type} groupId={record.group_id} />
      ),
    width: '8rem',
  },
];

const pagination: TablePaginationConfig = {
  defaultPageSize: 20,
  showSizeChanger: true,
  position: ['topRight', 'bottomRight'],
};

const SearchTable = ({ loading, results }: Props) => {
  return (
    <Table columns={columns} dataSource={results} loading={loading} pagination={pagination} rowKey="group_id"></Table>
  );
};

interface SeenAllProps {
  seenDocuments: string[];
  totalPage: number;
}

const SeenAll = ({ seenDocuments, totalPage }: SeenAllProps) => {
  if (seenDocuments.length != 0 && seenDocuments.length == totalPage) {
    return <SuccessIcon />;
  }
  return <></>;
};

interface DetailButtonProps {
  documentType: string;
  groupId: string;
}

const DetailButton = ({ documentType, groupId }: DetailButtonProps) => {
  return (
    <Link className="ant-btn ant-btn-primary" to={`/result/${documentType}/${groupId}`}>
      詳細
    </Link>
  );
};

export default SearchTable;
