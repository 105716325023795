import { RotateLeftOutlined, RotateRightOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { CSSProperties, memo, useEffect, useState } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import styled from 'styled-components';

import { documentApi } from '../../api/apiClient';
import useAPIData from '../../hooks/useAPIData';
import LoadingIcon from '../Loading/LoadingIcon';
type Props = {
  groupId: string;
  documentId: string;
};

const imageHeight = '80vh';

const DocumentImage = ({ documentId, groupId }: Props) => {
  const {
    data: previewUrl,
    error,
    reset,
  } = useAPIData(async () => {
    const { data } = await documentApi.documentControllerGetDividedImagePresignedUrl(groupId, documentId);

    return data.image_url;
  });

  useEffect(() => {
    reset();
  }, [documentId, groupId, reset]);

  const { left, reset: resetRotate, right, rotate } = useRotate();

  return (
    <TransformWrapper limitToBounds={false} panning={{ velocityDisabled: true }}>
      {({ resetTransform, zoomIn, zoomOut }) => (
        <>
          <Buttons>
            <Button icon={<RotateLeftOutlined />} onClick={() => left()} />
            <Button onClick={() => zoomIn()}>
              <ZoomInOutlined />
            </Button>
            <Button onClick={() => zoomOut()}>
              <ZoomOutOutlined />
            </Button>
            <Button icon={<RotateRightOutlined />} onClick={() => right()} />
            <Button
              onClick={() => {
                resetTransform();
                resetRotate();
              }}>
              RESET
            </Button>
          </Buttons>
          <TransformComponent wrapperStyle={wrapperStyle}>
            {!error ? (
              <ImageContainer
                style={{
                  transform: `rotateZ(${rotate}deg)`,
                }}>
                {previewUrl == null ? <LoadingIcon style={{ padding: '24px' }} /> : <Image src={previewUrl ?? ''} />}
              </ImageContainer>
            ) : (
              <OcrErrorContainer>読み取り中にエラーが発生したため画像は表示されません。</OcrErrorContainer>
            )}
          </TransformComponent>
        </>
      )}
    </TransformWrapper>
  );
};

export default memo(DocumentImage, (prev, next) => {
  return prev.documentId === next.documentId && prev.groupId === next.groupId;
});

function useRotate() {
  const [rotate, setRotate] = useState(0);

  const left = () => setRotate((deg) => deg - 90);

  const right = () => setRotate((deg) => deg + 90);

  const reset = () => setRotate(0);

  return { left, right, reset, rotate };
}

const wrapperStyle: CSSProperties = {
  width: '100%',
  border: '1px solid #f0f0f0',
  marginRight: '8px',
  overflow: 'scroll',
};

const Buttons = styled(Space)`
  width: 100%;
  margin: 4px 0 8px;
`;

const ImageContainer = styled.div`
  height: ${imageHeight};
  transform-origin: center center;
  transition-property: transform;
  transition-duration: 300ms;
  :hover {
    cursor: grab;
  }
`;

const OcrErrorContainer = styled.div`
  height: ${imageHeight};
  padding: 24px;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`;
