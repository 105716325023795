import { Form, Input, InputNumber, Select } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { set } from 'lodash';
import { ReactElement, useCallback, useMemo } from 'react';
import { useUpdateEffect } from 'react-use';
import styled from 'styled-components';

import { ReceiptDetail, UserAccountingCategories } from '../../../generated-api';
import getPath from '../../../utils/getPath';
import AccountSelectForm from '../AccountSelectForm';
import { FluctuationButton } from '../FluctuationButton';

export const DetailRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(70px, 1fr) minmax(80px, 1fr)) repeat(3, minmax(100px, 1fr));
  grid-gap: 8px;
  align-items: baseline;
`;
const InputNumberStyle = styled(InputNumber)`
  width: 100%;
`;
const RowHeaderLabel = styled.div`
  font-weight: bold;
  padding-left: 4px;
`;
const taxPercentChoice = [
  { label: '-', value: undefined },
  { label: '10%', value: 10 },
  { label: '8%', value: 8 },
  { label: '0%', value: 0 },
];

function calcAmountWithoutTax(taxPercent: number, totalAmount: number) {
  return Math.ceil(totalAmount / (1 + taxPercent / 100));
}

export function ReceiptDetailHeader(): ReactElement {
  return (
    <DetailRow>
      <RowHeaderLabel>税率</RowHeaderLabel>
      <RowHeaderLabel>税抜き金額</RowHeaderLabel>
      <RowHeaderLabel>税額</RowHeaderLabel>
      <RowHeaderLabel>税込み金額</RowHeaderLabel>
      <RowHeaderLabel>勘定科目</RowHeaderLabel>
      <RowHeaderLabel>取引内容</RowHeaderLabel>
    </DetailRow>
  );
}

type ReceiptDetailRowProps = {
  accountCategories: UserAccountingCategories[];
  inputPayee: string | null;
  predictedAccount: string | null;
  addRow: (index: number) => void;
  detail: ReceiptDetail;
  hiddenRemove: boolean;
  removeRow: (index: number) => void;
  rowId: string;
  rowIndex: number;
  changed: boolean;
  path: NamePath;
};
function ReceiptDetailRow({
  accountCategories,
  addRow,
  changed,
  detail,
  hiddenRemove,
  inputPayee,
  path,
  predictedAccount,
  removeRow,
  rowId,
  rowIndex,
}: ReceiptDetailRowProps): ReactElement {
  const form = Form.useFormInstance();
  const detailRowPath = getPath(path, 'receipt_detail', rowId);

  const accountMap = useMemo(() => {
    return new Map(accountCategories.map((item) => [item.account, item.account_code]));
  }, [accountCategories]) as Map<string, string>;

  const onChangeTaxPercent = useCallback(
    (value: number) => {
      const amountWithTax = form.getFieldValue(getPath(detailRowPath, 'amount_with_tax'));
      if (amountWithTax) {
        // amount_withoud_tax, tax_amount のみ更新する
        const amountWithoutTax = calcAmountWithoutTax(value, amountWithTax);
        const taxAmount = amountWithTax - amountWithoutTax;
        const currentValues = form.getFieldsValue();
        let updatedValues;
        updatedValues = set(currentValues, getPath(detailRowPath, 'amount_without_tax'), amountWithoutTax);
        updatedValues = set(updatedValues, getPath(detailRowPath, 'tax_amount'), taxAmount);
        form.setFieldsValue(updatedValues);
      }
    },
    [form, detailRowPath]
  );
  const setAccountCode = useCallback(
    (account: string) => {
      const currentValues = form.getFieldsValue();
      const updatedValues = set(currentValues, getPath(detailRowPath, 'account_code'), accountMap.get(account));
      form.setFieldsValue(updatedValues);
    },
    [form, detailRowPath, accountMap]
  );

  // inputPayee が変更されたら account を変更する
  useUpdateEffect(() => {
    if (changed && predictedAccount) {
      const currentValues = form.getFieldsValue();
      const updatedValues = set(currentValues, getPath(detailRowPath, 'account'), predictedAccount);
      form.setFieldsValue(updatedValues);
      setAccountCode(predictedAccount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputPayee, predictedAccount]);

  return (
    <>
      <DetailRow>
        <Form.Item initialValue={detail.tax_percent} name={getPath(path, 'receipt_detail', rowId, 'tax_percent')}>
          <Select
            options={taxPercentChoice.map((item) => ({ label: item.label, value: item.value }))}
            onChange={onChangeTaxPercent}
          />
        </Form.Item>
        <Form.Item
          initialValue={detail.amount_without_tax ?? 0}
          name={getPath(path, 'receipt_detail', rowId, 'amount_without_tax')}>
          <InputNumberStyle />
        </Form.Item>
        <Form.Item initialValue={detail.tax_amount ?? 0} name={getPath(path, 'receipt_detail', rowId, 'tax_amount')}>
          <InputNumberStyle />
        </Form.Item>
        <Form.Item
          initialValue={detail.amount_with_tax ?? 0}
          name={getPath(path, 'receipt_detail', rowId, 'amount_with_tax')}>
          <InputNumberStyle />
        </Form.Item>
        <AccountSelectForm
          accountCategories={accountCategories}
          initialValue={detail.account ?? ''}
          path={getPath(path, 'receipt_detail', rowId, 'account')}
          setAccountCode={setAccountCode}
        />
        <Form.Item
          initialValue={detail.transaction_content ?? ''}
          name={getPath(path, 'receipt_detail', rowId, 'transaction_content')}>
          <Input />
        </Form.Item>
        <FluctuationButton add={addRow} hiddenRemove={hiddenRemove} index={rowIndex} remove={removeRow} />
      </DetailRow>
      <Form.Item
        hidden
        initialValue={detail.account_code ?? '-'}
        name={getPath(path, 'receipt_detail', rowId, 'account_code')}>
        <Input />
      </Form.Item>
    </>
  );
}

export default ReceiptDetailRow;
