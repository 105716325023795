import { Form, Input } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { ReactElement, useCallback, useMemo } from 'react';
import { useList } from 'react-use';
import short from 'short-uuid';
import styled from 'styled-components';

import { TransferDestination } from '../../../generated-api';
import getPath from '../../../utils/getPath';
import TransferDestinationRow, { TransferDestinationHeader } from './TransferDestinationRow';

const Label = styled.div`
  margin-bottom: 12px;
`;
const Section = styled.div`
  padding: 8px;
  margin-bottom: 24px;
  border: 1px solid #f0f0f0;
  display: grid;
  grid-gap: 8px;
  align-items: center;
  overflow: auto;
  grid-auto-rows: 40px;
`;

type TransferDestinationFormProps = {
  path: NamePath;
  setChanged: (newValue: boolean) => void;
  transferDestinations: TransferDestination[];
};

function TransferDestinationForm({
  path,
  setChanged,
  transferDestinations,
}: TransferDestinationFormProps): ReactElement {
  const destinations = [...transferDestinations];
  const defaultValue: TransferDestination = useMemo(() => {
    return {
      bank_name: '',
      branchName: '',
      accountType: '',
      accountNumber: '',
      accountHolder: '',
    };
  }, []);

  if (destinations.length === 0) {
    destinations.push(defaultValue);
  }

  const details = useMemo(() => {
    return destinations.reduce<Record<string, TransferDestination>>((accumulator, current) => {
      accumulator[short.generate()] = current;
      return accumulator;
    }, {});
  }, [destinations]);

  const [rowIds, { insertAt: insertRowAt, removeAt: removeRowAt }] = useList(Object.keys(details));

  const addRow = useCallback(
    (index: number) => {
      const rowId = short.generate();
      details[rowId] = defaultValue;
      insertRowAt(index + 1, rowId);
      setChanged(true);
    },
    [defaultValue, details, insertRowAt, setChanged]
  );
  const removeRow = useCallback(
    (index: number) => {
      removeRowAt(index);
      setChanged(true);
    },
    [removeRowAt, setChanged]
  );

  return (
    <>
      <Label>振込口座情報</Label>
      <Section>
        <TransferDestinationHeader />
        <div key={rowIds.length} hidden>
          {rowIds.map((rowId, index) => (
            // 列の並びを維持するために index と rowId の関係を object で保持する
            // FIXME: rowId を value として持つと rowIds が更新されても value が更新されないため name にキーとして持たせる
            // FYI: Form.Item の value を動的に変化させるためには form.setFieldValue を使う必要がある
            <Form.Item
              key={rowId}
              initialValue={rowId}
              name={getPath(path, 'transferDestinationRowIndices', index, rowId)}>
              <Input type="text" />
            </Form.Item>
          ))}
        </div>
        {rowIds.map((id, index) => (
          <TransferDestinationRow
            key={id}
            addRow={addRow}
            hiddenRemove={rowIds.length <= 1}
            path={path}
            removeRow={removeRow}
            rowId={id}
            rowIndex={index}
            transferDestination={details[id] ?? defaultValue}
          />
        ))}
      </Section>
    </>
  );
}

export default TransferDestinationForm;
