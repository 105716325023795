import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import PageLayout from '../../components/Container/PageLayout';
import { meSelector } from '../../store/useMeStore';
import AccountingCategorySetting from './AccountingCategorySetting';
import RetentionPeriodSetting from './RetentionPeriodSetting';
import TrainingPermissionDefaultSetting from './TrainingPermissionDefaultSetting';

const CompanyName = styled.h2`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 24px;
`;

const SettingsPage = () => {
  const { company_name } = useRecoilValue(meSelector);
  return (
    <PageLayout heading="設定">
      <CompanyName>{company_name}</CompanyName>
      <AccountingCategorySetting />
      <RetentionPeriodSetting />
      <TrainingPermissionDefaultSetting />
    </PageLayout>
  );
};

export default SettingsPage;
