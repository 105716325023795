import { Button, Modal } from 'antd';
import { ReactNode, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValueLoadable } from 'recoil';
import styled from 'styled-components';

import { UserRole } from '../../generated-api';
import { meSelectorAsync } from '../../store/useMeStore';
import LoadingDot from '../Loading/LoadingDot';

type Props = {
  children: ReactNode;
  path: string;
  requireAdmin?: boolean;
};
const AdminCheckLink = ({ children, path, requireAdmin }: Props) => {
  const meLoadable = useRecoilValueLoadable(meSelectorAsync);

  const [modalVisible, setModalVisible] = useState(false);

  const show = useCallback(() => {
    setModalVisible(true);
  }, []);

  const close = useCallback(() => {
    setModalVisible(false);
  }, []);

  if (meLoadable.state !== 'hasValue') {
    // me の読み込みは上位のコンポーネントで先行して行っているのでここはたぶん表示されないはず
    return <LoadingDot />;
  }

  const me = meLoadable.contents;

  if (requireAdmin && me?.role !== UserRole.Admin) {
    return (
      <>
        <MockPageLink onClick={show}>{children}</MockPageLink>
        <Modal
          footer={[
            <Button key="close" type="primary" onClick={close}>
              閉じる
            </Button>,
          ]}
          title="お知らせ"
          visible={modalVisible}
          onCancel={close}>
          <p>この画面へのアクセスには、管理者権限が必要です。</p>
        </Modal>
      </>
    );
  } else {
    return <PageLink to={path}>{children}</PageLink>;
  }
};

export default AdminCheckLink;

const MockPageLink = styled.a`
  color: #000;
`;

const PageLink = styled(Link)`
  color: #000;
`;
