import { Table } from 'antd';
import { useCallback } from 'react';

import Badge from '../../components/Badge/Badge';
import { User } from '../../generated-api';
import UserAdministrationButtons from './UserAdministrationButtons';

const { Column } = Table;

type Props = {
  users: User[] | undefined;
  loading: boolean;
  onClickEdit: (userId: string, user: User) => void;
  onClickDelete: (userId: string) => void;
};

const UserTable = ({ loading, onClickDelete, onClickEdit, users }: Props) => {
  const renderButtons = useCallback(
    (user: User) => <UserAdministrationButtons user={user} onClickDelete={onClickDelete} onClickEdit={onClickEdit} />,
    [onClickEdit, onClickDelete]
  );

  return (
    <Table dataSource={users} loading={loading} rowKey="user_id">
      <Column<User> key="name" dataIndex="name" title="名前" />
      <Column<User> key="email" dataIndex="email" title="メールアドレス" />
      <Column<User> dataIndex="role" render={renderRole} title="管理者権限" />
      <Column<User> render={renderButtons} title="" />
    </Table>
  );
};

function renderRole(role: string) {
  if (role == 'admin') {
    return <Badge variant="success">管理者</Badge>;
  } else {
    return <Badge variant="danger">一般ユーザ</Badge>;
  }
}

export default UserTable;
