import { PlusOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import short from 'short-uuid';
import styled from 'styled-components';

import FormItemWithLabel from '../../../components/Form/FormItemWithLabel';
import { DocumentWithholdingSlipEntity } from '../../../generated-api';
import { additionalFormChoice } from './additionalFormChoice';
import { AdditionalFormRow } from './AdditionalFormRow';

interface Props {
  entity: DocumentWithholdingSlipEntity;
}
const AddFormButton = styled.div`
  display: flex;
  margin-bottom: 8px;
  align-items: center;
  gap: 8px;
`;

export default function AdditionalWithholdingSlipForm({ entity }: Props) {
  const [rows, setRows] = useState<string[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const entityKeys = useMemo(() => new Set(Object.keys(entity)), [entity]);
  const initialValues = useMemo(
    () => Object.keys(additionalFormChoice).filter((item) => entityKeys.has(item)),
    [entityKeys]
  );
  const availableOptions = useMemo(
    () =>
      Object.keys(additionalFormChoice).filter(
        (item) => !selectedOptions.includes(item) && !initialValues.includes(item)
      ),
    [initialValues, selectedOptions]
  );

  const addForm = useCallback(() => {
    const rowId = short.generate();
    setRows([...rows, rowId]);
  }, [rows]);

  return (
    <>
      <AddFormButton>
        <div>項目の追加</div>
        <Button
          ghost
          disabled={rows.length + initialValues.length === Object.keys(additionalFormChoice).length}
          icon={<PlusOutlined />}
          shape="circle"
          size="small"
          type="primary"
          onClick={addForm}
        />
      </AddFormButton>
      {initialValues.map((value) => (
        <FormItemWithLabel key={value} label={additionalFormChoice[value]} labelWidth="8rem" name={value}>
          <Input />
        </FormItemWithLabel>
      ))}
      {rows.map((value) => (
        <AdditionalFormRow
          key={value}
          availableOptions={availableOptions}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
        />
      ))}
    </>
  );
}
