import { Form, Input, InputNumber, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import { ReactElement, ReactNode, useCallback, useMemo } from 'react';
import { useList } from 'react-use';
import short from 'short-uuid';
import styled from 'styled-components';

import FormItemWithLabel from '../../../components/Form/FormItemWithLabel';
import { DocumentPaymentRecordEntity, Payment } from '../../../generated-api';
import ocrTypeNameDefinitions from '../../../utils/ocrTypeNameDefinitions';
import { FluctuationButton } from '../FluctuationButton';
import renderTableColumn from './renderTableColumn';
import { CSVConvertResult, OcrResultPageDefinition, ResultPageDocument } from './types';

const PaymentSection = styled.div`
  padding: 8px;
  margin-bottom: 24px;
  border: 1px solid #f0f0f0;
  display: grid;
  grid-gap: 8px;
  align-items: center;
  overflow: auto;
  grid-auto-rows: 40px;
`;

const ReceiptDetailLabel = styled.div`
  margin-bottom: 12px;
`;
const RowHeaderLabel = styled.div`
  font-weight: bold;
  padding-left: 4px;
`;
const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(70px, 1fr) minmax(80px, 1fr)) repeat(3, minmax(100px, 1fr));
  grid-gap: 8px;
  align-items: baseline;
`;
const InputNumberStyle = styled(InputNumber)`
  width: 100%;
  text-align: right;
`;

const defaultDetailValue: Payment = {
  classification: undefined,
  detail: '',
  payment_amount: 0,
  withholding_tax_amount: 0,
};
type PaymentFormProps = {
  entity: DocumentPaymentRecordEntity;
  setChanged: (newValue: boolean) => void;
};
function PaymentForm({ entity, setChanged }: PaymentFormProps): ReactElement {
  const details = useMemo(() => {
    return (
      entity.payments?.reduce<Record<string, Payment>>((accumulator, current) => {
        accumulator[short.generate()] = current;
        return accumulator;
      }, {}) ?? {}
    );
  }, [entity.payments]);

  const [rowIds, { insertAt: insertRowAt, removeAt: removeRowAt }] = useList(Object.keys(details));

  const initialValue = useMemo(() => {
    return {
      classification: '',
      detail: '',
      payment_amount: 0,
      withholding_tax_amount: 0,
    };
  }, []);

  if (rowIds.length === 0) {
    const rowId = short.generate();
    rowIds.push(rowId);
    details[rowId] = initialValue;
  }

  const addRow = useCallback(
    (index: number) => {
      const rowId = short.generate();
      details[rowId] = defaultDetailValue;
      insertRowAt(index + 1, rowId);
      setChanged(true);
    },
    [insertRowAt, setChanged, details]
  );
  const removeRow = useCallback(
    (index: number) => {
      removeRowAt(index);
      setChanged(true);
    },
    [removeRowAt, setChanged]
  );

  return (
    <>
      <ReceiptDetailLabel>明細</ReceiptDetailLabel>
      <PaymentSection>
        <Row>
          <RowHeaderLabel>区分</RowHeaderLabel>
          <RowHeaderLabel>細目</RowHeaderLabel>
          <RowHeaderLabel>支払金額</RowHeaderLabel>
          <RowHeaderLabel>源泉徴収額</RowHeaderLabel>
        </Row>
        <div key={rowIds.length} hidden>
          {rowIds.map((rowId, index) => (
            // 列の並びを維持するために index と rowId の関係を object で保持する
            // FIXME: rowId を value として持つと rowIds が更新されても value が更新されないため name にキーとして持たせる
            // FYI: Form.Item の value を動的に変化させるためには form.setFieldValue を使う必要がある
            <Form.Item key={rowId} initialValue={rowId} name={['rowIndices', index, rowId]}>
              <Input type="text" />
            </Form.Item>
          ))}
        </div>
        {rowIds.map((id, index) => (
          <ReceiptDetailRow
            key={id}
            // accountCategories={accountCategories ?? []}
            addRow={addRow}
            detail={details[id] ?? initialValue}
            hiddenRemove={rowIds.length <= 1}
            removeRow={removeRow}
            rowId={id}
            rowIndex={index}
          />
        ))}
      </PaymentSection>
    </>
  );
}

type PaymentRowProps = {
  // accountCategories: UserAccountingCategories[];
  addRow: (index: number) => void;
  detail: Payment;
  hiddenRemove: boolean;
  removeRow: (index: number) => void;
  rowId: string;
  rowIndex: number;
};
function ReceiptDetailRow({
  // accountCategories,
  addRow,
  detail,
  hiddenRemove,
  removeRow,
  rowId,
  rowIndex,
}: PaymentRowProps): ReactElement {
  return (
    <>
      <Row>
        <Form.Item initialValue={detail.classification ?? 0} name={['payment', rowId, 'classification']}>
          <InputNumberStyle />
        </Form.Item>
        <Form.Item initialValue={detail.detail ?? 0} name={['payment', rowId, 'detail']}>
          <InputNumberStyle />
        </Form.Item>
        <Form.Item
          initialValue={detail.payment_amount ?? 0}
          name={['payment', rowId, 'payment_amount']}
          style={{ textAlign: 'right' }}>
          <InputNumberStyle />
        </Form.Item>
        <Form.Item
          initialValue={detail.withholding_tax_amount ?? 0}
          name={['payment', rowId, 'withholding_tax_amount']}
          style={{ textAlign: 'right' }}>
          <Input />
        </Form.Item>
        <FluctuationButton add={addRow} hiddenRemove={hiddenRemove} index={rowIndex} remove={removeRow} />
      </Row>
    </>
  );
}

type FormProps = {
  entity: DocumentPaymentRecordEntity;
  setChanged: (newValue: boolean) => void;
};

function PaymentRecordFormInput({ entity, setChanged }: FormProps): ReactElement {
  return (
    <>
      <FormItemWithLabel label="支払いを受ける者氏名" labelWidth="10em" name="earner_name">
        <Input defaultValue={entity.earner_name} />
      </FormItemWithLabel>
      <FormItemWithLabel label="支払いを受ける者住所" labelWidth="10em" name="earner_address">
        <Input defaultValue={entity.earner_address} />
      </FormItemWithLabel>
      <FormItemWithLabel
        label="支払いを受ける者事業者番号"
        labelWidth="10em"
        marginBottom="0.5em"
        name="earner_individual_or_corporate_number">
        <Input defaultValue={entity.earner_individual_or_corporate_number} />
      </FormItemWithLabel>
      <FormItemWithLabel label="支払者氏名" labelWidth="10em" name="payer_name">
        <Input defaultValue={entity.payer_name} />
      </FormItemWithLabel>
      <FormItemWithLabel label="支払者住所" labelWidth="10em" name="payer_address">
        <Input defaultValue={entity.payer_address} />
      </FormItemWithLabel>
      <FormItemWithLabel label="支払者事業者番号" labelWidth="10em" name="payer_individual_or_corporate_number">
        <Input defaultValue={entity.payer_individual_or_corporate_number} />
      </FormItemWithLabel>
      <PaymentForm entity={entity} setChanged={setChanged} />
    </>
  );
}

function calcTotalAmount(payments: Payment[] | undefined): number {
  if (payments === undefined) {
    return 0;
  }
  const total_amount: number = payments.reduce((accumulator, payment) => {
    if (typeof payment.payment_amount === 'number') {
      return accumulator + payment.payment_amount;
    }
    if (typeof payment.payment_amount === 'string') {
      const payment_amount = parseInt((payment.payment_amount as string).replace(/,/g, ''));
      return accumulator + payment_amount;
    }
    return accumulator;
  }, 0);

  return total_amount;
}

function renderTableTotalAmountColumn(payments: Payment[]): ReactElement {
  const totalAmount = calcTotalAmount(payments).toLocaleString();
  return <>{totalAmount}</>;
}

function expandPaymentTable(record: ResultPageDocument<DocumentPaymentRecordEntity>): ReactNode {
  if (record.error) {
    return;
  }

  const payments = record.latest_revision.payments;

  const data: Record<string, string | number>[] = payments
    ? payments.map((payment) => {
        return {
          classification: payment.classification ?? '',
          detail: payment.detail ?? '',
          payment_amount: payment.payment_amount ?? 0,
          withholding_tax_amount: payment.withholding_tax_amount ?? 0,
        };
      })
    : [
        {
          classification: '',
          detail: '',
          payment_amount: 0,
          withholding_tax_amount: 0,
        },
      ];

  return (
    <Table dataSource={data} pagination={false}>
      <Column key="classification" dataIndex="classification" title="区分" />
      <Column key="detail" dataIndex="detail" title="細目" />
      <Column key="payment_amount" dataIndex="payment_amount" title="支払い金額" />
      <Column key="withholding_tax_amount" dataIndex="withholding_tax_amount" title="源泉徴収額" />
    </Table>
  );
}

type FormResult = {
  earner_address: string;
  earner_individual_or_corporate_number: string;
  earner_name: string;
  payer_individual_or_corporate_number: string;
  payer_address: string;
  total_amount: number;
  payer_name: string;
  payment: Record<string, Payment>;
  rowIndices: Record<string, never>[]; // rowId と 列の並びを保持する map
};
function formDataToEntity(data: FormResult): DocumentPaymentRecordEntity {
  const rowIds = data.rowIndices.map((row) => Object.keys(row)[0]);
  const payments = rowIds.map((rowId) => data.payment[rowId]);
  const total_amount = calcTotalAmount(payments);

  return {
    earner_address: data.earner_address,
    earner_individual_or_corporate_number: data.earner_individual_or_corporate_number,
    earner_name: data.earner_name,
    payer_individual_or_corporate_number: data.payer_individual_or_corporate_number,
    payer_address: data.payer_address,
    total_amount: total_amount,
    payer_name: data.payer_name,
    payments: payments,
    type: 'payment_record',
  };
}

function convertPaymentRecordCsv(data: ResultPageDocument<DocumentPaymentRecordEntity>[]): CSVConvertResult {
  const csvData = data.flatMap((item) => {
    const record = {
      ファイル名: item.parsed_path.fullFileName,
      支払いを受ける者氏名: item.latest_revision.earner_name ?? '',
      支払いを受ける者住所: item.latest_revision.earner_address ?? '',
      支払いを受ける者事業者番号: item.latest_revision.earner_individual_or_corporate_number ?? '',
      支払者氏名: item.latest_revision.payer_name ?? '',
      支払者住所: item.latest_revision.payer_address ?? '',
      支払者事業者番号: item.latest_revision.payer_individual_or_corporate_number ?? '',
    };
    const defaultDetail = {
      区分: '',
      細目: '',
      支払い金額: '0',
      源泉徴収額: '0',
    };
    const row = item.latest_revision.payments?.map((payment) => {
      return {
        ...record,
        区分: payment.classification ?? '',
        細目: payment.detail ?? '',
        支払い金額: payment.payment_amount?.toString() ?? '',
        源泉徴収額: payment.withholding_tax_amount?.toString() ?? '',
      };
    }) ?? [{ ...record, ...defaultDetail }];
    return row;
  });

  return {
    csv: csvData,
  };
}

const paymentRecordPageDefinition: OcrResultPageDefinition<DocumentPaymentRecordEntity> = {
  name: ocrTypeNameDefinitions.payment_record,
  tabs: [
    {
      csvColumns: {},
      tableColumns: [
        {
          dataIndex: ['latest_revision', 'earner_name'],
          title: '支払いを受ける者氏名',
          render: renderTableColumn,
        },
        {
          dataIndex: ['latest_revision', 'payments'],
          title: '支払い合計金額',
          render: renderTableTotalAmountColumn,
        },
        {
          dataIndex: ['latest_revision', 'payer_name'],
          title: '支払い者',
          render: renderTableColumn,
        },
      ],
      tableExpandable: {
        expandedRowRender: expandPaymentTable,
      },
    },
  ],
  formComponent: PaymentRecordFormInput,
  formDataToEntity,
  csvDataConverters: [
    {
      converter: convertPaymentRecordCsv,
    },
  ],
};

export default paymentRecordPageDefinition;
