import { Spin } from 'antd';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ReactElement, useCallback, useState } from 'react';
import { useAsyncRetry } from 'react-use';
import styled from 'styled-components';

import { documentApi } from '../../api/apiClient';

const TrainingPermissionCheckboxStyle = styled(Checkbox)`
  margin-bottom: 24px;
`;

type TrainingPermissionCheckboxProps = {
  groupId: string;
  documentId: string;
};
function TrainingPermissionCheckbox({ documentId, groupId }: TrainingPermissionCheckboxProps): ReactElement {
  const [loading, setLoading] = useState(false);
  const {
    loading: initialLoading,
    retry,
    value: enabled,
  } = useAsyncRetry(async () => {
    const { data } = await documentApi.documentControllerHasTrainingDocumentPermission(groupId, documentId);
    return data;
  });
  const onChange = useCallback(
    async (e: CheckboxChangeEvent) => {
      setLoading(true);
      if (e.target.checked) {
        await documentApi.documentControllerEnableTrainingUse(groupId, documentId);
      } else {
        await documentApi.documentControllerDisableTrainingUse(groupId, documentId);
      }
      retry();
      setLoading(false);
    },
    [documentId, groupId, retry]
  );
  return (
    <TrainingPermissionCheckboxStyle checked={enabled} disabled={loading || initialLoading} onChange={onChange}>
      この読み取りデータを今後の精度向上のために学習への使用を許可する
      <Spin size="small" spinning={loading || initialLoading} />
    </TrainingPermissionCheckboxStyle>
  );
}

export default TrainingPermissionCheckbox;
