import { CSVData, DocumentTableEntity, ResultPageTableDocument } from '../page/OcrResult/defs/types';
import { Paths } from '../types/Paths';
import getPropertyOf from './getPropertyOf';

export default function convertCSVColumns<T>(data: T[], columns: Record<string, Paths<T>>): CSVData {
  const paths = Object.values(columns);
  const headers = Object.keys(columns);

  const records = data.map((record) =>
    paths.reduce<Record<string, string>>((acc, curr, index) => {
      const property = getPropertyOf(record, curr);
      if (property == null) {
        return acc;
      }
      return {
        ...acc,
        [headers[index]]: String(property),
      };
    }, {})
  );

  return records;
}

export function convertVariableTableFormatToCsv(data: ResultPageTableDocument[]): CSVData {
  const safeRecords = data.filter(
    (
      record
    ): record is ResultPageTableDocument & {
      latest_revision: DocumentTableEntity & { rows: string[][]; column_names: string[] };
    } => record.latest_revision?.rows != null && record.latest_revision.column_names != null
  );

  const headers = Array.from(new Set(safeRecords.flatMap((record) => record.latest_revision.column_names)));

  const result = safeRecords.flatMap((record) => {
    const notExistsHeaders = headers.filter(
      (header) => record.latest_revision.column_names.findIndex((recordHeader) => header === recordHeader) === -1
    );

    return record.latest_revision.rows.map((row) =>
      row.reduce<CSVData[number]>(
        (acc, value, index) => ({
          ...acc,
          [record.latest_revision.column_names[index]]: value,
        }),
        {
          ファイル名: record.parsed_path.fullFileName,
          ページ数: record.page?.toString() ?? '-',
          // 歯抜けになっている列は空白文字にする
          ...notExistsHeaders.reduce((acc, curr) => ({ ...acc, [curr]: '' }), {}),
        }
      )
    );
  });

  return result;
}
