import { noWait, selector, useRecoilCallback } from 'recoil';

import { usersApi } from '../api/apiClient';
import { GetMeResponse } from '../generated-api';

export const meSelectorAsync = selector<GetMeResponse>({
  key: 'MeStore/meSelectorAsync',
  get: async () => {
    try {
      const { data } = await usersApi.userControllerGetMe();
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
});

export const meSelector = selector<GetMeResponse>({
  key: 'MeStore/meSelector',
  get: ({ get }) => {
    // meSelectorAsync の初期化が終了しているものとみなして同期で取得する
    const state = get(noWait(meSelectorAsync));
    if (state.state !== 'hasValue') {
      throw new Error('no value');
    }
    return state.contents;
  },
});

export const useFetchMe = (): (() => Promise<void>) => {
  return useRecoilCallback(
    ({ refresh }) =>
      async () => {
        refresh(meSelectorAsync);
      },
    []
  );
};
