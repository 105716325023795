import { Spin } from 'antd';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useRecoilValueLoadable } from 'recoil';
import styled from 'styled-components';

import { useAuthStore } from '../../store/useAuthStore';
import { meSelectorAsync } from '../../store/useMeStore';
import { notifyApplicationError } from '../../utils/notification';

const AuthenticatedRoute = (props: RouteProps) => {
  const { isAuthorized } = useAuthStore();
  const loadable = useRecoilValueLoadable(meSelectorAsync);

  if (isAuthorized) {
    if (loadable.state === 'loading') {
      return (
        <Container>
          <Spin />
        </Container>
      );
    }

    if (loadable.state === 'hasError') {
      notifyApplicationError();
      const path = makeBeforePathQuery();
      return <Redirect to={`/login?expired=1&r=${path}`} />;
    }

    const { agreement, password_change_required } = loadable.contents;

    if (!agreement) {
      return <Redirect to="/terms" />;
    }

    return password_change_required ? <Redirect to="/password/change" /> : <Route {...props} />;
  } else {
    const path = makeBeforePathQuery();
    return <Redirect to={`/login?expired=1&r=${path}`} />;
  }
};

export default AuthenticatedRoute;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function makeBeforePathQuery(): string {
  // ログイン後に元のページにリダイレクト出来るようにする(ref: src/page/Login/LoginPage.tsx)
  if (location.pathname === '/') {
    return '';
  }

  return encodeURIComponent(location.pathname);
}
