import { Modal } from 'antd';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { companiesApi, usersApi } from '../../api/apiClient';
import PageLayout from '../../components/Container/PageLayout';
import { CreateUserRequest } from '../../generated-api';
import { notifyError, notifySuccess } from '../../utils/notification';
import UserEditForm, { UserEditInputs } from './UserEditForm';

const UserCreatePage = () => {
  const history = useHistory();

  const createUser = useCallback(
    (input: UserEditInputs) => {
      Modal.confirm({
        title: `ユーザ<${input.name}>を作成しますか？`,
        content: <Content email={input.email} />,
        okText: 'はい',
        cancelText: 'いいえ',
        width: '720px',
        onOk: async () => {
          try {
            const { data: company } = await companiesApi.companyControllerGetCompany();
            const request: CreateUserRequest = {
              ...input,
              company_id: company.company_id,
              company_name: company.company_name,
            };
            const { data: user } = await usersApi.userControllerCreateUser(request);
            notifySuccess('ユーザを新規登録しました。');
            Modal.info({
              title: `作成したユーザのパスワードは以下になります。`,
              content: `パスワード: ${user.password}`,
              width: '720px',
            });
            history.push('/users');
          } catch (error) {
            notifyError('エラーが発生しました。');
          }
        },
      });
    },
    [history]
  );

  return (
    <PageLayout heading="組織ユーザ新規登録">
      <UserEditForm onSave={createUser} />
    </PageLayout>
  );
};

interface ContentProps {
  email: string;
}

function Content({ email }: ContentProps) {
  return (
    <>
      <p>以下のメールアドレスへログイン情報をお送りいたします。</p>
      <p>メールアドレス: {email}</p>
    </>
  );
}

export default UserCreatePage;
