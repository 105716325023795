import { message as notifier } from 'antd';

export const notifySuccess = (message: string): void => {
  notifier.success(message);
};

export const notifyWarning = (message: string): void => {
  notifier.warning(message);
};

export const notifyError = (message: string): void => {
  notifier.error(message);
};

export const notifyApplicationError = (): void => {
  notifier.error(
    'アプリケーションの初期化に失敗しました。ウェブサイトに問題が発生している可能性があります。時間を置いてからもう一度お試しください。'
  );
};
