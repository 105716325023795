import { Card, Table, Tabs } from 'antd';
import Column from 'antd/lib/table/Column';
import { useMemo, useState } from 'react';

import { DocumentGeneralTableEntity } from '../../../generated-api';
import ocrTypeNameDefinitions from '../../../utils/ocrTypeNameDefinitions';
import { VariableLengthInputForm, VariableLengthInputFormResult } from '../VariableLengthInputForm';
import { CSVData, EditModalEntity, FormComponentProps, OcrResultPageDefinition, ResultPageDocument } from './types';

const { TabPane } = Tabs;

type ChildTableProps = {
  table: string[][];
};

type GeneralTableFormEntity = {
  tables: string[][][];
};

function ChildTable({ table }: ChildTableProps) {
  const { header, values } = useMemo(() => {
    const [header, ...values] = table;
    return {
      header,
      // antd が rowKey を振れるように変換する
      values: values.map((value, index) => ({
        index,
        row: value.reduce<Record<string, string>>(
          (acc, v, index) => ({
            ...acc,
            [index]: v,
          }),
          {}
        ),
      })),
    };
  }, [table]);

  return (
    <Card>
      <Table dataSource={values} pagination={false} rowKey="index">
        {header.map((col, index) => (
          <Column<typeof values[0]>
            key={`col-${index}`}
            dataIndex={['row', index]}
            render={(value) => `${value}`}
            title={col}
          />
        ))}
      </Table>
    </Card>
  );
}

function GeneralTableDataFormComponent({ entity, setChanged }: FormComponentProps<GeneralTableFormEntity>) {
  const [tab, setTab] = useState('0');
  const formEntities = useMemo(
    () =>
      entity.tables.map(([cols, ...table]) => ({
        column_names: cols,
        rows: table,
      })),
    [entity.tables]
  );

  return (
    <>
      <Tabs defaultActiveKey={tab} onChange={setTab}>
        {formEntities.map((_form, index) => (
          <TabPane key={index} tab={`表${index + 1}`} tabKey={index.toString()} />
        ))}
      </Tabs>
      {/* TabPane 内でフォームを使うと、実際に表示されるまで値として反映されないので自力でタブの表示を制御する */}
      {formEntities.map((form, index) => (
        <div key={index} style={{ display: tab === index.toString() ? 'block' : 'none' }}>
          <VariableLengthInputForm entity={form} path={['resultTables', index]} setChanged={setChanged} />
        </div>
      ))}
    </>
  );
}

function entityToModalEntities(entities: ResultPageDocument<DocumentGeneralTableEntity>[]) {
  return entities.map<EditModalEntity<GeneralTableFormEntity>>(({ latest_revision: { tables }, ...record }) => ({
    documentId: record.document_id,
    groupId: record.group_id,
    pageNumber: record.page ?? 0,
    fileName: record.parsed_path.fullFileName,
    entity: {
      tables,
    },
  }));
}

type FormResult = {
  resultTables: Record<number, VariableLengthInputFormResult<DocumentGeneralTableEntity>>;
};

function formDataToEntity(data: FormResult): DocumentGeneralTableEntity {
  const tables = Object.values(data.resultTables);
  const newTables = tables.map(({ columns, table }) => [
    [...Object.values(columns)],
    ...Object.values(table).map((values) => Object.values(values)),
  ]);

  return {
    type: 'general_table',
    tables: newTables,
    // row_id, column_id は保存しなくてもよさそう
  };
}

const generalTableResultPageDefinition: OcrResultPageDefinition<DocumentGeneralTableEntity> = {
  name: ocrTypeNameDefinitions.general_table,
  tabs: [
    {
      csvColumns: {},
      tableColumns: [
        {
          title: '表の数',
          dataIndex: ['latest_revision', 'tables'],
          render: (value: string[][][]) => value.length,
        },
      ],
      tableExpandable: {
        expandedRowRender: ({ latest_revision: record }) =>
          record.tables.map((table, tableIndex) => <ChildTable key={tableIndex} table={table} />),
      },
    },
  ],
  formComponent: GeneralTableDataFormComponent,
  entityToModalEntities,
  formDataToEntity,
  csvDataConverters: [
    {
      labelName: '',
      converter: (data: ResultPageDocument<DocumentGeneralTableEntity>[]) => {
        return data
          .filter((entity) => entity.latest_revision.tables != null)
          .flatMap((entity, index) =>
            entity.latest_revision.tables.map((csv, tableIndex) => ({
              fileNameSuffix: `${entity.page ? `${entity.page}_${index}` : index}_${tableIndex}`,
              csv: convertGeneralTableToCSV(csv),
            }))
          );
      },
    },
  ],
  canOutputAsSingleCsvFile: true,
};

function convertGeneralTableToCSV(table: string[][]): CSVData {
  const headers = table[0];

  return table
    .slice(1)
    .map((value) =>
      value.reduce<Record<string, string>>((acc, curr, index) => ({ ...acc, [headers[index]]: curr }), {})
    );
}

export default generalTableResultPageDefinition;
