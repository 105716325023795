import { Button, Card, DatePicker, Form, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Input from 'antd/lib/input/Input';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useAsync } from 'react-use';

import { companiesApi } from '../../api/apiClient';
import { documentTypeChoice } from '../../components/Form/DocumentTypeSelectForm';
import datePickerLocale from '../../utils/datePickerLocale';
import { OcrResultSearchParam } from './useSearchQuery';

interface Props {
  defaultValue: OcrResultSearchParam;
  onSubmit: (values: OcrResultSearchParam) => void;
}

const SearchForm = ({ defaultValue, onSubmit }: Props) => {
  const [search] = useForm();
  const { loading: loadingUsers, value: users } = useAsync(async () => {
    const { data } = await companiesApi.companyControllerGetUserList();
    search.setFieldsValue({ user: defaultValue.user });
    return data;
  });

  const onSearch = useCallback(() => {
    const documentType = search.getFieldValue('ocrType');
    const userId = search.getFieldValue('user');
    const date = search.getFieldValue('date');
    const fileName = search.getFieldValue('fileName');

    /*
      DBに登録されているレコードのuploaded_at(YYYY-MM-DDTHH:mm:ss.SSSZ)がUTCのため、
      JSTが 2022-06-17T00:00:00.000 ~ 2022-06-17T23:59:59.999 の場合、
      UTCは 2022-06-16T15:00:00.000 ~ 2022-06-17T14:59:59.999 となる
    */
    const since =
      date && date[0] ? date[0].subtract(1, 'd').format('YYYY-MM-DD').toString() + 'T15:00:00.000' : undefined;

    const until = date && date[1] ? date[1].format('YYYY-MM-DD').toString() + 'T14:59:59.999' : undefined;
    onSubmit({ type: documentType, user: userId, since, until, fileName });
  }, [search, onSubmit]);

  const onReset = useCallback(() => {
    search.setFieldsValue({ ocrType: undefined, user: undefined, date: undefined, fileName: undefined });
    search.submit();
  }, [search]);

  const formWidth = 320;
  return (
    <Card>
      <Form
        form={search}
        initialValues={{
          user: undefined, // ユーザー一覧が読み込み終わったあとに setFieldsValue するので undefined で初期化
          ocrType: defaultValue.type,
          date: [
            defaultValue.since ? dayjs(defaultValue.since) : undefined,
            defaultValue.until ? dayjs(defaultValue.until) : undefined,
          ],
        }}
        labelAlign="left"
        labelCol={{ span: 6 }}
        labelWrap={true}
        wrapperCol={{ span: 18 }}
        onFieldsChange={onSearch}
        onFinish={onSearch}>
        <Form.Item label="帳票の選択" name="ocrType">
          <Select
            allowClear
            placeholder="選択してください"
            style={{ width: formWidth }}
            listHeight={40 * documentTypeChoice.length}>
            {documentTypeChoice.map((type) => (
              <Select.Option key={type.value} value={type.value}>
                {type.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="ユーザの選択" name="user">
          <Select
            allowClear
            loading={loadingUsers}
            placeholder={loadingUsers ? 'ユーザ一覧を読み込んでいます...' : '選択してください'}
            style={{ width: formWidth }}>
            {users?.map((user) => (
              <Select.Option key={user.user_id} value={user.user_id}>
                {user.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="実施日" name="date">
          <DatePicker.RangePicker locale={datePickerLocale} style={{ width: formWidth }} />
        </Form.Item>
        <Form.Item label="ファイル名" name="fileName">
          <Input style={{ width: formWidth }}></Input>
        </Form.Item>
        <Form.Item>
          <Button onClick={onReset}>検索条件のリセット</Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default SearchForm;
