import { RcFile } from 'antd/lib/upload/interface';
import axios from 'axios';

import { DocumentType, GetPresignedUrlRequest } from '../generated-api/api';
import { documentApi } from './apiClient';

export async function fileUpload(
  uploadFiles: RcFile[],
  documentType: DocumentType,
  combinable: boolean
): Promise<string> {
  const request: GetPresignedUrlRequest = {
    file_name_list: uploadFiles.map((file) => (file.webkitRelativePath ? file.webkitRelativePath : file.name)),
    type: documentType,
  };
  const {
    data: { files, group_id },
  } = combinable
    ? await documentApi.documentControllerGetCombineSourceUploadPresignedUrl(request)
    : await documentApi.documentControllerGetPdfUploadPresignedUrl(request);

  const upload = async (url: string, file: RcFile) => {
    await axios({
      method: 'PUT',
      url,
      headers: {
        'Content-Type': file.type,
      },
      data: await file.arrayBuffer(),
    });
  };

  await Promise.all(files.map((url, index) => upload(url.upload_url, uploadFiles[index])));

  return group_id;
}
