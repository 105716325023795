import baseLocale from 'antd/es/date-picker/locale/ja_JP';

const locale = {
  ...baseLocale,
  lang: {
    ...baseLocale.lang,
    monthFormat: 'MM月',
  },
};
export default locale;
