import styled from 'styled-components';

import { useAuthStore } from '../../store/useAuthStore';
import { mediaQuery } from '../../utils/mediaQuery';

const ExternalLink = styled.a`
  color: #000;
  text-decoration: underline;
`;

const StyledExternalLink = styled(ExternalLink)`
  @media (${mediaQuery.sp}) {
    margin: 5px 0;
  }
`;

const information = [
  { id: 'OperatingCompany', path: 'https://rpa-solutions.co.jp/company/', title: '運営会社', newTab: true },
  { id: 'UsageRules', path: '/20240718_Ez-AiOCR利用約款.pdf', title: '利用約款', newTab: true },
  {
    id: 'PrivacyPolicy',
    path: 'https://rpa-solutions.co.jp/privacy-policy/',
    title: 'プライバシーポリシー',
    newTab: true,
  },
  {
    id: 'Inquiry',
    path: 'mailto:ocr-support@rpa-solutions.co.jp?subject=%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B',
    title: 'お問い合わせ',
    newTab: false,
    loginRequired: true,
  },
];

const ExternalLinks = () => {
  const { isAuthorized } = useAuthStore();

  return (
    <>
      {information.map((item) => {
        if (item.loginRequired && !isAuthorized) {
          return null;
        }
        return (
          <StyledExternalLink key={item.id} href={item.path} target={item.newTab ? '_blank' : undefined}>
            {item.title}
          </StyledExternalLink>
        );
      })}
    </>
  );
};

export default ExternalLinks;
