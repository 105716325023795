import { Button, Modal, Space, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import { ExpandableConfig } from 'antd/lib/table/interface';
import { PropsWithChildren } from 'react';

import { FailedIcon, SuccessIcon } from '../../../components/Icons/StatusIcon';
import { DocumentEntities, ResultPageDocument } from '../defs/types';
import FileNameColumn from './FileNameColumn';

type TableRecord = ResultPageDocument<DocumentEntities>;

type Props = PropsWithChildren<{
  onConfirmDelete?: (documentId: string) => Promise<void>;
  onClickEdit?: (documentId: string) => void;
  results: TableRecord[];
  expandable: ExpandableConfig<TableRecord>;
  loading?: boolean;
}>;

export default function ResultTable({ children, expandable, loading, onClickEdit, onConfirmDelete, results }: Props) {
  return (
    <Table
      dataSource={results}
      expandable={{ rowExpandable: (record) => record.error == null, ...expandable }}
      loading={loading}
      pagination={{ defaultPageSize: 20, showSizeChanger: true, position: ['topRight', 'bottomRight'] }}
      rowKey="document_id">
      <Column<TableRecord>
        render={(_, record) => (!record.error && record.fully_read ? <SuccessIcon /> : <FailedIcon />)}
        title="ステータス"
      />
      <Column<TableRecord> dataIndex="seen" render={(seen: boolean) => seen && <SuccessIcon />} title="確認済み" />

      <Column<TableRecord>
        dataIndex="edited"
        render={(edited: boolean) => edited && <SuccessIcon />}
        title="編集済み"
      />

      <Column<TableRecord> render={(_, record) => <FileNameColumn record={record} />} title="ファイル名" />
      <Column<TableRecord> dataIndex="page" render={(page: number | null) => page ?? '-'} title="ページ数" />

      {children}

      <Column<TableRecord>
        dataIndex="document_id"
        render={(documentId: string) => (
          <EditColumn documentId={documentId} onClickEdit={onClickEdit} onConfirmDelete={onConfirmDelete} />
        )}
      />
    </Table>
  );
}

type EditColumnProps = {
  documentId: string;
  onConfirmDelete?: (documentId: string) => Promise<void>;
  onClickEdit?: (documentId: string) => void;
};

function EditColumn({ documentId, onClickEdit, onConfirmDelete: onConfirmDeleteHandler }: EditColumnProps) {
  const onClickDelete = () => {
    Modal.confirm({
      title: '読み取り結果を削除しますか?',
      okText: 'はい',
      cancelText: 'いいえ',
      onOk: async () => {
        await onConfirmDeleteHandler?.(documentId);
      },
    });
  };

  return (
    <Space>
      <Button type="primary" onClick={() => onClickEdit?.(documentId)}>
        確認･編集
      </Button>
      <Button danger onClick={onClickDelete}>
        削除
      </Button>
    </Space>
  );
}
