import { Button } from 'antd';
import { ReactElement, useCallback } from 'react';
import styled from 'styled-components';

import { EditModalEntity } from '../defs/types';

interface Props {
  entity: EditModalEntity;
  hasNext: boolean;
  hasPrev: boolean;
  onClickNext: () => void;
  onClickPrev: () => void;
  confirm: (action: Action) => void;
}
export type Action = '次へ' | '前へ' | '閉じる';

export default function Navigator(props: Props): ReactElement {
  const { confirm, entity, hasNext, hasPrev, onClickNext, onClickPrev } = props;
  const toNext = useCallback(async () => {
    if (hasNext && (await confirm('次へ'))) {
      onClickNext();
    }
  }, [confirm, hasNext, onClickNext]);
  const toPrev = useCallback(async () => {
    if (hasPrev && (await confirm('前へ'))) {
      onClickPrev();
    }
  }, [confirm, hasPrev, onClickPrev]);
  return (
    <NavigateButtons>
      <div>
        <Button disabled={!hasPrev} style={{ marginRight: `10px` }} onClick={toPrev}>
          ＜ 前へ
        </Button>
        <Button disabled={!hasNext} onClick={toNext}>
          次へ ＞
        </Button>
      </div>
      <FileLabel>ファイル名: {entity.fileName}</FileLabel>
      <FileLabel>ページ数: {entity.pageNumber}</FileLabel>
    </NavigateButtons>
  );
}

const NavigateButtons = styled.div`
  margin: 4px 0 8px;
  display: flex;
  flex: 0 0;
  justify-content: space-between;
`;

const FileLabel = styled.div`
  padding: 4px;
`;
