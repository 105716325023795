import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import styled from 'styled-components';

import { DocumentType } from '../../generated-api';

const Description = styled.p`
  color: rgba(0, 0, 0, 0.45);
`;

export const allowCombineTypes = new Set(['receipt']);

interface Props {
  documentType: DocumentType | null;
  combinable: boolean;
  setCombinable: (combinable: boolean) => void;
}
export default function CombineOption({ combinable, documentType, setCombinable }: Props) {
  const onChange = (e: CheckboxChangeEvent) => {
    setCombinable(e.target.checked);
  };
  if (documentType && allowCombineTypes.has(documentType)) {
    return (
      <>
        <Checkbox checked={combinable} onChange={onChange}>
          画像合成
        </Checkbox>
        <Description>画像1枚を2つ横並びで合成して画像2枚を1ページとして帳票を読み取ります。</Description>
      </>
    );
  } else {
    return <></>;
  }
}
