import { Form, Input } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { ReactElement, useCallback, useMemo } from 'react';
import { useAsync, useList } from 'react-use';
import short from 'short-uuid';
import styled from 'styled-components';

import { accountingCategoriesApi } from '../../../api/apiClient';
import { DocumentInvoiceEntity, InvoiceDetail } from '../../../generated-api';
import getPath from '../../../utils/getPath';
import { buildInvoiceDetail } from '../defs/invoiceResultPageDefinition';
import InvoiceDetailRow, { InvoiceDetailHeader } from './InvoiceDetailRow';

const InvoiceDetailLabel = styled.div`
  margin-bottom: 12px;
`;
const InvoiceDetailSection = styled.div`
  padding: 8px;
  margin-bottom: 24px;
  border: 1px solid #f0f0f0;
  display: grid;
  grid-gap: 8px;
  align-items: center;
  overflow: auto;
  grid-auto-rows: 40px;
`;
const InvoiceDetailCalcDescription = styled.div`
  font-size: 12px;
  color: #ff4d4f;
  white-space: nowrap;
`;

type InvoiceDetailFormProps = {
  entity: DocumentInvoiceEntity;
  setChanged: (newValue: boolean) => void;
  path: NamePath;
};

function InvoiceDetailForm({ entity, path, setChanged }: InvoiceDetailFormProps): ReactElement {
  const { value: accountCategories } = useAsync(async () => {
    const { data } = await accountingCategoriesApi.accountingCategoriesControllerGetAccountingCategories();
    return data.filter((item) => item.in_used);
  }, []);
  const details = useMemo(() => {
    const initialDetail = buildInvoiceDetail(entity);
    if (entity.invoice_details) {
      return entity.invoice_details.reduce<Record<string, InvoiceDetail>>((accumulator, current) => {
        accumulator[short.generate()] = current;
        return accumulator;
      }, {});
    } else {
      return { [short.generate()]: initialDetail };
    }
  }, [entity]);

  const defaultValue: InvoiceDetail = useMemo(() => {
    return {
      tax_percent: 10,
      amount_without_tax: 0,
      tax_amount: 0,
      amount_with_tax: 0,
      account: '',
    };
  }, []);

  const [rowIds, { insertAt: insertRowAt, removeAt: removeRowAt }] = useList(Object.keys(details));

  const addRow = useCallback(
    (index: number) => {
      const rowId = short.generate();
      details[rowId] = defaultValue;
      insertRowAt(index + 1, rowId);
      setChanged(true);
    },
    [defaultValue, details, insertRowAt, setChanged]
  );
  const removeRow = useCallback(
    (index: number) => {
      removeRowAt(index);
      setChanged(true);
    },
    [removeRowAt, setChanged]
  );

  return (
    <>
      <InvoiceDetailLabel>明細</InvoiceDetailLabel>
      <InvoiceDetailSection>
        <InvoiceDetailHeader />
        <div key={rowIds.length} hidden>
          {rowIds.map((rowId, index) => (
            // 列の並びを維持するために index と rowId の関係を object で保持する
            // FIXME: rowId を value として持つと rowIds が更新されても value が更新されないため name にキーとして持たせる
            // FYI: Form.Item の value を動的に変化させるためには form.setFieldValue を使う必要がある
            <Form.Item key={rowId} initialValue={rowId} name={getPath(path, 'invoiceDetailRowIndices', index, rowId)}>
              <Input type="text" />
            </Form.Item>
          ))}
        </div>
        {rowIds.map((id, index) => (
          <InvoiceDetailRow
            key={id}
            accountCategories={accountCategories ?? []}
            addRow={addRow}
            detail={details[id] ?? defaultValue}
            hiddenRemove={rowIds.length <= 1}
            path={path}
            removeRow={removeRow}
            rowId={id}
            rowIndex={index}
          />
        ))}
        <InvoiceDetailCalcDescription>
          「税込み金額」を入力して「税率」を変更すると、 自動的に「税抜き金額」と「税額」が計算されます。
        </InvoiceDetailCalcDescription>
      </InvoiceDetailSection>
    </>
  );
}

export default InvoiceDetailForm;
