import { FileExcelOutlined } from '@ant-design/icons';
import { Button, Radio, RadioChangeEvent } from 'antd';
import { format } from 'date-fns';
import JSZip from 'jszip';
import { ReactElement, useState } from 'react';
import styled from 'styled-components';

import parseFilePath from '../../components/Lib/parseFilePath';
import { Document, DocumentType } from '../../generated-api';
import useOcrPageDefinition from '../../hooks/useOcrPageDefinition';
import useDocumentListStore from '../../store/useDocumentListStore';
import convertCSVColumns from '../../utils/convertCSVColumns';
import createCSVData from '../../utils/createCSVData';
import { createTextBlobWithBOM, downloadBlob, downloadCSV } from '../../utils/downloadData';
import ocrResultPageDefinitions from './defs';
import { CSVConvertResult, CSVData, DocumentEntities, ResultPageDocument } from './defs/types';

interface Props {
  type: DocumentType;
  groupId: string;
}

function createCSVFileName(type: DocumentType, result: Document, suffix = ocrResultPageDefinitions[type].name) {
  const { fileName, topLevelDirName: dirName } = parseFilePath(result.user_file_path);
  const today = format(Date.now(), 'yyyyMMdd');
  if (dirName) {
    return `${today}_${dirName}_${suffix}.csv`;
  } else {
    return `${today}_${fileName}_${suffix}.csv`;
  }
}

function createMergedCSVFileName(type: DocumentType, suffix = ocrResultPageDefinitions[type].name) {
  const today = format(Date.now(), 'yyyyMMdd');
  return `${today}_${suffix}.csv`;
}

function createZipFileName(type: DocumentType, suffix = ocrResultPageDefinitions[type].name) {
  const today = format(Date.now(), 'yyyyMMdd');
  return `${today}_${suffix}.zip`;
}

function convertData(
  values: ResultPageDocument<DocumentEntities>[],
  type: DocumentEntities['type'],
  converterIndex: number
): CSVConvertResult | CSVConvertResult[] {
  const converterItem = ocrResultPageDefinitions[type].csvDataConverters?.[converterIndex];
  if (converterItem?.converter == null && ocrResultPageDefinitions[type].tabs[converterIndex] == null) {
    throw new Error(`Error occurred in convertData().
Did not defined tabs[${converterIndex}] in ocrResultPageDefinitions[${type}].
Or, pleaseAdd the ${converterIndex} converter to csvConverters.`);
  }
  return (
    converterItem?.converter?.(values) ?? {
      csv: convertCSVColumns(values, ocrResultPageDefinitions[type].tabs[converterIndex].csvColumns ?? {}),
    }
  );
}

export const DownloadCsvButton = ({ groupId, type }: Props): ReactElement => {
  const { ocrResults: results } = useDocumentListStore(groupId);
  const { csvDataConverters } = useOcrPageDefinition(type);
  const [converterIndex, setConverterIndex] = useState(0);
  const [outputFormat, setOutputFormat] = useState<'singleDocument' | 'eachDocument'>('eachDocument');

  const onChangeOutputFormat = (e: RadioChangeEvent) => {
    setOutputFormat(e.target.value as 'singleDocument' | 'eachDocument');
  };

  const canOutputAsSingleFile: boolean =
    results?.[0] && results.length >= 2 && ocrResultPageDefinitions[type].canOutputAsSingleCsvFile ? true : false;

  const saveCSVHandler = () => {
    if (results?.[0]) {
      const convertedData = convertData(results, type, converterIndex);
      if (!Array.isArray(convertedData)) {
        // 単一ファイルのダウンロード
        const csv = createCSVData(convertedData.csv);
        const fileName = createCSVFileName(type, results[0], convertedData.fileNameSuffix);
        downloadCSV(fileName, csv);
      } else {
        // 複数ファイルのダウンロード
        if (outputFormat == 'singleDocument') {
          // 複数ファイルのCSVDataをマージして一つのファイルをダウンロード
          const mergedCSV: CSVData = convertedData.reduce((result, r) => result.concat(r.csv), [] as CSVData);
          const resultCsv = createCSVData(mergedCSV);
          const fileName = createMergedCSVFileName(type);

          downloadCSV(fileName, resultCsv);
          return;
        }
        if (convertedData.length < 5) {
          // 4個以下は普通に個別ダウンロード
          for (const { csv, fileNameSuffix } of convertedData) {
            const fileName = createCSVFileName(type, results[0], fileNameSuffix);
            const resultCsv = createCSVData(csv);

            downloadCSV(fileName, resultCsv);
          }
        } else {
          // 5個以上なら zip 化
          const zip = new JSZip();
          for (const { csv, fileNameSuffix } of convertedData) {
            const fileName = createCSVFileName(type, results[0], fileNameSuffix);
            const resultCsv = createCSVData(csv);
            const data = createTextBlobWithBOM(resultCsv);

            zip.file(fileName, data);
          }
          zip.generateAsync({ type: 'blob' }).then((content) => {
            downloadBlob(createZipFileName(results[0].ocr_type), content);
          });
        }
      }
    }
  };

  return (
    <Wrap>
      {csvDataConverters && csvDataConverters.length >= 2 && (
        <CsvSelectorWrap>
          出力データ
          <Radio.Group value={converterIndex} onChange={(e) => setConverterIndex(e.target.value)}>
            {csvDataConverters.map((item, index) => (
              <Radio key={index} value={index}>
                {item.labelName}
              </Radio>
            ))}
          </Radio.Group>
        </CsvSelectorWrap>
      )}
      <Button icon={<FileExcelOutlined />} type="primary" onClick={saveCSVHandler}>
        CSV出力
      </Button>
      {results && canOutputAsSingleFile && (
        <Radio.Group value={outputFormat} onChange={onChangeOutputFormat}>
          <Radio value="singleDocument">1つのファイルにまとめて出力</Radio>
          <Radio value="eachDocument">別々のファイルに出力</Radio>
        </Radio.Group>
      )}
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 20px 0;
`;

const CsvSelectorWrap = styled.div`
  display: flex;
  gap: 25px;
  user-select: none;
`;
