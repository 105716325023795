import { FilterFilled as AntFilterFilled, FilterOutlined } from '@ant-design/icons';
import styled from 'styled-components';

type Props = {
  enabled?: boolean;
};

export default function FilterIcon({ enabled }: Props) {
  return enabled ? <FilterFilled color="primary" /> : <FilterOutlined />;
}

const FilterFilled = styled(AntFilterFilled)`
  svg {
    fill: #1890ff;
  }
`;
