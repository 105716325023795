import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Tooltip } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { ChangeEvent, MouseEvent, ReactElement, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { UserAccountingCategories } from '../../generated-api';
import { notifyError } from '../../utils/notification';

const AddAccountItemForm = styled.div`
  margin: 8px 0 0;
  padding: 8px 8px;
  border-top: 1px solid #f0f0f0;
`;
const AddAccountItemButton = styled(Button)`
  padding: 0;
`;

type Props = {
  accountCategories: UserAccountingCategories[];
  initialValue: string;
  path: NamePath;
  setAccountCode: (account: string) => void;
};
function AccountSelectForm({ accountCategories, initialValue, path, setAccountCode }: Props): ReactElement {
  const [addedAccounts, setAddedAccounts] = useState<string[]>([]);
  const [newAccountName, setNewAccountName] = useState('');

  const initialAccounts = useMemo(() => {
    return accountCategories.map((item) => item.account);
  }, [accountCategories]);

  const onChangeNewAccountName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setNewAccountName(e.target.value);
  }, []);

  const addAccountItem = useCallback(
    (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
      e.preventDefault();
      if (newAccountName === '') {
        notifyError('勘定科目名を入力してください。');
        return;
      }
      setAddedAccounts([...addedAccounts, newAccountName]);
      setNewAccountName('');
    },
    [addedAccounts, newAccountName]
  );

  return (
    <Form.Item initialValue={initialValue} name={path}>
      <Select
        dropdownRender={(menu) => (
          <>
            {menu}
            <AddAccountItemForm>
              <Input value={newAccountName} onChange={onChangeNewAccountName} />
              <Tooltip placement="rightTop" title="勘定科目マスターには追加されません。">
                <AddAccountItemButton icon={<PlusOutlined />} type="text" onClick={addAccountItem}>
                  追加する
                </AddAccountItemButton>
              </Tooltip>
            </AddAccountItemForm>
          </>
        )}
        options={[...addedAccounts, ...initialAccounts].map((item) => ({ label: item, value: item }))}
        onChange={setAccountCode}
      />
    </Form.Item>
  );
}

export default AccountSelectForm;
