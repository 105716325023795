import { ReactElement } from 'react';

import { DocumentOcrErrorEntity } from '../../../generated-api';
import ocrTypeNameDefinitions from '../../../utils/ocrTypeNameDefinitions';
import { OcrResultPageDefinition } from './types';

function OcrErrorFormInputs(): ReactElement {
  return (
    <>
      <p>読み取り中にエラーが発生しました。</p>
      <p>原因調査・改善にご協力いただける場合は、読み取りに失敗したファイルをご共有いただけますと幸いです。</p>
    </>
  );
}

const ocrErrorResultPageDefinition: OcrResultPageDefinition<DocumentOcrErrorEntity> = {
  name: ocrTypeNameDefinitions.ocr_error,
  formComponent: OcrErrorFormInputs,
  tabs: [
    {
      csvColumns: {},
      tableColumns: [],
    },
  ],
};
export default ocrErrorResultPageDefinition;
