import { Button, Modal, Spin, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import { ReactElement, useCallback, useState } from 'react';
import { useAsyncFn, useAsyncRetry } from 'react-use';
import styled from 'styled-components';

import { accountingCategoriesApi } from '../../api/apiClient';
import Badge from '../../components/Badge/Badge';
import { UserAccountingCategories } from '../../generated-api';
import getErrorBrief from '../../utils/getErrorBrief';
import AccountingCategoryCreateModal from './AccountingCategoryCreateModal';
import AccountingCategoryEditModal from './AccountingCategoryEditModal';
import { SettingItemHeaderLabel } from './SettingItemHeaderLabel';
import { SettingItemLayout } from './SettingItemLayout';

const SettingHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const StyledButton = styled(Button)`
  padding: 4px;
  min-width: 100px;
`;

const AccountingCategorySetting = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [accountId, setAccountId] = useState<string | undefined>(undefined);
  const [accountName, setAccountName] = useState<string | undefined>(undefined);
  const [accountCode, setAccountCode] = useState<string | undefined>(undefined);

  const { retry, value: accountingCategories } = useAsyncRetry(async () => {
    const { data: accountingCategories } =
      await accountingCategoriesApi.accountingCategoriesControllerGetAccountingCategories();
    return accountingCategories;
  }, []);
  const accountNameFilters = accountingCategories?.map((category) => {
    return {
      text: category.account,
      value: category.account,
    };
  });

  const openCreateModal = useCallback(() => {
    setCreateModalOpen(true);
  }, []);
  const openEditModal = useCallback((id: string, name: string, code?: string) => {
    setEditModalOpen(true);
    setAccountId(id);
    setAccountName(name);
    setAccountCode(code);
  }, []);
  const onClickDelete = useCallback(
    async (id: string, name: string) => {
      Modal.confirm({
        title: `勘定科目｢${name}｣を削除しますか？`,
        okText: 'はい',
        cancelText: 'いいえ',
        onOk: async () => {
          try {
            await accountingCategoriesApi.accountingCategoriesControllerDeleteAccountingCategory(id);
            retry();
          } catch (e) {
            Modal.error({
              title: getErrorBrief(e),
            });
          }
        },
      });
    },
    [retry]
  );

  return (
    <SettingItemLayout>
      <SettingHeader>
        <SettingItemHeaderLabel>領収書の勘定科目の設定</SettingItemHeaderLabel>
        <StyledButton type="primary" onClick={openCreateModal}>
          新規登録
        </StyledButton>
      </SettingHeader>
      <Table dataSource={accountingCategories} rowKey="account_id">
        <Column
          dataIndex="in_used"
          render={renderStatus}
          sorter={(a: UserAccountingCategories, b: UserAccountingCategories) => {
            if (a.in_used === b.in_used) {
              return 0;
            }
            if (a.in_used) {
              return -1;
            }
            return 1;
          }}
        />
        <Column
          filterSearch
          dataIndex="account"
          filters={accountNameFilters}
          sorter={(a: UserAccountingCategories, b: UserAccountingCategories) => a.account.localeCompare(b.account)}
          title="勘定科目名"
          onFilter={(value, record: UserAccountingCategories) => record.account.startsWith(value as string)}
        />
        <Column
          dataIndex="account_code"
          sorter={(a: UserAccountingCategories, b: UserAccountingCategories) => {
            if (!a.account_code && !b.account_code) {
              return 0;
            }
            if (!a.account_code) {
              return 1;
            }
            if (!b.account_code) {
              return -1;
            }
            return a.account_code.localeCompare(b.account_code);
          }}
          title="勘定科目コード"
        />
        <Column<UserAccountingCategories>
          render={(_, record) => (
            <ButtonGroup>
              <ToggleUsageButton
                accountId={record.account_id}
                inUsed={record.in_used}
                updateAccountingCategories={retry}
              />
              <Button
                type="primary"
                onClick={() => openEditModal(record.account_id, record.account, record.account_code)}>
                編集
              </Button>
              <Button
                danger
                disabled={record.company_id === 'master'}
                type="primary"
                onClick={() => onClickDelete(record.account_id, record.account)}>
                削除
              </Button>
            </ButtonGroup>
          )}
          width={280}
        />
      </Table>
      <AccountingCategoryCreateModal
        existingAccountNames={new Set(accountingCategories?.map((category) => category.account) || [])}
        open={createModalOpen}
        setModalOpen={setCreateModalOpen}
        updateAccountingCategories={retry}
      />
      <AccountingCategoryEditModal
        accountId={accountId}
        accountName={accountName}
        initAccountCode={accountCode}
        open={editModalOpen}
        setModalOpen={setEditModalOpen}
        updateAccountingCategories={retry}
      />
    </SettingItemLayout>
  );
};

interface ToggleUsageButtonProps {
  accountId: string;
  inUsed: boolean;
  updateAccountingCategories: () => void;
}
function ToggleUsageButton({ accountId, inUsed, updateAccountingCategories }: ToggleUsageButtonProps): ReactElement {
  const [state, toggle] = useAsyncFn(
    async (accountId: string) => {
      if (inUsed) {
        await accountingCategoriesApi.accountingCategoriesControllerTurnOffAccountingCategory(accountId);
      } else {
        await accountingCategoriesApi.accountingCategoriesControllerTurnOnAccountingCategory(accountId);
      }
      updateAccountingCategories();
    },
    [updateAccountingCategories]
  );

  return (
    <StyledButton danger={inUsed} disabled={state.loading} type="primary" onClick={() => toggle(accountId)}>
      {state.loading ? <Spin /> : inUsed ? '使用しない' : '使用する'}
    </StyledButton>
  );
}

function renderStatus(used: boolean) {
  if (used) {
    return <Badge variant="success">使用中</Badge>;
  } else {
    return <Badge variant="danger">不使用</Badge>;
  }
}

export default AccountingCategorySetting;
