import { Input } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { ReactElement, ReactNode, useState } from 'react';

import FormItemWithLabel from '../../../components/Form/FormItemWithLabel';
import { DocumentReceiptEntity, ReceiptDetail } from '../../../generated-api';
import ocrTypeNameDefinitions from '../../../utils/ocrTypeNameDefinitions';
import PayeeInput from '../Receipt/PayeeInput';
import ReceiptDetailForm from '../Receipt/ReceiptDetailForm';
import ReceiptTable from '../Receipt/ReceiptTable';
import TrainingPermissionCheckbox from '../TrainingPermissionCheckbox';
import renderTableColumn from './renderTableColumn';
import { CSVConvertResult, OcrResultPageDefinition, ResultPageDocument } from './types';

type FormProps = {
  documentId: string;
  entity: DocumentReceiptEntity;
  groupId: string;
  changed: boolean;
  setChanged: (newValue: boolean) => void;
  path?: NamePath;
};
export function ReceiptFormInputs({
  documentId,
  entity,
  groupId,
  changed,
  setChanged,
  path = [],
}: FormProps): ReactElement {
  // detail で payee の変更を検知するために使用する
  const [inputPayee, setInputPayee] = useState<string | null>(entity.payee?.[0] ?? null);
  const onPayeeChange = async (value: string) => {
    setInputPayee(value);
  };
  return (
    <>
      <FormItemWithLabel initialValue={entity.payment_date} label="支払い日付" name="payment_date" path={path}>
        <Input />
      </FormItemWithLabel>
      <PayeeInput path={path} payees={entity.payee ?? []} onChange={onPayeeChange} />
      <FormItemWithLabel initialValue={entity.business_number} label="事業者番号" name="business_number" path={path}>
        <Input />
      </FormItemWithLabel>
      <ReceiptDetailForm
        changed={changed}
        entity={entity}
        inputPayee={inputPayee}
        path={path}
        setChanged={setChanged}
      />
      <TrainingPermissionCheckbox documentId={documentId} groupId={groupId} />
    </>
  );
}

function expandReceiptTable(record: ResultPageDocument<DocumentReceiptEntity>): ReactNode {
  if (record.error) {
    return;
  }
  return <ReceiptTable receipt={record.latest_revision} />;
}

export type FormResult = {
  business_number: string;
  payment_date: string;
  payee: string[]; // 実際には支払先ではなく摘要を表す
  payee_input: string;
  receipt_detail: Record<string, ReceiptDetail>;
  rowIndices: Record<string, unknown>[]; // rowId と 列の並びを保持する map
};
export function formDataToEntity(data: FormResult): DocumentReceiptEntity {
  const rowIds = data.rowIndices.map((row) => Object.keys(row)[0]);
  const receiptDetails = rowIds.map((rowId) => data.receipt_detail[rowId]);

  // payee となっているが実際には支払先ではなく摘要を表す
  const payee = data.payee ? [...data.payee] : [];
  if (data.payee_input) {
    payee.unshift(data.payee_input);
  }

  return {
    payment_date: data.payment_date,
    payee: [...new Set(payee)],
    business_number: data.business_number,
    receipt_details: receiptDetails,
    type: 'receipt',
  };
}

export function receiptsToCsv(
  data: {
    summary: Record<string, string | number>;
    latest_revision: DocumentReceiptEntity;
  }[]
): CSVConvertResult {
  const csvData = data.flatMap((item) => {
    const defaultDetail = {
      税率: '10',
      税抜き金額: '',
      税額: '',
      税込み金額: '',
      勘定科目名: '-',
      勘定科目コード: '-',
      取引内容: '',
    };
    const row = item.latest_revision.receipt_details?.map((detail) => {
      return {
        ...item.summary,
        税率: detail.tax_percent?.toString() ?? '',
        税抜き金額: detail.amount_without_tax?.toString() ?? '',
        税額: detail.tax_amount?.toString() ?? '',
        税込み金額: detail.amount_with_tax?.toString() ?? '',
        勘定科目名: detail.account ?? '-',
        勘定科目コード: detail.account_code ?? '-',
        取引内容: detail.transaction_content ?? '',
      };
    }) ?? [{ ...item.summary, ...defaultDetail }];
    return row;
  });
  return {
    csv: csvData,
  };
}

function convertReceiptCsv(data: ResultPageDocument<DocumentReceiptEntity>[]): CSVConvertResult {
  const receipts = data.map((item) => {
    return {
      summary: {
        ファイル名: item.parsed_path.fullFileName,
        ページ数: item.page?.toString() ?? '-',
        支払い日付: item.latest_revision.payment_date ?? '',
        摘要: item.latest_revision.payee?.[0] ?? '',
        事業者番号: item.latest_revision.business_number ?? '',
      },
      latest_revision: item.latest_revision,
    };
  });
  return receiptsToCsv(receipts);
}

type PayeeColumnProps = {
  record: DocumentReceiptEntity;
};
function PayeeColumn({ record }: PayeeColumnProps) {
  if (record.payee) {
    return <>{record.payee[0]}</>;
  }
  return <>-</>;
}

const receiptResultPageDefinition: OcrResultPageDefinition<DocumentReceiptEntity> = {
  name: ocrTypeNameDefinitions.receipt,
  tabs: [
    {
      csvColumns: {},
      tableColumns: [
        { dataIndex: ['latest_revision', 'payment_date'], title: '支払い日付', render: renderTableColumn },
        {
          dataIndex: ['latest_revision'],
          title: '摘要',
          render: (record: DocumentReceiptEntity) => <PayeeColumn record={record} />,
        },
        {
          dataIndex: ['latest_revision', 'business_number'],
          title: '事業者番号',
          render: renderTableColumn,
        },
      ],
      tableExpandable: {
        expandedRowRender: expandReceiptTable,
      },
    },
  ],
  formComponent: ReceiptFormInputs,
  formDataToEntity,
  csvDataConverters: [
    {
      converter: convertReceiptCsv,
    },
  ],
};

export default receiptResultPageDefinition;
