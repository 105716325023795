import { FileOutlined } from '@ant-design/icons';
import { Button, Checkbox, Typography } from 'antd';
import { useState } from 'react';
import { useAsyncFn } from 'react-use';
import styled from 'styled-components';

import { useAgreeTerms, useCommitLogout } from '../../store/useAuthStore';
import { notifySuccess } from '../../utils/notification';

export default function TermsAgree() {
  const agreeTerms = useAgreeTerms();
  const logout = useCommitLogout();

  const [checked, setChecked] = useState(false);

  const [state, agreeTermsCallback] = useAsyncFn(async () => {
    await agreeTerms();
    notifySuccess('約款への同意後は再ログインが必要です。お手数ですが再度ログインをお願いします。');
    await logout();
  });

  return (
    <AgreeContainer>
      <Checkbox onChange={(e) => setChecked(e.target.checked)}>以下の利用規約に同意しますか？</Checkbox>
      <Typography.Link href="/20240718_Ez-AiOCR利用約款.pdf" target="_blank">
        <FileOutlined />
        利用約款PDF
      </Typography.Link>
      <Button disabled={!checked} loading={state.loading} size="large" type="primary" onClick={agreeTermsCallback}>
        ＞ 次に進む
      </Button>
    </AgreeContainer>
  );
}

const AgreeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;
