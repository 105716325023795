import { QuestionCircleOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';

import UserName from '../../../components/User/UserName';
import { DocumentType } from '../../../generated-api';
import ocrResultPageDefinitions from '../defs';
import DocumentStatus, { DocumentStatusType } from '../DocumentStatus';

type Props = PropsWithChildren<
  | {
      documentType: DocumentType;
      status: DocumentStatusType;
      executedUserId: string | null;
      uploadedAt: string | null;
      filesCount: number | null;
      totalPageCount: number | null;
      succeededFileCount: number | null;
      failedFileCount: number | null;
    }
  | {
      documentType: DocumentType;
      status: 'loading';
      executedUserId?: never;
      uploadedAt?: never;
      filesCount?: never;
      totalPageCount?: never;
      succeededFileCount?: never;
      failedFileCount?: never;
    }
>;

export default function ResultSummaryContainer({
  children,
  documentType,
  executedUserId,
  failedFileCount,
  filesCount,
  status,
  succeededFileCount,
  totalPageCount,
  uploadedAt,
}: Props) {
  const def = ocrResultPageDefinitions[documentType];
  return (
    <SummaryContainer>
      <Title level={5}>書類種別: {def.name}</Title>
      <DocumentStatus status={status} />
      <ImplementationInfo>
        <ImplementationInfoItem>
          実施者: <UserName userId={executedUserId ?? ''} />
        </ImplementationInfoItem>
        <ImplementationInfoItem>実施日時: {uploadedAt}</ImplementationInfoItem>
      </ImplementationInfo>
      <FileCount>
        ファイル数
        <Question title="画像合成した場合、ファイル数は必ず1となります。" />: {filesCount}
      </FileCount>
      <PageCountContainer>
        <PageCountTotal>
          総ページ数: {totalPageCount == null || totalPageCount <= 0 ? '-' : totalPageCount}
        </PageCountTotal>
        <PageCountList>
          <PageCountListElement>正常ファイル数: {succeededFileCount}</PageCountListElement>
          <PageCountListElement>エラーファイル数: {failedFileCount}</PageCountListElement>
        </PageCountList>
      </PageCountContainer>
      {children}
    </SummaryContainer>
  );
}

const SummaryContainer = styled.div`
  width: 100%;
`;

const ImplementationInfo = styled.div`
  margin-bottom: 16px;
`;

const ImplementationInfoItem = styled.div``;

const FileCount = styled.div``;

const Question = styled(QuestionCircleOutlined)`
  padding: 0 4px;
  color: rgba(0, 0, 0, 0.45);
`;

const PageCountContainer = styled.div``;

const PageCountTotal = styled.div``;

const PageCountList = styled.ul`
  padding: 0;
  display: flex;
`;

const PageCountListElement = styled.li`
  list-style-type: none;
  margin-right: 10px;
`;
