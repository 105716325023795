import { Spin } from 'antd';
import styled from 'styled-components';

function LoadingScreen() {
  return (
    <Screen>
      <Spin size="large" />
    </Screen>
  );
}

export default LoadingScreen;

const Screen = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
`;
