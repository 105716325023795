import { Form, Input, InputNumber, Select } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { set } from 'lodash';
import { ReactElement, useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { InvoiceDetail, UserAccountingCategories } from '../../../generated-api';
import getPath from '../../../utils/getPath';
import AccountSelectForm from '../AccountSelectForm';
import { FluctuationButton } from '../FluctuationButton';

export const DetailRow = styled.div`
  display: grid;
  grid-template-columns: 70px repeat(4, minmax(75px, 1fr)) 100px;
  grid-gap: 8px;
  align-items: baseline;
`;
const RowHeaderLabel = styled.div`
  font-weight: bold;
  padding-left: 4px;
`;
const InputNumberStyle = styled(InputNumber)`
  width: 100%;
`;

const taxPercentChoice = [
  { label: '-', value: undefined },
  { label: '10%', value: 10 },
  { label: '8%', value: 8 },
  { label: '0%', value: 0 },
];

export function InvoiceDetailHeader(): ReactElement {
  return (
    <DetailRow>
      <RowHeaderLabel>税率</RowHeaderLabel>
      <RowHeaderLabel>税抜き金額</RowHeaderLabel>
      <RowHeaderLabel>税額</RowHeaderLabel>
      <RowHeaderLabel>税込み金額</RowHeaderLabel>
      <RowHeaderLabel>勘定科目</RowHeaderLabel>
    </DetailRow>
  );
}

type InvoiceDetailRowProps = {
  accountCategories: UserAccountingCategories[];
  detail: InvoiceDetail;
  path: NamePath;
  addRow: (index: number) => void;
  removeRow: (index: number) => void;
  hiddenRemove: boolean;
  rowId: string;
  rowIndex: number;
};
function InvoiceDetailRow({
  accountCategories,
  addRow,
  detail,
  hiddenRemove,
  path,
  removeRow,
  rowId,
  rowIndex,
}: InvoiceDetailRowProps): ReactElement {
  const form = Form.useFormInstance();
  const detailRowPath = getPath(path, 'invoice_detail', rowId);

  const accountMap = useMemo(() => {
    return new Map(accountCategories.map((item) => [item.account, item.account_code]));
  }, [accountCategories]) as Map<string, string>;

  const onChangeTaxPercent = useCallback(
    (value: number) => {
      const amountWithTax = form.getFieldValue(getPath(detailRowPath, 'amount_with_tax'));
      if (amountWithTax) {
        // amount_without_tax, tax_amount のみ更新する
        const amountWithoutTax = Math.ceil(amountWithTax / (1 + value / 100));
        const taxAmount = amountWithTax - amountWithoutTax;
        const currentValues = form.getFieldsValue();
        let updatedValues;
        updatedValues = set(currentValues, getPath(detailRowPath, 'amount_without_tax'), amountWithoutTax);
        updatedValues = set(updatedValues, getPath(detailRowPath, 'tax_amount'), taxAmount);
        form.setFieldsValue(updatedValues);
      }
    },
    [detailRowPath, form]
  );

  const setAccountCode = useCallback(
    (account: string) => {
      const currentValues = form.getFieldsValue();
      const updatedValues = set(currentValues, getPath(detailRowPath, 'account_code'), accountMap.get(account));
      form.setFieldsValue(updatedValues);
    },
    [form, detailRowPath, accountMap]
  );

  return (
    <>
      <DetailRow>
        <Form.Item initialValue={detail.tax_percent} name={getPath(detailRowPath, 'tax_percent')}>
          <Select
            options={taxPercentChoice.map((item) => ({ label: item.label, value: item.value }))}
            onChange={onChangeTaxPercent}
          />
        </Form.Item>
        <Form.Item initialValue={detail.amount_without_tax ?? 0} name={getPath(detailRowPath, 'amount_without_tax')}>
          <InputNumberStyle />
        </Form.Item>
        <Form.Item initialValue={detail.tax_amount} name={getPath(detailRowPath, 'tax_amount')}>
          <InputNumberStyle />
        </Form.Item>
        <Form.Item initialValue={detail.amount_with_tax} name={getPath(detailRowPath, 'amount_with_tax')}>
          <InputNumberStyle />
        </Form.Item>
        <AccountSelectForm
          accountCategories={accountCategories ?? []}
          initialValue={detail.account ?? ''}
          path={getPath(detailRowPath, 'account')}
          setAccountCode={setAccountCode}
        />
        <FluctuationButton add={addRow} hiddenRemove={hiddenRemove} index={rowIndex} remove={removeRow} />
      </DetailRow>
      <Form.Item hidden initialValue={detail.account_code ?? '-'} name={getPath(detailRowPath, 'account_code')}>
        <Input />
      </Form.Item>
    </>
  );
}

export default InvoiceDetailRow;
