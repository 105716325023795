import { Table } from 'antd';
import Column from 'antd/lib/table/Column';
import { ReactElement } from 'react';

import { DocumentReceiptEntity } from '../../../generated-api';

export function ReceiptTable({ receipt }: { receipt: DocumentReceiptEntity }): ReactElement {
  const paymentDate = receipt.payment_date ?? '-';
  const payee = receipt.payee ?? ['-'];
  const receiptDetails = receipt.receipt_details;

  const data: Record<string, string | number>[] = receiptDetails
    ? receiptDetails.map((detail, index) => {
        return {
          key: index,
          payment_date: paymentDate,
          payee: payee[0],
          tax_percent: detail.tax_percent ?? 10,
          amount_without_tax: detail.amount_without_tax ?? 0,
          tax_amount: detail.tax_amount ?? 0,
          amount_with_tax: detail.amount_with_tax ?? 0,
          account: detail.account ?? '-',
          account_code: detail.account_code ?? '-',
          transaction_content: detail.transaction_content ?? '',
        };
      })
    : [
        // detailがない場合は、デフォルトで10%の欄に表示する
        {
          key: 0,
          payment_date: paymentDate,
          payee: payee[0],
          tax_percent: 10,
          amount_without_tax: 0,
          tax_amount: 0,
          amount_with_tax: 0,
          account: '-',
          account_code: '-',
          transaction_content: '',
        },
      ];

  return (
    <Table dataSource={data} pagination={false}>
      <Column key="payment_date" dataIndex="payment_date" title="支払い日付" />
      <Column key="payee" dataIndex="payee" title="摘要" />
      <Column key="tax_percent" dataIndex="tax_percent" title="税率" />
      <Column key="amount_without_tax" dataIndex="amount_without_tax" title="税抜き金額" />
      <Column key="tax_amount" dataIndex="tax_amount" title="税額" />
      <Column key="amount_with_tax" dataIndex="amount_with_tax" title="税込み金額" />
      <Column key="account" dataIndex="account" title="勘定科目名" />
      <Column key="account_code" dataIndex="account_code" title="勘定科目コード" />
      <Column key="transaction_content" dataIndex="transaction_content" title="取引内容" />
    </Table>
  );
}

export default ReceiptTable;
