import { ReactElement } from 'react';
import { useAsync } from 'react-use';

import usersDataLoader from '../../api/usersDataLoader';

interface Props {
  userId: string;
}
const UserName = ({ userId }: Props): ReactElement => {
  const { value: userName } = useAsync(async () => {
    if (userId.length === 0) {
      return null;
    }
    const user = await usersDataLoader.getUserById(userId);
    return user?.name;
  }, [userId]);

  return <>{userName ?? '不明なユーザ'}</>;
};

export default UserName;
