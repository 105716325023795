import DataLoader from 'dataloader';

import { User } from '../generated-api';
import { usersApi } from './apiClient';

export class UsersDataLoader {
  private loader = new DataLoader<string, User | null>(
    async (keys) => {
      if (keys.length === 0) {
        return [];
      }
      const { data } = await usersApi.userControllerGetUsers(Array.from(new Set(keys)));

      return keys.map((key) => data.users[key] ?? null);
    },
    {
      // フロントのページングのサイズが最大100なのでそれぐらい
      maxBatchSize: 100,
      cache: false,
    }
  );

  public async getUserById(userId: string): Promise<User | null> {
    return this.loader.load(userId);
  }

  public async getUsersByIds(userIds: string[]): Promise<Array<User | null>> {
    const result = await this.loader.loadMany(userIds);
    return result.filter((v): v is User => !(v instanceof Error));
  }
}

const usersDataLoader = new UsersDataLoader();

export default usersDataLoader;
