export default function parseCurrencyInt(value: string | undefined): number {
  if (value == null) {
    return NaN;
  }
  const numberStr = value.replaceAll(',', '');
  const parsed = parseInt(numberStr, 10);

  // parseInt は成功判定が緩すぎなのでカンマを除いた値と parseInt 後の値が一致しなければ NaN とみなす
  if (isNaN(parsed) || parsed.toString() !== numberStr) {
    return NaN;
  }

  return parsed;
}
