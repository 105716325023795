import { Button, Form, Input } from 'antd';
import { Rule } from 'antd/lib/form';
import { useForm } from 'antd/lib/form/Form';
import { useHistory } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import styled from 'styled-components';

import { authApi } from '../../api/apiClient';
import AuthPageContainer from '../../components/Container/AuthPageContainer';
import { notifySuccess } from '../../utils/notification';

const emailRules: Rule[] = [
  { required: true, message: '入力必須項目' },
  { type: 'email', message: '有効なメールアドレスを入力してください' },
];

const PasswordForgetPage = () => {
  const [form] = useForm();
  const history = useHistory();

  const [state, onClickSendMail] = useAsyncFn(async () => {
    const email = form.getFieldValue('email');
    await authApi.authControllerSendPasswordChangeMail({ email });
    notifySuccess('パスワードリセットメールを送信しました。');
    history.push('/login');
  }, [form, history]);

  return (
    <AuthPageContainer>
      <Form form={form} layout="vertical" requiredMark={false}>
        <FormItem label="メールアドレス" name="email" rules={emailRules}>
          <FormInput type="email" />
        </FormItem>
        <Description>
          <Sentence>登録したメールアドレスを入力し、送信ボタンを押してください。</Sentence>
          <Sentence>メール記載のURLにアクセスして、パスワードの再設定を行ってください</Sentence>
        </Description>
        <FormItem>
          <FormButton block disabled={state.loading} htmlType="submit" type="primary" onClick={onClickSendMail}>
            メール送信
          </FormButton>
        </FormItem>
      </Form>
    </AuthPageContainer>
  );
};

export default PasswordForgetPage;

const FormItem = styled(Form.Item)`
  width: 500px;
`;

const FormInput = styled(Input)`
  height: 45px;
`;

const Description = styled.div`
  margin-bottom: 24px;
`;

const Sentence = styled.p`
  margin: 0;
  color: #777;
`;

const FormButton = styled(Button)`
  height: 50px;
`;
