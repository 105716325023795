import axios from 'axios';

import {
  AccountingCategoriesApi,
  AccountingCategoryCompletionApi,
  AuthApi,
  CompaniesApi,
  CompanyUsageApi,
  DocumentApi,
  UsersApi,
} from '../generated-api';

const options = {
  isJsonMime: (mime: string) => mime === 'application/json',
  headers: {
    'Content-Type': 'application/json',
  },
};
let axiosInterceptorId = 0;

const endpoint = process.env.REACT_APP_API_ENDPOINT;

export const apiClientRaw = axios.create();

initAxiosInterceptor();

export const authApi = new AuthApi(options, endpoint, apiClientRaw);

export const usersApi = new UsersApi(options, endpoint, apiClientRaw);

export const documentApi = new DocumentApi(options, endpoint, apiClientRaw);

export const companiesApi = new CompaniesApi(options, endpoint, apiClientRaw);

export const companyUsageApi = new CompanyUsageApi(options, endpoint, apiClientRaw);

export const accountingCategoriesApi = new AccountingCategoriesApi(options, endpoint, apiClientRaw);

export const accountingCategoryCompletionApi = new AccountingCategoryCompletionApi(options, endpoint, apiClientRaw);

function initAxiosInterceptor(): void {
  if (axiosInterceptorId !== 0) {
    return;
  }
  axiosInterceptorId = apiClientRaw.interceptors.request.use((req) => {
    if (!req.url?.endsWith('/auth/login')) {
      const token = localStorage.getItem('token');
      req.headers['Authorization'] = `Bearer ${token}`;
    }
    return req;
  });

  apiClientRaw.interceptors.response.use(
    (res) => res,
    (error) => {
      if (axios.isAxiosError(error) && error.response?.status == 401 && window.location.pathname !== '/login') {
        if (localStorage.getItem('token')) {
          localStorage.removeItem('token');
        }
      }
      return Promise.reject(error);
    }
  );
}
