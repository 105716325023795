import { Form, Space } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import {
  ComponentType,
  CSSProperties,
  KeyboardEvent,
  KeyboardEventHandler,
  ReactElement,
  RefCallback,
  useRef,
} from 'react';
import styled from 'styled-components';

import getPath from '../../utils/getPath';
import parseCurrencyInt from '../../utils/parseCurrencyInt';
import { FluctuationButton } from './FluctuationButton';

type FocusElementController = {
  onKeyDown: KeyboardEventHandler;
  ref: RefCallback<HTMLElement>;
};

function useFocusController(): (
  rowIndex: number,
  columnIndex: number,
  rows: number,
  columns: number
) => FocusElementController {
  const ref = useRef<Record<string, HTMLInputElement>>({});
  return (rowIndex: number, columnIndex: number, rows: number, columns: number) => {
    const key = `${rowIndex}-${columnIndex}`;
    ref.current[key] = ref.current[key] || null;

    const keyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      const isArrowUpKey = ['ArrowUp'].includes(e.key);
      const isArrowDownKey = ['ArrowDown'].includes(e.key);

      if (!isArrowUpKey && !isArrowDownKey) {
        return;
      }
      e.preventDefault();

      const canMoveUpOrLeft = isArrowUpKey && e.currentTarget.selectionStart === 0;
      const canMoveDownOrRight = isArrowDownKey && e.currentTarget.selectionStart === e.currentTarget.value.length;
      if (canMoveUpOrLeft || canMoveDownOrRight) {
        moveForm(e.key);
      }

      moveCursor(e);
    };

    const moveCursor = (e: KeyboardEvent<HTMLInputElement>) => {
      const cursorPosition = e.currentTarget.selectionStart;
      switch (e.key) {
        case 'ArrowUp':
          e.currentTarget.setSelectionRange(0, 0);
          break;
        case 'ArrowDown':
          e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length);
          break;
      }
    };

    const moveForm = (key: string) => {
      switch (key) {
        case 'ArrowUp':
          focusInput(rowIndex - 1, columnIndex, 'end');
          break;
        case 'ArrowDown':
          focusInput(rowIndex + 1, columnIndex, 'start');
          break;
      }
    };

    const focusInput = (rowIndex: number, columnIndex: number, cursorPosition: 'start' | 'end') => {
      if (rowIndex < 0 || rowIndex >= rows || columnIndex < 0 || columnIndex >= columns) {
        return;
      }
      const targetInput = ref.current[`${rowIndex}-${columnIndex}`];

      if (cursorPosition === 'start') {
        targetInput.setSelectionRange(0, 0);
      }
      if (cursorPosition === 'end') {
        const length = targetInput.value.length;
        targetInput.setSelectionRange(length, length);
      }

      targetInput?.focus();
    };

    const setRef: RefCallback<HTMLInputElement> = (node) => {
      if (!node) {
        return;
      }
      ref.current[key] = node;
    };

    return {
      onKeyDown: keyDown,
      ref: setRef,
    };
  };
}

export type ColumnType = Partial<{
  currency: boolean;
}>;

const BlankContainer = () => {
  return <></>;
};
export interface PrependComponentProp<T> {
  value?: T;
  id?: string;
}
interface Props<PrependValue> {
  columnTypes: ColumnType[];
  columnIds: string[];
  rows: Record</* rowId */ string, Record</* columnId */ string, /* value */ string>>;
  rowIds: string[];
  addRow: (index: number) => void;
  removeRow: (index: number) => void;
  prependComponent?: ComponentType<PrependComponentProp<PrependValue>>;
  prependData?: Record<string, PrependValue | undefined>;
  path?: NamePath;
}

export const RowInputForm = <PrependValue,>({
  addRow,
  columnIds,
  removeRow,
  rowIds,
  rows,
  path,
  prependComponent: PrependComponent = BlankContainer,
  prependData = {},
  columnTypes,
}: Props<PrependValue>): ReactElement => {
  const focusController = useFocusController();

  return (
    <>
      {rowIds.length === 0 && <FluctuationButton hiddenRemove add={addRow} index={-1} remove={removeRow} />}
      {rowIds.map((rowId, rowIndex) => {
        return (
          <Row key={rowId}>
            <PrependComponent id={rowId} value={prependData[rowId]} />
            <RowNumber>{rowIndex + 1}</RowNumber>
            {columnIds.map((columnId, colIndex) => {
              const style = makeStyleFromColumnType(columnTypes[colIndex]);
              return (
                <Form.Item
                  key={columnId}
                  initialValue={formatInitialValue(columnTypes[colIndex], rows?.[rowId]?.[columnId])}
                  name={getPath(path, 'table', rowId, columnId)}>
                  <Input
                    style={style}
                    type="text"
                    {...focusController(rowIndex, colIndex, rowIds.length, columnIds.length)}
                  />
                </Form.Item>
              );
            })}
            <FluctuationButton add={addRow} hiddenRemove={rowIds.length <= 1} index={rowIndex} remove={removeRow} />
          </Row>
        );
      })}
    </>
  );
};

const Input = styled.input`
  width: 150px;
  font-size: 12px;
`;

const Row = styled(Space)`
  display: flex;
  align-items: baseline;
  height: 40px;
`;

const RowNumber = styled.div`
  width: 1rem;
`;

function makeStyleFromColumnType(columnType?: ColumnType): CSSProperties {
  if (columnType == null) {
    return {};
  }
  if (columnType.currency) {
    return {
      textAlign: 'right',
    };
  }

  return {};
}

function formatInitialValue(columnType: ColumnType | undefined, value: string | undefined) {
  if (value == null) {
    return '';
  }
  if (columnType == null) {
    return value;
  }

  if (columnType.currency) {
    const parsed = parseCurrencyInt(value);
    return isNaN(parsed) ? value : parsed.toLocaleString('ja-JP', { currency: 'JPY' });
  }

  return value;
}
