import styled from 'styled-components';

const LoadingDot = styled.div`
  display: inline-block;
  width: 30px;
  line-height: 15px;
  height: 15px;
  &:after {
    display: inline-block;
    width: 100%;
    height: 100%;
    content: '';
    animation-name: dots;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }

  @keyframes dots {
    0% {
      content: '';
    }

    25% {
      content: '.';
    }

    50% {
      content: '..';
    }

    75% {
      content: '...';
    }

    100% {
      content: '';
    }
  }
`;

export default LoadingDot;
