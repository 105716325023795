import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Typography, Upload } from 'antd';
import { Rule } from 'antd/lib/form';
import { useForm } from 'antd/lib/form/Form';
import { RcFile } from 'antd/lib/upload/interface';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { documentApi } from '../../api/apiClient';
import { fileUpload } from '../../api/fileUpload';
import PageLayout from '../../components/Container/PageLayout';
import DocumentTypeSelectForm from '../../components/Form/DocumentTypeSelectForm';
import { DocumentType } from '../../generated-api';
import { mediaQuery } from '../../utils/mediaQuery';
import { notifyError, notifySuccess } from '../../utils/notification';
import CombineOption, { allowCombineTypes } from './CombineOption';
import DocumentTypeGuide from './DocumentTypeGuide';

const { Dragger } = Upload;
const { Title } = Typography;

const allowFileTypes = ['application/pdf', 'image/jpeg', 'image/png'];
const initialFormValues = { ocrType: null };

const selectRules: Rule[] = [{ required: true, message: 'この項目は必須です' }];

const combinedType: Partial<Record<DocumentType, DocumentType>> = {
  receipt: 'dual_horizontal_receipt',
};

const TopPage = () => {
  const history = useHistory();
  const [form] = useForm<FormValues>();
  const [files, setFiles] = useState<RcFile[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedDocType, setSelectedDocType] = useState<DocumentType | null>(null);
  const [combinable, setCombinable] = useState(false);

  const handlers = {
    beforeUpload: (file: RcFile) => {
      if (file.type == null || !allowFileTypes.includes(file.type)) {
        return Upload.LIST_IGNORE;
      }

      if (file.size > 20 * 1024 * 1024) {
        notifyError(`「${file.name}」のファイルサイズが大きすぎます。アップロード出来るのは 20MB までです。`);
        return Upload.LIST_IGNORE;
      }
      setFiles((state) => [...state, file]);
      return false;
    },
    onRemove: (file: unknown) => {
      const copyFiles = [...files];
      const index = copyFiles.indexOf(file as RcFile);
      copyFiles.splice(index, 1);
      setFiles([...copyFiles]);
    },
  };

  const handleFinish = useCallback(
    async (values: FormValues) => {
      try {
        setIsUploading(true);
        const groupId = await fileUpload(files, values.ocrType, combinable);
        notifySuccess('アップロードに成功しました。');
        if (combinable) {
          await documentApi.documentControllerCombineDocuments(groupId);
          const documentType = combinedType[values.ocrType];
          history.push(`/result/${documentType}/${groupId}`);
        } else {
          history.push(`/result/${values.ocrType}/${groupId}`);
        }
        setFiles([]);
      } catch {
        notifyError('アップロードに失敗しました。');
      } finally {
        setIsUploading(false);
      }
    },
    [combinable, files, history]
  );

  const handleSelectChange = (value: DocumentType) => {
    setSelectedDocType(value);
    if (allowCombineTypes.has(value)) {
      setCombinable(true);
    } else {
      setCombinable(false);
    }
  };

  return (
    <PageLayout heading="帳票を読み取る">
      <Form form={form} initialValues={initialFormValues} onFinish={handleFinish}>
        <StepHeading level={5}>STEP1: 対象の帳票を選択してください</StepHeading>
        <DocumentTypeSelectForm rules={selectRules} onChange={handleSelectChange} />
        <CombineOption combinable={combinable} documentType={selectedDocType} setCombinable={setCombinable} />
        <DocumentTypeGuide documentType={selectedDocType} />
        <StepHeading level={5}>STEP2: 読み取る帳票をアップロードしてください</StepHeading>
        <UploadDragger directory {...handlers} fileList={files} multiple={true}>
          <UploadIcon />
          <p className="ant-upload-text">帳票をアップロード</p>
          <p className="ant-upload-hint">pdf, png, またはそれらが含まれたフォルダを選択出来ます。</p>
          <p className="ant-upload-hint">ファイルまたはフォルダをドラック&ドロップ出来ます。</p>
        </UploadDragger>
        <UploadButton disabled={files.length === 0} htmlType="submit" loading={isUploading} type="primary">
          アップロード
        </UploadButton>
      </Form>
    </PageLayout>
  );
};

export default TopPage;

type FormValues = {
  ocrType: DocumentType;
};

const StepHeading = styled(Title)`
  padding-bottom: 16px;
`;

const UploadDragger = styled(Dragger)`
  user-select: none;
  color: #1890ff;
  width: 400px;
  height: 350px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #efe;

  :hover {
    background-color: #eee;
  }
  @media (${mediaQuery.sp}) {
    width: 100%;
  }
`;

const UploadIcon = styled(UploadOutlined)`
  display: inline-block;

  > svg {
    width: 50px;
    height: 50px;
  }
`;

const UploadButton = styled(Button)`
  margin: 24px 0;
`;
