import { Input, InputNumber } from 'antd';
import ExcelJS, { Alignment, Borders, Cell, Fill, Font, Workbook } from 'exceljs';
import { ReactElement } from 'react';

import FormItemWithLabel from '../../../components/Form/FormItemWithLabel';
import { DocumentFurusatoTaxEntity } from '../../../generated-api';
import ocrTypeNameDefinitions from '../../../utils/ocrTypeNameDefinitions';
import renderTableColumn from './renderTableColumn';
import { OcrResultPageDefinition, ResultPageDocument } from './types';

interface Props {
  entity: DocumentFurusatoTaxEntity;
}
function FurusatoTaxFormInputs({ entity }: Props): ReactElement {
  return (
    <>
      <FormItemWithLabel label="収納日" name="payment_day">
        <Input defaultValue={entity.payment_day} />
      </FormItemWithLabel>
      <FormItemWithLabel label="金額" name="amount">
        <InputNumber defaultValue={entity.amount} />
      </FormItemWithLabel>
      <FormItemWithLabel label="都道府県" name="prefecture">
        <Input defaultValue={entity.prefecture} />
      </FormItemWithLabel>
      <FormItemWithLabel label="都市" name="city">
        <Input defaultValue={entity.city} />
      </FormItemWithLabel>
      <FormItemWithLabel label="自治体所在地" name="address">
        <Input defaultValue={entity.address} />
      </FormItemWithLabel>
    </>
  );
}

function convertToExcelForTatsujin(values: ResultPageDocument<DocumentFurusatoTaxEntity>[]): Workbook {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet('寄附金の受領証等特定寄附金', { views: [{ showGridLines: false }] });

  const borderStyle: Partial<Borders> = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  };
  const fontStyle: Partial<Font> = {
    name: 'メイリオ',
    size: 9,
    color: { theme: 1 },
  };
  const headerFillStyle: Fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFD9D9D9' },
  };
  const headerAlignmentStyle: Partial<Alignment> = { vertical: 'middle', wrapText: true };

  const setBaseStyle = (cell: Cell) => {
    cell.border = borderStyle;
    cell.font = fontStyle;
  };
  const setHeaderStyle = (cell: Cell) => {
    setBaseStyle(cell);
    cell.alignment = headerAlignmentStyle;
  };

  sheet.eachRow((row) => {
    row.height = 15;
  });
  // 中途半端なWidthを指定してる理由は下記URLを参照
  // https://learn.microsoft.com/en-us/previous-versions/office/developer/office-2010/cc802410(v=office.14)?redirectedfrom=MSDN
  const columnWidth = 17.62;

  const headerBusinessCode = sheet.addRow(['事業者コード', '']);
  headerBusinessCode.eachCell(setHeaderStyle);
  headerBusinessCode.getCell(1).fill = headerFillStyle;
  headerBusinessCode.getCell(2).alignment = { ...headerAlignmentStyle, horizontal: 'left' };

  const headerBusinessName = sheet.addRow(['事業者名', '']);
  headerBusinessName.eachCell(setHeaderStyle);
  headerBusinessName.getCell(1).fill = headerFillStyle;
  headerBusinessName.getCell(2).alignment = { ...headerAlignmentStyle, horizontal: 'left' };

  const headerDataName = sheet.addRow(['データ名称', '寄附金の受領証等特定寄附金']);
  headerDataName.height = 30.75;
  headerDataName.eachCell((cell) => {
    setHeaderStyle(cell);
    cell.fill = headerFillStyle;
  });

  const columnRow = sheet.addRow(['寄附年月日', '寄附先の所在地', '寄附先の名称', '住民税区分', '金額']);
  columnRow.height = 15.75;
  columnRow.eachCell((cell, number) => {
    setHeaderStyle(cell);
    cell.border = {
      ...borderStyle,
      top: { style: 'double' },
    };
    cell.fill = headerFillStyle;
    sheet.getColumn(number).width = columnWidth;
  });

  values.forEach((value) => {
    const row = sheet.addRow([
      value.latest_revision.payment_day,
      value.latest_revision.address,
      value.latest_revision.city,
      '都道府県、市区町村分(ふるさと納税)',
      value.latest_revision.amount,
    ]);
    row.eachCell(setBaseStyle);
    row.height = 15;
  });

  return workbook;
}

const furusatoTaxResultPageDefinition: OcrResultPageDefinition<DocumentFurusatoTaxEntity> = {
  name: ocrTypeNameDefinitions.furusato_tax,
  formComponent: FurusatoTaxFormInputs,
  tabs: [
    {
      csvColumns: {
        ファイル名: ['parsed_path', 'fullFileName'],
        ページ数: ['page'],
        収納日: ['latest_revision', 'payment_day'],
        金額: ['latest_revision', 'amount'],
        都道府県: ['latest_revision', 'prefecture'],
        都市: ['latest_revision', 'city'],
        自治体所在地: ['latest_revision', 'address'],
      },
      tableColumns: [
        { dataIndex: ['latest_revision', 'payment_day'], title: '収納日', render: renderTableColumn },
        { dataIndex: ['latest_revision', 'amount'], title: '金額', render: renderTableColumn, sort: true },
        {
          dataIndex: ['latest_revision', 'prefecture'],
          title: '都道府県',
          render: renderTableColumn,
          searchFilter: true,
        },
        { dataIndex: ['latest_revision', 'city'], title: '都市', render: renderTableColumn, searchFilter: true },
        {
          dataIndex: ['latest_revision', 'address'],
          title: '自治体所在地',
          render: renderTableColumn,
          searchFilter: true,
        },
      ],
    },
  ],
  excelDataConverters: [
    {
      labelName: '達人インポートフォーマット',
      converter: convertToExcelForTatsujin,
    },
  ],
};
export default furusatoTaxResultPageDefinition;
