import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useMount } from 'react-use';
import { atom, useRecoilCallback, useRecoilState, useRecoilValueLoadable } from 'recoil';

import { companiesApi } from '../api/apiClient';
import { meSelectorAsync, useFetchMe } from './useMeStore';

// 初期化済みかどうか
const isAuthInitiatedState = atom<boolean>({
  key: 'AuthStore/isAuthInitiatedState',
  default: false,
});

export const useAuthStore = () => {
  const meLoadable = useRecoilValueLoadable(meSelectorAsync);

  const [isAuthInitiated, setIsAuthInitiated] = useRecoilState(isAuthInitiatedState);
  const fetchMe = useFetchMe();

  useMount(async () => {
    if (!isAuthInitiated) {
      try {
        await fetchMe();
      } finally {
        setIsAuthInitiated(true);
      }
    }
  });

  return {
    isAuthInitiated: isAuthInitiated && meLoadable.state !== 'loading',
    isAuthorized: meLoadable.state === 'hasValue',
  };
};

export const useCommitLogin = (): ((token: string) => void) => {
  const fetchMe = useFetchMe();

  return useCallback(
    async (newToken: string) => {
      localStorage.setItem('token', newToken);
      await fetchMe();
    },
    [fetchMe]
  );
};

export const useCommitLogout = (): (() => void) => {
  const history = useHistory();

  return useRecoilCallback(
    ({ refresh }) =>
      () => {
        refresh(meSelectorAsync);
        localStorage.removeItem('token');
        history.push('/login');
      },
    [history]
  );
};

export const useAgreeTerms = () => {
  return useCallback(async () => {
    await companiesApi.companyControllerAgreeTerms();
  }, []);
};
