import { useEffect } from 'react';
import { useMount } from 'react-use';
import { atom, useRecoilState } from 'recoil';

import useInterval from '../hooks/useInterval';
import useDocumentGroupStore from './useDocumentGroupStore';
import useDocumentListStore from './useDocumentListStore';

// 複数回呼ばれても壊れないようにタイマーの状態は atom で保持する
const documentListAutoUpdateAtom = atom({
  default: false,
  key: 'documentListAutoUpdateAtom',
});

// 読み取りが完了するまでの間の自動更新を制御する
export default function useDocumentListAutoUpdateStore(groupId: string) {
  const { actions: documentAction } = useDocumentListStore(groupId);
  const {
    actions: documentGroupAction,
    documentGroup,
    failedFileCount,
    forbidden,
    succeededFileCount,
  } = useDocumentGroupStore(groupId);

  const [autoUpdateEnable, setAutoUpdateEnable] = useRecoilState(documentListAutoUpdateAtom);

  const [start, clear, autoUpdateTimerEnable] = useInterval(async () => {
    Promise.all([documentAction.fetchDocumentList(), documentGroupAction.fetchDocumentGroup()]);
  }, 3000);

  useMount(() => {
    if (!autoUpdateEnable) {
      start();
      setAutoUpdateEnable(true);
    }

    return () => {
      clear();
      setAutoUpdateEnable(false);
    };
  });

  useEffect(() => {
    if (
      forbidden || // 閲覧権限がない
      (autoUpdateTimerEnable &&
        documentGroup != null &&
        documentGroup.files_count <= succeededFileCount + failedFileCount)
    ) {
      clear();
      setAutoUpdateEnable(false);
    }
  }, [
    clear,
    documentGroup,
    failedFileCount,
    autoUpdateTimerEnable,
    forbidden,
    setAutoUpdateEnable,
    succeededFileCount,
  ]);

  return { autoUpdateTimerEnable };
}
