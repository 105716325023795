import axios from 'axios';
import { useCallback, useMemo } from 'react';
import { useUnmount } from 'react-use';
import { atomFamily, useRecoilState, useResetRecoilState } from 'recoil';

import { documentApi } from '../api/apiClient';
import { GetDocumentGroupResponse } from '../generated-api';
import { notifyError } from '../utils/notification';

type DocumentGroup = GetDocumentGroupResponse;

const documentGroupAtom = atomFamily<DocumentGroup | null, string>({
  default: null,
  key: 'documentGroup',
});

const documentGroupForbiddenAtom = atomFamily<boolean, string>({
  default: false,
  key: 'documentGroupForbiddenAtom',
});

export default function useDocumentGroupStore(groupId: string) {
  const [documentGroup, setDocumentGroup] = useRecoilState(documentGroupAtom(groupId));
  const [forbidden, setForbidden] = useRecoilState(documentGroupForbiddenAtom(groupId));

  const resetDocument = useResetRecoilState(documentGroupAtom(groupId));
  const resetForbidden = useResetRecoilState(documentGroupForbiddenAtom(groupId));

  const fetchDocumentGroup = useCallback(async () => {
    try {
      const data = await getDocumentGroupById(groupId);
      setDocumentGroup(data);
      setForbidden(false);
    } catch (e: unknown) {
      if (axios.isAxiosError(e) && e.response?.status == 403) {
        notifyError('閲覧権限を持っていません。');
        setForbidden(true);
        return null;
      }
    }
  }, [groupId, setDocumentGroup, setForbidden]);

  useUnmount(() => {
    resetDocument();
    resetForbidden();
  });

  return useMemo(
    () => ({
      documentGroup,
      forbidden,
      failedFileCount: documentGroup?.errors_source_bucket_keys.length ?? -1,
      succeededFileCount: documentGroup?.completed_source_bucket_keys.length ?? -1,
      executedUserId: documentGroup?.user_id ?? '',
      actions: {
        fetchDocumentGroup,
      },
    }),
    [documentGroup, fetchDocumentGroup, forbidden]
  );
}

async function getDocumentGroupById(groupId: string): Promise<GetDocumentGroupResponse> {
  const { data: documentGroup } = await documentApi.documentControllerGetDocumentGroupById(groupId);

  return documentGroup;
}
