import { Redirect } from 'react-router-dom';
import { useRecoilValueLoadable } from 'recoil';

import AuthPageContainer from '../../components/Container/AuthPageContainer';
import LoadingScreen from '../../components/Loading/LoadingScreen';
import { meSelector } from '../../store/useMeStore';
import NotYetAgreed from './NotAgreed';
import TermsAgree from './TermsAgreePage';

export default function TermsPage() {
  const meLoadable = useRecoilValueLoadable(meSelector);

  if (meLoadable.state === 'loading') {
    return <LoadingScreen />;
  }

  if (meLoadable.state === 'hasError') {
    return <Redirect to="/login" />;
  }

  const me = meLoadable.contents;

  if (me.agreement) {
    return <Redirect to="/" />;
  }

  return <AuthPageContainer>{me.is_representative ? <TermsAgree /> : <NotYetAgreed />}</AuthPageContainer>;
}
