import axios from 'axios';

export default function getErrorBrief(error: unknown): string {
  if (axios.isAxiosError(error)) {
    if (error.response) {
      return error.response.data.message ?? 'エラーが発生しました。';
    }
    return error.message;
  } else if (error instanceof Error) {
    return error.message;
  } else {
    return 'エラーが発生しました。';
  }
}
