/* tslint:disable */
/* eslint-disable */
/**
 * OCRSOL API
 * OCRSOL API description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @enum {string}
 */

export const AuthError = {
  UserNotFound: 'USER_NOT_FOUND',
  CompanyInactivated: 'COMPANY_INACTIVATED',
} as const;

export type AuthError = typeof AuthError[keyof typeof AuthError];

/**
 *
 * @export
 * @interface AuthLoginRequest
 */
export interface AuthLoginRequest {
  /**
   *
   * @type {string}
   * @memberof AuthLoginRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof AuthLoginRequest
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof AuthLoginRequest
   */
  token: string;
}
/**
 *
 * @export
 * @interface AuthLoginResponse
 */
export interface AuthLoginResponse {
  /**
   *
   * @type {string}
   * @memberof AuthLoginResponse
   */
  access_token: string;
}
/**
 *
 * @export
 * @interface ChangeFileNameRequest
 */
export interface ChangeFileNameRequest {
  /**
   *
   * @type {string}
   * @memberof ChangeFileNameRequest
   */
  fileName: string;
  /**
   *
   * @type {string}
   * @memberof ChangeFileNameRequest
   */
  s3FilePath: string;
}
/**
 *
 * @export
 * @interface ChangeRetentionPeriodRequest
 */
export interface ChangeRetentionPeriodRequest {
  /**
   *
   * @type {number}
   * @memberof ChangeRetentionPeriodRequest
   */
  period: number;
}
/**
 *
 * @export
 * @interface Company
 */
export interface Company {
  /**
   *
   * @type {string}
   * @memberof Company
   */
  agreementDate?: string;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  company_id: string;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  company_name: string;
  /**
   *
   * @type {number}
   * @memberof Company
   */
  data_retention_period?: number;
  /**
   *
   * @type {boolean}
   * @memberof Company
   */
  enable_training?: boolean;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  inactive_from?: string;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  representative_user_id: string;
}
/**
 *
 * @export
 * @interface CompanyUsage
 */
export interface CompanyUsage {
  /**
   *
   * @type {number}
   * @memberof CompanyUsage
   */
  bill: number;
  /**
   *
   * @type {number}
   * @memberof CompanyUsage
   */
  bill_with_tax: number;
  /**
   *
   * @type {string}
   * @memberof CompanyUsage
   */
  company_id: string;
  /**
   * 利用料金に含めないページ数 料金計算に使う情報は usage_stats_ocr_type がマスタとなっているので二重管理になりそうに見えるが、 company_usage の集計は月ごとに行われ、また無料条件は変わる可能性が高いので、 履歴として持つ必要がある。
   * @type {number}
   * @memberof CompanyUsage
   */
  free_usage: number;
  /**
   *
   * @type {string}
   * @memberof CompanyUsage
   */
  month: string;
  /**
   *
   * @type {number}
   * @memberof CompanyUsage
   */
  usage: number;
}
/**
 *
 * @export
 * @interface CreateOrUpdateAccountingCategoryRequest
 */
export interface CreateOrUpdateAccountingCategoryRequest {
  /**
   *
   * @type {string}
   * @memberof CreateOrUpdateAccountingCategoryRequest
   */
  account: string;
  /**
   *
   * @type {string}
   * @memberof CreateOrUpdateAccountingCategoryRequest
   */
  account_code?: string;
}
/**
 *
 * @export
 * @interface CreateUserRequest
 */
export interface CreateUserRequest {
  /**
   *
   * @type {string}
   * @memberof CreateUserRequest
   */
  company_id: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserRequest
   */
  company_name: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserRequest
   */
  name: string;
  /**
   *
   * @type {UserRole}
   * @memberof CreateUserRequest
   */
  role: UserRole;
}
/**
 *
 * @export
 * @interface CreateUserResponse
 */
export interface CreateUserResponse {
  /**
   *
   * @type {string}
   * @memberof CreateUserResponse
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserResponse
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserResponse
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserResponse
   */
  user_id: string;
}
/**
 *
 * @export
 * @interface Document
 */
export interface Document {
  /**
   *
   * @type {string}
   * @memberof Document
   */
  company_id: string;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  document_id: string;
  /**
   *
   * @type {boolean}
   * @memberof Document
   */
  edited: boolean;
  /**
   *
   * @type {DocumentOcrErrorEntity}
   * @memberof Document
   */
  error?: DocumentOcrErrorEntity;
  /**
   *
   * @type {boolean}
   * @memberof Document
   */
  fully_read: boolean;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  group_id: string;
  /**
   *
   * @type {DocumentMedicalBillEntity | DocumentFurusatoTaxEntity | DocumentBankBookEntity | DocumentCardStatementEntity | DocumentGeneralTableEntity | DocumentReceiptEntity | DocumentPaymentRecordEntity | DocumentDualHorizontalReceiptEntity | DocumentDualVerticalReceiptEntity | DocumentWithholdingSlipEntity | DocumentInvoiceEntity | DocumentOcrErrorEntity}
   * @memberof Document
   */
  latest_revision:
    | DocumentMedicalBillEntity
    | DocumentFurusatoTaxEntity
    | DocumentBankBookEntity
    | DocumentCardStatementEntity
    | DocumentGeneralTableEntity
    | DocumentReceiptEntity
    | DocumentPaymentRecordEntity
    | DocumentDualHorizontalReceiptEntity
    | DocumentDualVerticalReceiptEntity
    | DocumentWithholdingSlipEntity
    | DocumentInvoiceEntity
    | DocumentOcrErrorEntity;
  /**
   *
   * @type {DocumentMedicalBillEntity | DocumentFurusatoTaxEntity | DocumentBankBookEntity | DocumentCardStatementEntity | DocumentGeneralTableEntity | DocumentReceiptEntity | DocumentPaymentRecordEntity | DocumentDualHorizontalReceiptEntity | DocumentDualVerticalReceiptEntity | DocumentWithholdingSlipEntity | DocumentInvoiceEntity | DocumentOcrErrorEntity}
   * @memberof Document
   */
  ocr_result:
    | DocumentMedicalBillEntity
    | DocumentFurusatoTaxEntity
    | DocumentBankBookEntity
    | DocumentCardStatementEntity
    | DocumentGeneralTableEntity
    | DocumentReceiptEntity
    | DocumentPaymentRecordEntity
    | DocumentDualHorizontalReceiptEntity
    | DocumentDualVerticalReceiptEntity
    | DocumentWithholdingSlipEntity
    | DocumentInvoiceEntity
    | DocumentOcrErrorEntity;
  /**
   *
   * @type {DocumentType}
   * @memberof Document
   */
  ocr_type: DocumentType;
  /**
   *
   * @type {number}
   * @memberof Document
   */
  page?: number;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  s3_path?: string;
  /**
   *
   * @type {boolean}
   * @memberof Document
   */
  seen?: boolean;
  /**
   *
   * @type {number}
   * @memberof Document
   */
  total_page?: number;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  user_file_path: string;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  user_id: string;
}
/**
 *
 * @export
 * @interface DocumentBankBookEntity
 */
export interface DocumentBankBookEntity {
  /**
   *
   * @type {Array<string>}
   * @memberof DocumentBankBookEntity
   */
  balance_calculation_result?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof DocumentBankBookEntity
   */
  columnIds?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof DocumentBankBookEntity
   */
  column_names?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof DocumentBankBookEntity
   */
  rowIds?: Array<string>;
  /**
   *
   * @type {Array<Array<string>>}
   * @memberof DocumentBankBookEntity
   */
  rows?: Array<Array<string>>;
  /**
   *
   * @type {string}
   * @memberof DocumentBankBookEntity
   */
  type: DocumentBankBookEntityTypeEnum;
}

export const DocumentBankBookEntityTypeEnum = {
  Bankbook: 'bankbook',
} as const;

export type DocumentBankBookEntityTypeEnum =
  typeof DocumentBankBookEntityTypeEnum[keyof typeof DocumentBankBookEntityTypeEnum];

/**
 *
 * @export
 * @interface DocumentCardStatementEntity
 */
export interface DocumentCardStatementEntity {
  /**
   *
   * @type {Array<string>}
   * @memberof DocumentCardStatementEntity
   */
  columnIds?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof DocumentCardStatementEntity
   */
  column_names?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof DocumentCardStatementEntity
   */
  rowIds?: Array<string>;
  /**
   *
   * @type {Array<Array<string>>}
   * @memberof DocumentCardStatementEntity
   */
  rows?: Array<Array<string>>;
  /**
   *
   * @type {string}
   * @memberof DocumentCardStatementEntity
   */
  type: DocumentCardStatementEntityTypeEnum;
}

export const DocumentCardStatementEntityTypeEnum = {
  CardStatement: 'card_statement',
} as const;

export type DocumentCardStatementEntityTypeEnum =
  typeof DocumentCardStatementEntityTypeEnum[keyof typeof DocumentCardStatementEntityTypeEnum];

/**
 *
 * @export
 * @interface DocumentDualHorizontalReceiptEntity
 */
export interface DocumentDualHorizontalReceiptEntity {
  /**
   *
   * @type {Array<DocumentReceiptEntity>}
   * @memberof DocumentDualHorizontalReceiptEntity
   */
  receipts: Array<DocumentReceiptEntity>;
  /**
   *
   * @type {string}
   * @memberof DocumentDualHorizontalReceiptEntity
   */
  type: DocumentDualHorizontalReceiptEntityTypeEnum;
}

export const DocumentDualHorizontalReceiptEntityTypeEnum = {
  DualHorizontalReceipt: 'dual_horizontal_receipt',
} as const;

export type DocumentDualHorizontalReceiptEntityTypeEnum =
  typeof DocumentDualHorizontalReceiptEntityTypeEnum[keyof typeof DocumentDualHorizontalReceiptEntityTypeEnum];

/**
 *
 * @export
 * @interface DocumentDualVerticalReceiptEntity
 */
export interface DocumentDualVerticalReceiptEntity {
  /**
   *
   * @type {Array<DocumentReceiptEntity>}
   * @memberof DocumentDualVerticalReceiptEntity
   */
  receipts: Array<DocumentReceiptEntity>;
  /**
   *
   * @type {string}
   * @memberof DocumentDualVerticalReceiptEntity
   */
  type: DocumentDualVerticalReceiptEntityTypeEnum;
}

export const DocumentDualVerticalReceiptEntityTypeEnum = {
  DualVerticalReceipt: 'dual_vertical_receipt',
} as const;

export type DocumentDualVerticalReceiptEntityTypeEnum =
  typeof DocumentDualVerticalReceiptEntityTypeEnum[keyof typeof DocumentDualVerticalReceiptEntityTypeEnum];

/**
 *
 * @export
 * @interface DocumentFurusatoTaxEntity
 */
export interface DocumentFurusatoTaxEntity {
  /**
   *
   * @type {string}
   * @memberof DocumentFurusatoTaxEntity
   */
  address?: string;
  /**
   *
   * @type {number}
   * @memberof DocumentFurusatoTaxEntity
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof DocumentFurusatoTaxEntity
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentFurusatoTaxEntity
   */
  payment_day?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentFurusatoTaxEntity
   */
  prefecture?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentFurusatoTaxEntity
   */
  type: DocumentFurusatoTaxEntityTypeEnum;
}

export const DocumentFurusatoTaxEntityTypeEnum = {
  FurusatoTax: 'furusato_tax',
} as const;

export type DocumentFurusatoTaxEntityTypeEnum =
  typeof DocumentFurusatoTaxEntityTypeEnum[keyof typeof DocumentFurusatoTaxEntityTypeEnum];

/**
 *
 * @export
 * @interface DocumentGeneralTableEntity
 */
export interface DocumentGeneralTableEntity {
  /**
   *
   * @type {Array<Array<Array<string>>>}
   * @memberof DocumentGeneralTableEntity
   */
  tables: Array<Array<Array<string>>>;
  /**
   *
   * @type {string}
   * @memberof DocumentGeneralTableEntity
   */
  type: DocumentGeneralTableEntityTypeEnum;
}

export const DocumentGeneralTableEntityTypeEnum = {
  GeneralTable: 'general_table',
} as const;

export type DocumentGeneralTableEntityTypeEnum =
  typeof DocumentGeneralTableEntityTypeEnum[keyof typeof DocumentGeneralTableEntityTypeEnum];

/**
 *
 * @export
 * @interface DocumentInvoiceEntity
 */
export interface DocumentInvoiceEntity {
  /**
   *
   * @type {string}
   * @memberof DocumentInvoiceEntity
   */
  biller_company_name?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentInvoiceEntity
   */
  biller_company_number?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentInvoiceEntity
   */
  billing_company_name?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentInvoiceEntity
   */
  invoice_date?: string;
  /**
   *
   * @type {Array<InvoiceDetail>}
   * @memberof DocumentInvoiceEntity
   */
  invoice_details?: Array<InvoiceDetail>;
  /**
   *
   * @type {Array<Tax>}
   * @memberof DocumentInvoiceEntity
   */
  tax?: Array<Tax>;
  /**
   *
   * @type {number}
   * @memberof DocumentInvoiceEntity
   */
  total_invoice_amount?: number;
  /**
   *
   * @type {Array<TransferDestination>}
   * @memberof DocumentInvoiceEntity
   */
  transfer_destinations?: Array<TransferDestination>;
  /**
   *
   * @type {string}
   * @memberof DocumentInvoiceEntity
   */
  type: DocumentInvoiceEntityTypeEnum;
}

export const DocumentInvoiceEntityTypeEnum = {
  Invoice: 'invoice',
} as const;

export type DocumentInvoiceEntityTypeEnum =
  typeof DocumentInvoiceEntityTypeEnum[keyof typeof DocumentInvoiceEntityTypeEnum];

/**
 *
 * @export
 * @interface DocumentMedicalBillEntity
 */
export interface DocumentMedicalBillEntity {
  /**
   *
   * @type {string}
   * @memberof DocumentMedicalBillEntity
   */
  address?: string;
  /**
   *
   * @type {number}
   * @memberof DocumentMedicalBillEntity
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof DocumentMedicalBillEntity
   */
  date?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentMedicalBillEntity
   */
  hospital_name?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentMedicalBillEntity
   */
  patient_name?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentMedicalBillEntity
   */
  type: DocumentMedicalBillEntityTypeEnum;
}

export const DocumentMedicalBillEntityTypeEnum = {
  MedicalBill: 'medical_bill',
} as const;

export type DocumentMedicalBillEntityTypeEnum =
  typeof DocumentMedicalBillEntityTypeEnum[keyof typeof DocumentMedicalBillEntityTypeEnum];

/**
 *
 * @export
 * @interface DocumentOcrErrorEntity
 */
export interface DocumentOcrErrorEntity {
  /**
   *
   * @type {string}
   * @memberof DocumentOcrErrorEntity
   */
  error_message?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentOcrErrorEntity
   */
  type: DocumentOcrErrorEntityTypeEnum;
}

export const DocumentOcrErrorEntityTypeEnum = {
  OcrError: 'ocr_error',
} as const;

export type DocumentOcrErrorEntityTypeEnum =
  typeof DocumentOcrErrorEntityTypeEnum[keyof typeof DocumentOcrErrorEntityTypeEnum];

/**
 *
 * @export
 * @interface DocumentPaymentRecordEntity
 */
export interface DocumentPaymentRecordEntity {
  /**
   *
   * @type {string}
   * @memberof DocumentPaymentRecordEntity
   */
  earner_address?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentPaymentRecordEntity
   */
  earner_individual_or_corporate_number?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentPaymentRecordEntity
   */
  earner_name?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentPaymentRecordEntity
   */
  payer_address?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentPaymentRecordEntity
   */
  payer_individual_or_corporate_number?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentPaymentRecordEntity
   */
  payer_name?: string;
  /**
   *
   * @type {Array<Payment>}
   * @memberof DocumentPaymentRecordEntity
   */
  payments?: Array<Payment>;
  /**
   *
   * @type {number}
   * @memberof DocumentPaymentRecordEntity
   */
  total_amount?: number;
  /**
   *
   * @type {string}
   * @memberof DocumentPaymentRecordEntity
   */
  type: DocumentPaymentRecordEntityTypeEnum;
}

export const DocumentPaymentRecordEntityTypeEnum = {
  PaymentRecord: 'payment_record',
} as const;

export type DocumentPaymentRecordEntityTypeEnum =
  typeof DocumentPaymentRecordEntityTypeEnum[keyof typeof DocumentPaymentRecordEntityTypeEnum];

/**
 *
 * @export
 * @interface DocumentReceiptEntity
 */
export interface DocumentReceiptEntity {
  /**
   *
   * @type {string}
   * @memberof DocumentReceiptEntity
   */
  business_number?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DocumentReceiptEntity
   */
  payee?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof DocumentReceiptEntity
   */
  payment_date?: string;
  /**
   *
   * @type {Array<ReceiptDetail>}
   * @memberof DocumentReceiptEntity
   */
  receipt_details?: Array<ReceiptDetail>;
  /**
   *
   * @type {string}
   * @memberof DocumentReceiptEntity
   */
  type: DocumentReceiptEntityTypeEnum;
}

export const DocumentReceiptEntityTypeEnum = {
  Receipt: 'receipt',
} as const;

export type DocumentReceiptEntityTypeEnum =
  typeof DocumentReceiptEntityTypeEnum[keyof typeof DocumentReceiptEntityTypeEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const DocumentType = {
  MedicalBill: 'medical_bill',
  FurusatoTax: 'furusato_tax',
  Bankbook: 'bankbook',
  CardStatement: 'card_statement',
  GeneralTable: 'general_table',
  Receipt: 'receipt',
  PaymentRecord: 'payment_record',
  DualHorizontalReceipt: 'dual_horizontal_receipt',
  DualVerticalReceipt: 'dual_vertical_receipt',
  WithholdingSlip: 'withholding_slip',
  Invoice: 'invoice',
  OcrError: 'ocr_error',
} as const;

export type DocumentType = typeof DocumentType[keyof typeof DocumentType];

/**
 *
 * @export
 * @interface DocumentWithholdingSlipEntity
 */
export interface DocumentWithholdingSlipEntity {
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  care_insurance?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  date_of_birth?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  earner_address?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  earner_name?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  earner_number?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  earthquake_insurance_deduction?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  employer_address?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  employer_name?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  employer_number?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  employer_phone?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  house_loan_deduction?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  income_type?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  life_insurance_deduction?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  new_life_insurance?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  new_personal_pension_insurance?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  old_life_insurance?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  old_personal_pension_insurance?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  other?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  payment_amount?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  social_insurance?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  spouse_deduction?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  spouse_income?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  type: DocumentWithholdingSlipEntityTypeEnum;
  /**
   *
   * @type {string}
   * @memberof DocumentWithholdingSlipEntity
   */
  withholding_amount?: string;
}

export const DocumentWithholdingSlipEntityTypeEnum = {
  WithholdingSlip: 'withholding_slip',
} as const;

export type DocumentWithholdingSlipEntityTypeEnum =
  typeof DocumentWithholdingSlipEntityTypeEnum[keyof typeof DocumentWithholdingSlipEntityTypeEnum];

/**
 *
 * @export
 * @interface EditUserRequest
 */
export interface EditUserRequest {
  /**
   *
   * @type {string}
   * @memberof EditUserRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof EditUserRequest
   */
  name: string;
  /**
   *
   * @type {UserRole}
   * @memberof EditUserRequest
   */
  role: UserRole;
}
/**
 *
 * @export
 * @interface Fee
 */
export interface Fee {
  /**
   *
   * @type {number}
   * @memberof Fee
   */
  base: number;
  /**
   *
   * @type {number}
   * @memberof Fee
   */
  minimum_page: number;
  /**
   *
   * @type {number}
   * @memberof Fee
   */
  rate: number;
}
/**
 *
 * @export
 * @interface GetCompanyRepresentativeUserResponse
 */
export interface GetCompanyRepresentativeUserResponse {
  /**
   *
   * @type {string}
   * @memberof GetCompanyRepresentativeUserResponse
   */
  representative_user_id: string;
  /**
   *
   * @type {string}
   * @memberof GetCompanyRepresentativeUserResponse
   */
  representative_user_name: string;
}
/**
 *
 * @export
 * @interface GetDividedImagePresignedUrlResponse
 */
export interface GetDividedImagePresignedUrlResponse {
  /**
   *
   * @type {string}
   * @memberof GetDividedImagePresignedUrlResponse
   */
  image_url: string;
}
/**
 *
 * @export
 * @interface GetDocumentGroupResponse
 */
export interface GetDocumentGroupResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof GetDocumentGroupResponse
   */
  completed_source_bucket_keys: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof GetDocumentGroupResponse
   */
  errors_source_bucket_keys: Array<string>;
  /**
   *
   * @type {number}
   * @memberof GetDocumentGroupResponse
   */
  files_count: number;
  /**
   *
   * @type {string}
   * @memberof GetDocumentGroupResponse
   */
  group_id: string;
  /**
   *
   * @type {DocumentType}
   * @memberof GetDocumentGroupResponse
   */
  ocr_type: DocumentType;
  /**
   *
   * @type {string}
   * @memberof GetDocumentGroupResponse
   */
  updated_at: string;
  /**
   *
   * @type {string}
   * @memberof GetDocumentGroupResponse
   */
  uploaded_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetDocumentGroupResponse
   */
  user_id: string;
}
/**
 *
 * @export
 * @interface GetMeResponse
 */
export interface GetMeResponse {
  /**
   *
   * @type {boolean}
   * @memberof GetMeResponse
   */
  agreement: boolean;
  /**
   *
   * @type {string}
   * @memberof GetMeResponse
   */
  company_name: string;
  /**
   *
   * @type {string}
   * @memberof GetMeResponse
   */
  email: string;
  /**
   *
   * @type {boolean}
   * @memberof GetMeResponse
   */
  is_representative: boolean;
  /**
   *
   * @type {string}
   * @memberof GetMeResponse
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof GetMeResponse
   */
  password_change_required: boolean;
  /**
   *
   * @type {UserRole}
   * @memberof GetMeResponse
   */
  role: UserRole;
  /**
   *
   * @type {string}
   * @memberof GetMeResponse
   */
  user_id: string;
}
/**
 *
 * @export
 * @interface GetPostFilePresignedUrlsResponse
 */
export interface GetPostFilePresignedUrlsResponse {
  /**
   *
   * @type {Array<GetPresignedUrlResponseFile>}
   * @memberof GetPostFilePresignedUrlsResponse
   */
  files: Array<GetPresignedUrlResponseFile>;
  /**
   *
   * @type {string}
   * @memberof GetPostFilePresignedUrlsResponse
   */
  group_id: string;
}
/**
 *
 * @export
 * @interface GetPresignedUrlRequest
 */
export interface GetPresignedUrlRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof GetPresignedUrlRequest
   */
  file_name_list: Array<string>;
  /**
   *
   * @type {DocumentType}
   * @memberof GetPresignedUrlRequest
   */
  type: DocumentType;
}
/**
 *
 * @export
 * @interface GetPresignedUrlResponseFile
 */
export interface GetPresignedUrlResponseFile {
  /**
   *
   * @type {string}
   * @memberof GetPresignedUrlResponseFile
   */
  file_name: string;
  /**
   *
   * @type {string}
   * @memberof GetPresignedUrlResponseFile
   */
  upload_url: string;
}
/**
 *
 * @export
 * @interface GetSearchResponse
 */
export interface GetSearchResponse {
  /**
   *
   * @type {string}
   * @memberof GetSearchResponse
   */
  deleted_at?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof GetSearchResponse
   */
  file_names: Array<string>;
  /**
   *
   * @type {string}
   * @memberof GetSearchResponse
   */
  group_id: string;
  /**
   *
   * @type {DocumentType}
   * @memberof GetSearchResponse
   */
  ocr_type: DocumentType;
  /**
   *
   * @type {Array<string>}
   * @memberof GetSearchResponse
   */
  seen_documents: Array<string>;
  /**
   *
   * @type {number}
   * @memberof GetSearchResponse
   */
  total_page: number;
  /**
   *
   * @type {string}
   * @memberof GetSearchResponse
   */
  uploaded_at: string | null;
  /**
   *
   * @type {string}
   * @memberof GetSearchResponse
   */
  user_id: string;
}
/**
 *
 * @export
 * @interface GetStatisticsPerFormResponse
 */
export interface GetStatisticsPerFormResponse {
  /**
   *
   * @type {Array<UsagePerForm>}
   * @memberof GetStatisticsPerFormResponse
   */
  statistics: Array<UsagePerForm>;
}
/**
 *
 * @export
 * @interface GetStatisticsPerUserResponse
 */
export interface GetStatisticsPerUserResponse {
  /**
   *
   * @type {Array<UsagePerUser>}
   * @memberof GetStatisticsPerUserResponse
   */
  statistics: Array<UsagePerUser>;
}
/**
 *
 * @export
 * @interface GetUsersResponse
 */
export interface GetUsersResponse {
  /**
   *
   * @type {{ [key: string]: User; }}
   * @memberof GetUsersResponse
   */
  users: { [key: string]: User };
}
/**
 *
 * @export
 * @interface InvoiceDetail
 */
export interface InvoiceDetail {
  /**
   *
   * @type {string}
   * @memberof InvoiceDetail
   */
  account?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceDetail
   */
  account_code?: string;
  /**
   *
   * @type {number}
   * @memberof InvoiceDetail
   */
  amount_with_tax?: number;
  /**
   *
   * @type {number}
   * @memberof InvoiceDetail
   */
  amount_without_tax?: number;
  /**
   *
   * @type {number}
   * @memberof InvoiceDetail
   */
  tax_amount?: number;
  /**
   *
   * @type {number}
   * @memberof InvoiceDetail
   */
  tax_percent?: number;
}
/**
 *
 * @export
 * @interface LoginErrorResponse
 */
export interface LoginErrorResponse {
  /**
   *
   * @type {string}
   * @memberof LoginErrorResponse
   */
  message: string;
  /**
   *
   * @type {AuthError}
   * @memberof LoginErrorResponse
   */
  reason: AuthError;
}
/**
 *
 * @export
 * @interface PasswordChangeMailRequest
 */
export interface PasswordChangeMailRequest {
  /**
   *
   * @type {string}
   * @memberof PasswordChangeMailRequest
   */
  email: string;
}
/**
 *
 * @export
 * @interface PasswordChangeRequest
 */
export interface PasswordChangeRequest {
  /**
   *
   * @type {string}
   * @memberof PasswordChangeRequest
   */
  password: string;
}
/**
 *
 * @export
 * @interface PasswordChangeSignatureRequest
 */
export interface PasswordChangeSignatureRequest {
  /**
   *
   * @type {number}
   * @memberof PasswordChangeSignatureRequest
   */
  expire: number;
  /**
   *
   * @type {string}
   * @memberof PasswordChangeSignatureRequest
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof PasswordChangeSignatureRequest
   */
  signature: string;
  /**
   *
   * @type {string}
   * @memberof PasswordChangeSignatureRequest
   */
  token: string;
  /**
   *
   * @type {string}
   * @memberof PasswordChangeSignatureRequest
   */
  user_id: string;
}
/**
 *
 * @export
 * @interface PatchDocumentRequest
 */
export interface PatchDocumentRequest {
  /**
   *
   * @type {DocumentMedicalBillEntity | DocumentFurusatoTaxEntity | DocumentBankBookEntity | DocumentCardStatementEntity | DocumentGeneralTableEntity | DocumentReceiptEntity | DocumentPaymentRecordEntity | DocumentDualHorizontalReceiptEntity | DocumentDualVerticalReceiptEntity | DocumentWithholdingSlipEntity | DocumentInvoiceEntity | DocumentOcrErrorEntity}
   * @memberof PatchDocumentRequest
   */
  edited_ocr_result:
    | DocumentMedicalBillEntity
    | DocumentFurusatoTaxEntity
    | DocumentBankBookEntity
    | DocumentCardStatementEntity
    | DocumentGeneralTableEntity
    | DocumentReceiptEntity
    | DocumentPaymentRecordEntity
    | DocumentDualHorizontalReceiptEntity
    | DocumentDualVerticalReceiptEntity
    | DocumentWithholdingSlipEntity
    | DocumentInvoiceEntity
    | DocumentOcrErrorEntity;
}
/**
 *
 * @export
 * @interface Payment
 */
export interface Payment {
  /**
   *
   * @type {string}
   * @memberof Payment
   */
  classification?: string;
  /**
   *
   * @type {string}
   * @memberof Payment
   */
  detail?: string;
  /**
   *
   * @type {number}
   * @memberof Payment
   */
  payment_amount?: number;
  /**
   *
   * @type {number}
   * @memberof Payment
   */
  withholding_tax_amount?: number;
}
/**
 *
 * @export
 * @interface Plan
 */
export interface Plan {
  /**
   *
   * @type {Array<Fee>}
   * @memberof Plan
   */
  fee: Array<Fee>;
  /**
   *
   * @type {string}
   * @memberof Plan
   */
  plan_id: string;
  /**
   *
   * @type {string}
   * @memberof Plan
   */
  plan_name: string;
  /**
   *
   * @type {string}
   * @memberof Plan
   */
  plan_start_time: string;
  /**
   *
   * @type {number}
   * @memberof Plan
   */
  tax_percent: number;
}
/**
 *
 * @export
 * @interface PredictAccountingCategoryRequest
 */
export interface PredictAccountingCategoryRequest {
  /**
   *
   * @type {string}
   * @memberof PredictAccountingCategoryRequest
   */
  payee: string;
}
/**
 *
 * @export
 * @interface PredictAccountingCategoryResponse
 */
export interface PredictAccountingCategoryResponse {
  /**
   *
   * @type {string}
   * @memberof PredictAccountingCategoryResponse
   */
  account?: string;
}
/**
 *
 * @export
 * @interface ReceiptDetail
 */
export interface ReceiptDetail {
  /**
   *
   * @type {string}
   * @memberof ReceiptDetail
   */
  account?: string;
  /**
   *
   * @type {string}
   * @memberof ReceiptDetail
   */
  account_code?: string;
  /**
   *
   * @type {number}
   * @memberof ReceiptDetail
   */
  amount_with_tax?: number;
  /**
   *
   * @type {number}
   * @memberof ReceiptDetail
   */
  amount_without_tax?: number;
  /**
   *
   * @type {number}
   * @memberof ReceiptDetail
   */
  tax_amount?: number;
  /**
   *
   * @type {number}
   * @memberof ReceiptDetail
   */
  tax_percent?: number;
  /**
   *
   * @type {string}
   * @memberof ReceiptDetail
   */
  transaction_content?: string;
}
/**
 *
 * @export
 * @interface Tax
 */
export interface Tax {
  /**
   *
   * @type {number}
   * @memberof Tax
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof Tax
   */
  percent?: number;
}
/**
 *
 * @export
 * @interface TransferDestination
 */
export interface TransferDestination {
  /**
   *
   * @type {string}
   * @memberof TransferDestination
   */
  account_holder?: string;
  /**
   *
   * @type {string}
   * @memberof TransferDestination
   */
  account_number?: string;
  /**
   *
   * @type {string}
   * @memberof TransferDestination
   */
  account_type?: string;
  /**
   *
   * @type {string}
   * @memberof TransferDestination
   */
  bank_name?: string;
  /**
   *
   * @type {string}
   * @memberof TransferDestination
   */
  branch_name?: string;
}
/**
 *
 * @export
 * @interface UsagePerForm
 */
export interface UsagePerForm {
  /**
   *
   * @type {string}
   * @memberof UsagePerForm
   */
  month: string;
  /**
   *
   * @type {DocumentType}
   * @memberof UsagePerForm
   */
  ocr_type: DocumentType;
  /**
   *
   * @type {number}
   * @memberof UsagePerForm
   */
  usage: number;
}
/**
 *
 * @export
 * @interface UsagePerUser
 */
export interface UsagePerUser {
  /**
   *
   * @type {string}
   * @memberof UsagePerUser
   */
  month: string;
  /**
   *
   * @type {number}
   * @memberof UsagePerUser
   */
  usage: number;
  /**
   *
   * @type {string}
   * @memberof UsagePerUser
   */
  user_name: string;
}
/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {string}
   * @memberof User
   */
  company_id: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  company_name: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  password_change_required: boolean;
  /**
   *
   * @type {string}
   * @memberof User
   */
  role: UserRoleEnum;
  /**
   *
   * @type {string}
   * @memberof User
   */
  user_id: string;
}

export const UserRoleEnum = {
  Admin: 'admin',
  Member: 'member',
} as const;

export type UserRoleEnum = typeof UserRoleEnum[keyof typeof UserRoleEnum];

/**
 *
 * @export
 * @interface UserAccountingCategories
 */
export interface UserAccountingCategories {
  /**
   *
   * @type {string}
   * @memberof UserAccountingCategories
   */
  account: string;
  /**
   *
   * @type {string}
   * @memberof UserAccountingCategories
   */
  account_code?: string;
  /**
   *
   * @type {string}
   * @memberof UserAccountingCategories
   */
  account_id: string;
  /**
   *
   * @type {string}
   * @memberof UserAccountingCategories
   */
  company_id: string;
  /**
   *
   * @type {string}
   * @memberof UserAccountingCategories
   */
  created_at: string;
  /**
   *
   * @type {boolean}
   * @memberof UserAccountingCategories
   */
  in_used: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */

export const UserRole = {
  Admin: 'admin',
  Member: 'member',
} as const;

export type UserRole = typeof UserRole[keyof typeof UserRole];

/**
 * AccountingCategoriesApi - axios parameter creator
 * @export
 */
export const AccountingCategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CreateOrUpdateAccountingCategoryRequest} createOrUpdateAccountingCategoryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountingCategoriesControllerCreateOrUpdateAccountingCategory: async (
      createOrUpdateAccountingCategoryRequest: CreateOrUpdateAccountingCategoryRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createOrUpdateAccountingCategoryRequest' is not null or undefined
      assertParamExists(
        'accountingCategoriesControllerCreateOrUpdateAccountingCategory',
        'createOrUpdateAccountingCategoryRequest',
        createOrUpdateAccountingCategoryRequest
      );
      const localVarPath = `/accounting/categories`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createOrUpdateAccountingCategoryRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountingCategoriesControllerDeleteAccountingCategory: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('accountingCategoriesControllerDeleteAccountingCategory', 'id', id);
      const localVarPath = `/accounting/categories/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountingCategoriesControllerGetAccountingCategories: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/accounting/categories/self`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountingCategoriesControllerTurnOffAccountingCategory: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('accountingCategoriesControllerTurnOffAccountingCategory', 'id', id);
      const localVarPath = `/accounting/categories/{id}/turnoff`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountingCategoriesControllerTurnOnAccountingCategory: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('accountingCategoriesControllerTurnOnAccountingCategory', 'id', id);
      const localVarPath = `/accounting/categories/{id}/turnon`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AccountingCategoriesApi - functional programming interface
 * @export
 */
export const AccountingCategoriesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AccountingCategoriesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateOrUpdateAccountingCategoryRequest} createOrUpdateAccountingCategoryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async accountingCategoriesControllerCreateOrUpdateAccountingCategory(
      createOrUpdateAccountingCategoryRequest: CreateOrUpdateAccountingCategoryRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.accountingCategoriesControllerCreateOrUpdateAccountingCategory(
          createOrUpdateAccountingCategoryRequest,
          options
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async accountingCategoriesControllerDeleteAccountingCategory(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.accountingCategoriesControllerDeleteAccountingCategory(
        id,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async accountingCategoriesControllerGetAccountingCategories(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserAccountingCategories>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.accountingCategoriesControllerGetAccountingCategories(
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async accountingCategoriesControllerTurnOffAccountingCategory(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.accountingCategoriesControllerTurnOffAccountingCategory(
        id,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async accountingCategoriesControllerTurnOnAccountingCategory(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.accountingCategoriesControllerTurnOnAccountingCategory(
        id,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AccountingCategoriesApi - factory interface
 * @export
 */
export const AccountingCategoriesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AccountingCategoriesApiFp(configuration);
  return {
    /**
     *
     * @param {CreateOrUpdateAccountingCategoryRequest} createOrUpdateAccountingCategoryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountingCategoriesControllerCreateOrUpdateAccountingCategory(
      createOrUpdateAccountingCategoryRequest: CreateOrUpdateAccountingCategoryRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .accountingCategoriesControllerCreateOrUpdateAccountingCategory(
          createOrUpdateAccountingCategoryRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountingCategoriesControllerDeleteAccountingCategory(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .accountingCategoriesControllerDeleteAccountingCategory(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountingCategoriesControllerGetAccountingCategories(
      options?: any
    ): AxiosPromise<Array<UserAccountingCategories>> {
      return localVarFp
        .accountingCategoriesControllerGetAccountingCategories(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountingCategoriesControllerTurnOffAccountingCategory(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .accountingCategoriesControllerTurnOffAccountingCategory(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountingCategoriesControllerTurnOnAccountingCategory(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .accountingCategoriesControllerTurnOnAccountingCategory(id, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AccountingCategoriesApi - object-oriented interface
 * @export
 * @class AccountingCategoriesApi
 * @extends {BaseAPI}
 */
export class AccountingCategoriesApi extends BaseAPI {
  /**
   *
   * @param {CreateOrUpdateAccountingCategoryRequest} createOrUpdateAccountingCategoryRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountingCategoriesApi
   */
  public accountingCategoriesControllerCreateOrUpdateAccountingCategory(
    createOrUpdateAccountingCategoryRequest: CreateOrUpdateAccountingCategoryRequest,
    options?: AxiosRequestConfig
  ) {
    return AccountingCategoriesApiFp(this.configuration)
      .accountingCategoriesControllerCreateOrUpdateAccountingCategory(createOrUpdateAccountingCategoryRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountingCategoriesApi
   */
  public accountingCategoriesControllerDeleteAccountingCategory(id: string, options?: AxiosRequestConfig) {
    return AccountingCategoriesApiFp(this.configuration)
      .accountingCategoriesControllerDeleteAccountingCategory(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountingCategoriesApi
   */
  public accountingCategoriesControllerGetAccountingCategories(options?: AxiosRequestConfig) {
    return AccountingCategoriesApiFp(this.configuration)
      .accountingCategoriesControllerGetAccountingCategories(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountingCategoriesApi
   */
  public accountingCategoriesControllerTurnOffAccountingCategory(id: string, options?: AxiosRequestConfig) {
    return AccountingCategoriesApiFp(this.configuration)
      .accountingCategoriesControllerTurnOffAccountingCategory(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountingCategoriesApi
   */
  public accountingCategoriesControllerTurnOnAccountingCategory(id: string, options?: AxiosRequestConfig) {
    return AccountingCategoriesApiFp(this.configuration)
      .accountingCategoriesControllerTurnOnAccountingCategory(id, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AccountingCategoryCompletionApi - axios parameter creator
 * @export
 */
export const AccountingCategoryCompletionApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {PredictAccountingCategoryRequest} predictAccountingCategoryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountingCategoryCompletionControllerPredictAccountingCategory: async (
      predictAccountingCategoryRequest: PredictAccountingCategoryRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'predictAccountingCategoryRequest' is not null or undefined
      assertParamExists(
        'accountingCategoryCompletionControllerPredictAccountingCategory',
        'predictAccountingCategoryRequest',
        predictAccountingCategoryRequest
      );
      const localVarPath = `/accounting/categories/predict`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        predictAccountingCategoryRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AccountingCategoryCompletionApi - functional programming interface
 * @export
 */
export const AccountingCategoryCompletionApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AccountingCategoryCompletionApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {PredictAccountingCategoryRequest} predictAccountingCategoryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async accountingCategoryCompletionControllerPredictAccountingCategory(
      predictAccountingCategoryRequest: PredictAccountingCategoryRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PredictAccountingCategoryResponse>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.accountingCategoryCompletionControllerPredictAccountingCategory(
          predictAccountingCategoryRequest,
          options
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AccountingCategoryCompletionApi - factory interface
 * @export
 */
export const AccountingCategoryCompletionApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AccountingCategoryCompletionApiFp(configuration);
  return {
    /**
     *
     * @param {PredictAccountingCategoryRequest} predictAccountingCategoryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountingCategoryCompletionControllerPredictAccountingCategory(
      predictAccountingCategoryRequest: PredictAccountingCategoryRequest,
      options?: any
    ): AxiosPromise<PredictAccountingCategoryResponse> {
      return localVarFp
        .accountingCategoryCompletionControllerPredictAccountingCategory(predictAccountingCategoryRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AccountingCategoryCompletionApi - object-oriented interface
 * @export
 * @class AccountingCategoryCompletionApi
 * @extends {BaseAPI}
 */
export class AccountingCategoryCompletionApi extends BaseAPI {
  /**
   *
   * @param {PredictAccountingCategoryRequest} predictAccountingCategoryRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountingCategoryCompletionApi
   */
  public accountingCategoryCompletionControllerPredictAccountingCategory(
    predictAccountingCategoryRequest: PredictAccountingCategoryRequest,
    options?: AxiosRequestConfig
  ) {
    return AccountingCategoryCompletionApiFp(this.configuration)
      .accountingCategoryCompletionControllerPredictAccountingCategory(predictAccountingCategoryRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {PasswordChangeRequest} passwordChangeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerChangePassword: async (
      passwordChangeRequest: PasswordChangeRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'passwordChangeRequest' is not null or undefined
      assertParamExists('authControllerChangePassword', 'passwordChangeRequest', passwordChangeRequest);
      const localVarPath = `/auth/password/change`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(passwordChangeRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {PasswordChangeSignatureRequest} passwordChangeSignatureRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerChangePasswordWithSignature: async (
      passwordChangeSignatureRequest: PasswordChangeSignatureRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'passwordChangeSignatureRequest' is not null or undefined
      assertParamExists(
        'authControllerChangePasswordWithSignature',
        'passwordChangeSignatureRequest',
        passwordChangeSignatureRequest
      );
      const localVarPath = `/auth/password/change/signature`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        passwordChangeSignatureRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AuthLoginRequest} authLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerLogin: async (
      authLoginRequest: AuthLoginRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authLoginRequest' is not null or undefined
      assertParamExists('authControllerLogin', 'authLoginRequest', authLoginRequest);
      const localVarPath = `/auth/login`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(authLoginRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {PasswordChangeMailRequest} passwordChangeMailRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerSendPasswordChangeMail: async (
      passwordChangeMailRequest: PasswordChangeMailRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'passwordChangeMailRequest' is not null or undefined
      assertParamExists('authControllerSendPasswordChangeMail', 'passwordChangeMailRequest', passwordChangeMailRequest);
      const localVarPath = `/auth/password/send`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        passwordChangeMailRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {PasswordChangeRequest} passwordChangeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authControllerChangePassword(
      passwordChangeRequest: PasswordChangeRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerChangePassword(
        passwordChangeRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {PasswordChangeSignatureRequest} passwordChangeSignatureRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authControllerChangePasswordWithSignature(
      passwordChangeSignatureRequest: PasswordChangeSignatureRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerChangePasswordWithSignature(
        passwordChangeSignatureRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {AuthLoginRequest} authLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authControllerLogin(
      authLoginRequest: AuthLoginRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthLoginResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLogin(authLoginRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {PasswordChangeMailRequest} passwordChangeMailRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authControllerSendPasswordChangeMail(
      passwordChangeMailRequest: PasswordChangeMailRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerSendPasswordChangeMail(
        passwordChangeMailRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AuthApiFp(configuration);
  return {
    /**
     *
     * @param {PasswordChangeRequest} passwordChangeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerChangePassword(passwordChangeRequest: PasswordChangeRequest, options?: any): AxiosPromise<void> {
      return localVarFp
        .authControllerChangePassword(passwordChangeRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {PasswordChangeSignatureRequest} passwordChangeSignatureRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerChangePasswordWithSignature(
      passwordChangeSignatureRequest: PasswordChangeSignatureRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .authControllerChangePasswordWithSignature(passwordChangeSignatureRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {AuthLoginRequest} authLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerLogin(authLoginRequest: AuthLoginRequest, options?: any): AxiosPromise<AuthLoginResponse> {
      return localVarFp.authControllerLogin(authLoginRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {PasswordChangeMailRequest} passwordChangeMailRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerSendPasswordChangeMail(
      passwordChangeMailRequest: PasswordChangeMailRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .authControllerSendPasswordChangeMail(passwordChangeMailRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
  /**
   *
   * @param {PasswordChangeRequest} passwordChangeRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authControllerChangePassword(passwordChangeRequest: PasswordChangeRequest, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .authControllerChangePassword(passwordChangeRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PasswordChangeSignatureRequest} passwordChangeSignatureRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authControllerChangePasswordWithSignature(
    passwordChangeSignatureRequest: PasswordChangeSignatureRequest,
    options?: AxiosRequestConfig
  ) {
    return AuthApiFp(this.configuration)
      .authControllerChangePasswordWithSignature(passwordChangeSignatureRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AuthLoginRequest} authLoginRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authControllerLogin(authLoginRequest: AuthLoginRequest, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .authControllerLogin(authLoginRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PasswordChangeMailRequest} passwordChangeMailRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authControllerSendPasswordChangeMail(
    passwordChangeMailRequest: PasswordChangeMailRequest,
    options?: AxiosRequestConfig
  ) {
    return AuthApiFp(this.configuration)
      .authControllerSendPasswordChangeMail(passwordChangeMailRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CompaniesApi - axios parameter creator
 * @export
 */
export const CompaniesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerAgreeTerms: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/companies/terms/agree`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ChangeRetentionPeriodRequest} changeRetentionPeriodRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerChangeRetentionPeriod: async (
      changeRetentionPeriodRequest: ChangeRetentionPeriodRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'changeRetentionPeriodRequest' is not null or undefined
      assertParamExists(
        'companyControllerChangeRetentionPeriod',
        'changeRetentionPeriodRequest',
        changeRetentionPeriodRequest
      );
      const localVarPath = `/companies/self/period`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        changeRetentionPeriodRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerDisableTraining: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/companies/self/training/disable`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerEnableTraining: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/companies/self/training/enable`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetCompany: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/companies/self`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetCompanyRepresentativeUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/companies/self/representative`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetPlan: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/companies/self/plan`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetPlanAtLastMonth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/companies/self/plan/lastmonth`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetUserList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/companies/self/users/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CompaniesApi - functional programming interface
 * @export
 */
export const CompaniesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CompaniesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyControllerAgreeTerms(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerAgreeTerms(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ChangeRetentionPeriodRequest} changeRetentionPeriodRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyControllerChangeRetentionPeriod(
      changeRetentionPeriodRequest: ChangeRetentionPeriodRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerChangeRetentionPeriod(
        changeRetentionPeriodRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyControllerDisableTraining(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerDisableTraining(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyControllerEnableTraining(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerEnableTraining(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyControllerGetCompany(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerGetCompany(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyControllerGetCompanyRepresentativeUser(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCompanyRepresentativeUserResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerGetCompanyRepresentativeUser(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyControllerGetPlan(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Plan>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerGetPlan(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyControllerGetPlanAtLastMonth(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Plan>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerGetPlanAtLastMonth(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyControllerGetUserList(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerGetUserList(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CompaniesApi - factory interface
 * @export
 */
export const CompaniesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = CompaniesApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerAgreeTerms(options?: any): AxiosPromise<void> {
      return localVarFp.companyControllerAgreeTerms(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {ChangeRetentionPeriodRequest} changeRetentionPeriodRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerChangeRetentionPeriod(
      changeRetentionPeriodRequest: ChangeRetentionPeriodRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .companyControllerChangeRetentionPeriod(changeRetentionPeriodRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerDisableTraining(options?: any): AxiosPromise<void> {
      return localVarFp.companyControllerDisableTraining(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerEnableTraining(options?: any): AxiosPromise<void> {
      return localVarFp.companyControllerEnableTraining(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetCompany(options?: any): AxiosPromise<Company> {
      return localVarFp.companyControllerGetCompany(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetCompanyRepresentativeUser(options?: any): AxiosPromise<GetCompanyRepresentativeUserResponse> {
      return localVarFp
        .companyControllerGetCompanyRepresentativeUser(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetPlan(options?: any): AxiosPromise<Plan> {
      return localVarFp.companyControllerGetPlan(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetPlanAtLastMonth(options?: any): AxiosPromise<Plan> {
      return localVarFp.companyControllerGetPlanAtLastMonth(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetUserList(options?: any): AxiosPromise<Array<User>> {
      return localVarFp.companyControllerGetUserList(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * CompaniesApi - object-oriented interface
 * @export
 * @class CompaniesApi
 * @extends {BaseAPI}
 */
export class CompaniesApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companyControllerAgreeTerms(options?: AxiosRequestConfig) {
    return CompaniesApiFp(this.configuration)
      .companyControllerAgreeTerms(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ChangeRetentionPeriodRequest} changeRetentionPeriodRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companyControllerChangeRetentionPeriod(
    changeRetentionPeriodRequest: ChangeRetentionPeriodRequest,
    options?: AxiosRequestConfig
  ) {
    return CompaniesApiFp(this.configuration)
      .companyControllerChangeRetentionPeriod(changeRetentionPeriodRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companyControllerDisableTraining(options?: AxiosRequestConfig) {
    return CompaniesApiFp(this.configuration)
      .companyControllerDisableTraining(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companyControllerEnableTraining(options?: AxiosRequestConfig) {
    return CompaniesApiFp(this.configuration)
      .companyControllerEnableTraining(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companyControllerGetCompany(options?: AxiosRequestConfig) {
    return CompaniesApiFp(this.configuration)
      .companyControllerGetCompany(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companyControllerGetCompanyRepresentativeUser(options?: AxiosRequestConfig) {
    return CompaniesApiFp(this.configuration)
      .companyControllerGetCompanyRepresentativeUser(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companyControllerGetPlan(options?: AxiosRequestConfig) {
    return CompaniesApiFp(this.configuration)
      .companyControllerGetPlan(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companyControllerGetPlanAtLastMonth(options?: AxiosRequestConfig) {
    return CompaniesApiFp(this.configuration)
      .companyControllerGetPlanAtLastMonth(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companyControllerGetUserList(options?: AxiosRequestConfig) {
    return CompaniesApiFp(this.configuration)
      .companyControllerGetUserList(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CompanyUsageApi - axios parameter creator
 * @export
 */
export const CompanyUsageApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyUsageControllerGetBilling: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/companies/self/billing`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [since]
     * @param {string} [until]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyUsageControllerGetCompanyUsage: async (
      since?: string,
      until?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/companies/self/usage`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (since !== undefined) {
        localVarQueryParameter['since'] = since;
      }

      if (until !== undefined) {
        localVarQueryParameter['until'] = until;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyUsageControllerGetCompanyUsageAtNow: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/companies/self/usage/now`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [since]
     * @param {string} [until]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyUsageControllerGetStatisticsPerForm: async (
      since?: string,
      until?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/companies/self/statistics/form`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (since !== undefined) {
        localVarQueryParameter['since'] = since;
      }

      if (until !== undefined) {
        localVarQueryParameter['until'] = until;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [since]
     * @param {string} [until]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyUsageControllerGetStatisticsPerUser: async (
      since?: string,
      until?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/companies/self/statistics/user`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (since !== undefined) {
        localVarQueryParameter['since'] = since;
      }

      if (until !== undefined) {
        localVarQueryParameter['until'] = until;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CompanyUsageApi - functional programming interface
 * @export
 */
export const CompanyUsageApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CompanyUsageApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyUsageControllerGetBilling(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyUsage>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyUsageControllerGetBilling(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} [since]
     * @param {string} [until]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyUsageControllerGetCompanyUsage(
      since?: string,
      until?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyUsage>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyUsageControllerGetCompanyUsage(
        since,
        until,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyUsageControllerGetCompanyUsageAtNow(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyUsage>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyUsageControllerGetCompanyUsageAtNow(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} [since]
     * @param {string} [until]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyUsageControllerGetStatisticsPerForm(
      since?: string,
      until?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStatisticsPerFormResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyUsageControllerGetStatisticsPerForm(
        since,
        until,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} [since]
     * @param {string} [until]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyUsageControllerGetStatisticsPerUser(
      since?: string,
      until?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStatisticsPerUserResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyUsageControllerGetStatisticsPerUser(
        since,
        until,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CompanyUsageApi - factory interface
 * @export
 */
export const CompanyUsageApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = CompanyUsageApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyUsageControllerGetBilling(options?: any): AxiosPromise<CompanyUsage> {
      return localVarFp.companyUsageControllerGetBilling(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [since]
     * @param {string} [until]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyUsageControllerGetCompanyUsage(
      since?: string,
      until?: string,
      options?: any
    ): AxiosPromise<Array<CompanyUsage>> {
      return localVarFp
        .companyUsageControllerGetCompanyUsage(since, until, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyUsageControllerGetCompanyUsageAtNow(options?: any): AxiosPromise<CompanyUsage> {
      return localVarFp.companyUsageControllerGetCompanyUsageAtNow(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [since]
     * @param {string} [until]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyUsageControllerGetStatisticsPerForm(
      since?: string,
      until?: string,
      options?: any
    ): AxiosPromise<GetStatisticsPerFormResponse> {
      return localVarFp
        .companyUsageControllerGetStatisticsPerForm(since, until, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [since]
     * @param {string} [until]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyUsageControllerGetStatisticsPerUser(
      since?: string,
      until?: string,
      options?: any
    ): AxiosPromise<GetStatisticsPerUserResponse> {
      return localVarFp
        .companyUsageControllerGetStatisticsPerUser(since, until, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CompanyUsageApi - object-oriented interface
 * @export
 * @class CompanyUsageApi
 * @extends {BaseAPI}
 */
export class CompanyUsageApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanyUsageApi
   */
  public companyUsageControllerGetBilling(options?: AxiosRequestConfig) {
    return CompanyUsageApiFp(this.configuration)
      .companyUsageControllerGetBilling(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [since]
   * @param {string} [until]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanyUsageApi
   */
  public companyUsageControllerGetCompanyUsage(since?: string, until?: string, options?: AxiosRequestConfig) {
    return CompanyUsageApiFp(this.configuration)
      .companyUsageControllerGetCompanyUsage(since, until, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanyUsageApi
   */
  public companyUsageControllerGetCompanyUsageAtNow(options?: AxiosRequestConfig) {
    return CompanyUsageApiFp(this.configuration)
      .companyUsageControllerGetCompanyUsageAtNow(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [since]
   * @param {string} [until]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanyUsageApi
   */
  public companyUsageControllerGetStatisticsPerForm(since?: string, until?: string, options?: AxiosRequestConfig) {
    return CompanyUsageApiFp(this.configuration)
      .companyUsageControllerGetStatisticsPerForm(since, until, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [since]
   * @param {string} [until]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanyUsageApi
   */
  public companyUsageControllerGetStatisticsPerUser(since?: string, until?: string, options?: AxiosRequestConfig) {
    return CompanyUsageApiFp(this.configuration)
      .companyUsageControllerGetStatisticsPerUser(since, until, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerGetHello: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/hello`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appControllerGetHello(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerGetHello(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerGetHello(options?: any): AxiosPromise<string> {
      return localVarFp.appControllerGetHello(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public appControllerGetHello(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .appControllerGetHello(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DocumentApi - axios parameter creator
 * @export
 */
export const DocumentApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} group
     * @param {ChangeFileNameRequest} changeFileNameRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerChangeFileName: async (
      group: string,
      changeFileNameRequest: ChangeFileNameRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'group' is not null or undefined
      assertParamExists('documentControllerChangeFileName', 'group', group);
      // verify required parameter 'changeFileNameRequest' is not null or undefined
      assertParamExists('documentControllerChangeFileName', 'changeFileNameRequest', changeFileNameRequest);
      const localVarPath = `/documents/{group}/file/change/name`.replace(
        `{${'group'}}`,
        encodeURIComponent(String(group))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(changeFileNameRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerCombineDocuments: async (
      group: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'group' is not null or undefined
      assertParamExists('documentControllerCombineDocuments', 'group', group);
      const localVarPath = `/documents/combine/{group}`.replace(`{${'group'}}`, encodeURIComponent(String(group)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} group
     * @param {string} document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerDeleteDocument: async (
      group: string,
      document: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'group' is not null or undefined
      assertParamExists('documentControllerDeleteDocument', 'group', group);
      // verify required parameter 'document' is not null or undefined
      assertParamExists('documentControllerDeleteDocument', 'document', document);
      const localVarPath = `/documents/{group}/{document}`
        .replace(`{${'group'}}`, encodeURIComponent(String(group)))
        .replace(`{${'document'}}`, encodeURIComponent(String(document)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} group
     * @param {string} document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerDisableTrainingUse: async (
      group: string,
      document: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'group' is not null or undefined
      assertParamExists('documentControllerDisableTrainingUse', 'group', group);
      // verify required parameter 'document' is not null or undefined
      assertParamExists('documentControllerDisableTrainingUse', 'document', document);
      const localVarPath = `/documents/{group}/{document}/training/disable`
        .replace(`{${'group'}}`, encodeURIComponent(String(group)))
        .replace(`{${'document'}}`, encodeURIComponent(String(document)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} group
     * @param {string} document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerEnableTrainingUse: async (
      group: string,
      document: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'group' is not null or undefined
      assertParamExists('documentControllerEnableTrainingUse', 'group', group);
      // verify required parameter 'document' is not null or undefined
      assertParamExists('documentControllerEnableTrainingUse', 'document', document);
      const localVarPath = `/documents/{group}/{document}/training/enable`
        .replace(`{${'group'}}`, encodeURIComponent(String(group)))
        .replace(`{${'document'}}`, encodeURIComponent(String(document)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GetPresignedUrlRequest} getPresignedUrlRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerGetCombineSourceUploadPresignedUrl: async (
      getPresignedUrlRequest: GetPresignedUrlRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'getPresignedUrlRequest' is not null or undefined
      assertParamExists(
        'documentControllerGetCombineSourceUploadPresignedUrl',
        'getPresignedUrlRequest',
        getPresignedUrlRequest
      );
      const localVarPath = `/documents/combine/presigned_url`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        getPresignedUrlRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} group
     * @param {string} document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerGetDividedImagePresignedUrl: async (
      group: string,
      document: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'group' is not null or undefined
      assertParamExists('documentControllerGetDividedImagePresignedUrl', 'group', group);
      // verify required parameter 'document' is not null or undefined
      assertParamExists('documentControllerGetDividedImagePresignedUrl', 'document', document);
      const localVarPath = `/documents/{group}/{document}/image`
        .replace(`{${'group'}}`, encodeURIComponent(String(group)))
        .replace(`{${'document'}}`, encodeURIComponent(String(document)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} group
     * @param {string} document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerGetDocument: async (
      group: string,
      document: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'group' is not null or undefined
      assertParamExists('documentControllerGetDocument', 'group', group);
      // verify required parameter 'document' is not null or undefined
      assertParamExists('documentControllerGetDocument', 'document', document);
      const localVarPath = `/documents/{group}/{document}`
        .replace(`{${'group'}}`, encodeURIComponent(String(group)))
        .replace(`{${'document'}}`, encodeURIComponent(String(document)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerGetDocumentGroupById: async (
      group: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'group' is not null or undefined
      assertParamExists('documentControllerGetDocumentGroupById', 'group', group);
      const localVarPath = `/documents/{group}`.replace(`{${'group'}}`, encodeURIComponent(String(group)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerGetDocuments: async (group: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'group' is not null or undefined
      assertParamExists('documentControllerGetDocuments', 'group', group);
      const localVarPath = `/documents/{group}/list`.replace(`{${'group'}}`, encodeURIComponent(String(group)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GetPresignedUrlRequest} getPresignedUrlRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerGetPdfUploadPresignedUrl: async (
      getPresignedUrlRequest: GetPresignedUrlRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'getPresignedUrlRequest' is not null or undefined
      assertParamExists('documentControllerGetPdfUploadPresignedUrl', 'getPresignedUrlRequest', getPresignedUrlRequest);
      const localVarPath = `/documents/presigned_url`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        getPresignedUrlRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} group
     * @param {string} document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerHasTrainingDocumentPermission: async (
      group: string,
      document: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'group' is not null or undefined
      assertParamExists('documentControllerHasTrainingDocumentPermission', 'group', group);
      // verify required parameter 'document' is not null or undefined
      assertParamExists('documentControllerHasTrainingDocumentPermission', 'document', document);
      const localVarPath = `/documents/{group}/{document}/training/permission`
        .replace(`{${'group'}}`, encodeURIComponent(String(group)))
        .replace(`{${'document'}}`, encodeURIComponent(String(document)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} group
     * @param {string} document
     * @param {PatchDocumentRequest} patchDocumentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerPatchDocument: async (
      group: string,
      document: string,
      patchDocumentRequest: PatchDocumentRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'group' is not null or undefined
      assertParamExists('documentControllerPatchDocument', 'group', group);
      // verify required parameter 'document' is not null or undefined
      assertParamExists('documentControllerPatchDocument', 'document', document);
      // verify required parameter 'patchDocumentRequest' is not null or undefined
      assertParamExists('documentControllerPatchDocument', 'patchDocumentRequest', patchDocumentRequest);
      const localVarPath = `/documents/{group}/{document}`
        .replace(`{${'group'}}`, encodeURIComponent(String(group)))
        .replace(`{${'document'}}`, encodeURIComponent(String(document)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(patchDocumentRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {DocumentType} [ocrType]
     * @param {string} [userId]
     * @param {string} [since]
     * @param {string} [until]
     * @param {string} [fileName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerSearch: async (
      ocrType?: DocumentType,
      userId?: string,
      since?: string,
      until?: string,
      fileName?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/documents/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (ocrType !== undefined) {
        localVarQueryParameter['ocr_type'] = ocrType;
      }

      if (userId !== undefined) {
        localVarQueryParameter['user_id'] = userId;
      }

      if (since !== undefined) {
        localVarQueryParameter['since'] = since;
      }

      if (until !== undefined) {
        localVarQueryParameter['until'] = until;
      }

      if (fileName !== undefined) {
        localVarQueryParameter['fileName'] = fileName;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} group
     * @param {string} document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerSeeDocument: async (
      group: string,
      document: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'group' is not null or undefined
      assertParamExists('documentControllerSeeDocument', 'group', group);
      // verify required parameter 'document' is not null or undefined
      assertParamExists('documentControllerSeeDocument', 'document', document);
      const localVarPath = `/documents/{group}/{document}/see`
        .replace(`{${'group'}}`, encodeURIComponent(String(group)))
        .replace(`{${'document'}}`, encodeURIComponent(String(document)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DocumentApi - functional programming interface
 * @export
 */
export const DocumentApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DocumentApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} group
     * @param {ChangeFileNameRequest} changeFileNameRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async documentControllerChangeFileName(
      group: string,
      changeFileNameRequest: ChangeFileNameRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.documentControllerChangeFileName(
        group,
        changeFileNameRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async documentControllerCombineDocuments(
      group: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.documentControllerCombineDocuments(group, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} group
     * @param {string} document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async documentControllerDeleteDocument(
      group: string,
      document: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.documentControllerDeleteDocument(
        group,
        document,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} group
     * @param {string} document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async documentControllerDisableTrainingUse(
      group: string,
      document: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.documentControllerDisableTrainingUse(
        group,
        document,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} group
     * @param {string} document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async documentControllerEnableTrainingUse(
      group: string,
      document: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.documentControllerEnableTrainingUse(
        group,
        document,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {GetPresignedUrlRequest} getPresignedUrlRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async documentControllerGetCombineSourceUploadPresignedUrl(
      getPresignedUrlRequest: GetPresignedUrlRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPostFilePresignedUrlsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.documentControllerGetCombineSourceUploadPresignedUrl(
        getPresignedUrlRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} group
     * @param {string} document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async documentControllerGetDividedImagePresignedUrl(
      group: string,
      document: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDividedImagePresignedUrlResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.documentControllerGetDividedImagePresignedUrl(
        group,
        document,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} group
     * @param {string} document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async documentControllerGetDocument(
      group: string,
      document: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.documentControllerGetDocument(group, document, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async documentControllerGetDocumentGroupById(
      group: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDocumentGroupResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.documentControllerGetDocumentGroupById(group, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async documentControllerGetDocuments(
      group: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Document>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.documentControllerGetDocuments(group, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {GetPresignedUrlRequest} getPresignedUrlRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async documentControllerGetPdfUploadPresignedUrl(
      getPresignedUrlRequest: GetPresignedUrlRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPostFilePresignedUrlsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.documentControllerGetPdfUploadPresignedUrl(
        getPresignedUrlRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} group
     * @param {string} document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async documentControllerHasTrainingDocumentPermission(
      group: string,
      document: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.documentControllerHasTrainingDocumentPermission(
        group,
        document,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} group
     * @param {string} document
     * @param {PatchDocumentRequest} patchDocumentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async documentControllerPatchDocument(
      group: string,
      document: string,
      patchDocumentRequest: PatchDocumentRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.documentControllerPatchDocument(
        group,
        document,
        patchDocumentRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {DocumentType} [ocrType]
     * @param {string} [userId]
     * @param {string} [since]
     * @param {string} [until]
     * @param {string} [fileName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async documentControllerSearch(
      ocrType?: DocumentType,
      userId?: string,
      since?: string,
      until?: string,
      fileName?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetSearchResponse>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.documentControllerSearch(
        ocrType,
        userId,
        since,
        until,
        fileName,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} group
     * @param {string} document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async documentControllerSeeDocument(
      group: string,
      document: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.documentControllerSeeDocument(group, document, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DocumentApi - factory interface
 * @export
 */
export const DocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DocumentApiFp(configuration);
  return {
    /**
     *
     * @param {string} group
     * @param {ChangeFileNameRequest} changeFileNameRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerChangeFileName(
      group: string,
      changeFileNameRequest: ChangeFileNameRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .documentControllerChangeFileName(group, changeFileNameRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerCombineDocuments(group: string, options?: any): AxiosPromise<void> {
      return localVarFp.documentControllerCombineDocuments(group, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} group
     * @param {string} document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerDeleteDocument(group: string, document: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .documentControllerDeleteDocument(group, document, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} group
     * @param {string} document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerDisableTrainingUse(group: string, document: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .documentControllerDisableTrainingUse(group, document, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} group
     * @param {string} document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerEnableTrainingUse(group: string, document: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .documentControllerEnableTrainingUse(group, document, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {GetPresignedUrlRequest} getPresignedUrlRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerGetCombineSourceUploadPresignedUrl(
      getPresignedUrlRequest: GetPresignedUrlRequest,
      options?: any
    ): AxiosPromise<GetPostFilePresignedUrlsResponse> {
      return localVarFp
        .documentControllerGetCombineSourceUploadPresignedUrl(getPresignedUrlRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} group
     * @param {string} document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerGetDividedImagePresignedUrl(
      group: string,
      document: string,
      options?: any
    ): AxiosPromise<GetDividedImagePresignedUrlResponse> {
      return localVarFp
        .documentControllerGetDividedImagePresignedUrl(group, document, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} group
     * @param {string} document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerGetDocument(group: string, document: string, options?: any): AxiosPromise<Document> {
      return localVarFp
        .documentControllerGetDocument(group, document, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerGetDocumentGroupById(group: string, options?: any): AxiosPromise<GetDocumentGroupResponse> {
      return localVarFp
        .documentControllerGetDocumentGroupById(group, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerGetDocuments(group: string, options?: any): AxiosPromise<Array<Document>> {
      return localVarFp.documentControllerGetDocuments(group, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {GetPresignedUrlRequest} getPresignedUrlRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerGetPdfUploadPresignedUrl(
      getPresignedUrlRequest: GetPresignedUrlRequest,
      options?: any
    ): AxiosPromise<GetPostFilePresignedUrlsResponse> {
      return localVarFp
        .documentControllerGetPdfUploadPresignedUrl(getPresignedUrlRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} group
     * @param {string} document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerHasTrainingDocumentPermission(
      group: string,
      document: string,
      options?: any
    ): AxiosPromise<boolean> {
      return localVarFp
        .documentControllerHasTrainingDocumentPermission(group, document, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} group
     * @param {string} document
     * @param {PatchDocumentRequest} patchDocumentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerPatchDocument(
      group: string,
      document: string,
      patchDocumentRequest: PatchDocumentRequest,
      options?: any
    ): AxiosPromise<Document> {
      return localVarFp
        .documentControllerPatchDocument(group, document, patchDocumentRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {DocumentType} [ocrType]
     * @param {string} [userId]
     * @param {string} [since]
     * @param {string} [until]
     * @param {string} [fileName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerSearch(
      ocrType?: DocumentType,
      userId?: string,
      since?: string,
      until?: string,
      fileName?: string,
      options?: any
    ): AxiosPromise<Array<GetSearchResponse>> {
      return localVarFp
        .documentControllerSearch(ocrType, userId, since, until, fileName, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} group
     * @param {string} document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerSeeDocument(group: string, document: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .documentControllerSeeDocument(group, document, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DocumentApi - object-oriented interface
 * @export
 * @class DocumentApi
 * @extends {BaseAPI}
 */
export class DocumentApi extends BaseAPI {
  /**
   *
   * @param {string} group
   * @param {ChangeFileNameRequest} changeFileNameRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentApi
   */
  public documentControllerChangeFileName(
    group: string,
    changeFileNameRequest: ChangeFileNameRequest,
    options?: AxiosRequestConfig
  ) {
    return DocumentApiFp(this.configuration)
      .documentControllerChangeFileName(group, changeFileNameRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} group
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentApi
   */
  public documentControllerCombineDocuments(group: string, options?: AxiosRequestConfig) {
    return DocumentApiFp(this.configuration)
      .documentControllerCombineDocuments(group, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} group
   * @param {string} document
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentApi
   */
  public documentControllerDeleteDocument(group: string, document: string, options?: AxiosRequestConfig) {
    return DocumentApiFp(this.configuration)
      .documentControllerDeleteDocument(group, document, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} group
   * @param {string} document
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentApi
   */
  public documentControllerDisableTrainingUse(group: string, document: string, options?: AxiosRequestConfig) {
    return DocumentApiFp(this.configuration)
      .documentControllerDisableTrainingUse(group, document, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} group
   * @param {string} document
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentApi
   */
  public documentControllerEnableTrainingUse(group: string, document: string, options?: AxiosRequestConfig) {
    return DocumentApiFp(this.configuration)
      .documentControllerEnableTrainingUse(group, document, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {GetPresignedUrlRequest} getPresignedUrlRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentApi
   */
  public documentControllerGetCombineSourceUploadPresignedUrl(
    getPresignedUrlRequest: GetPresignedUrlRequest,
    options?: AxiosRequestConfig
  ) {
    return DocumentApiFp(this.configuration)
      .documentControllerGetCombineSourceUploadPresignedUrl(getPresignedUrlRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} group
   * @param {string} document
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentApi
   */
  public documentControllerGetDividedImagePresignedUrl(group: string, document: string, options?: AxiosRequestConfig) {
    return DocumentApiFp(this.configuration)
      .documentControllerGetDividedImagePresignedUrl(group, document, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} group
   * @param {string} document
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentApi
   */
  public documentControllerGetDocument(group: string, document: string, options?: AxiosRequestConfig) {
    return DocumentApiFp(this.configuration)
      .documentControllerGetDocument(group, document, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} group
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentApi
   */
  public documentControllerGetDocumentGroupById(group: string, options?: AxiosRequestConfig) {
    return DocumentApiFp(this.configuration)
      .documentControllerGetDocumentGroupById(group, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} group
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentApi
   */
  public documentControllerGetDocuments(group: string, options?: AxiosRequestConfig) {
    return DocumentApiFp(this.configuration)
      .documentControllerGetDocuments(group, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {GetPresignedUrlRequest} getPresignedUrlRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentApi
   */
  public documentControllerGetPdfUploadPresignedUrl(
    getPresignedUrlRequest: GetPresignedUrlRequest,
    options?: AxiosRequestConfig
  ) {
    return DocumentApiFp(this.configuration)
      .documentControllerGetPdfUploadPresignedUrl(getPresignedUrlRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} group
   * @param {string} document
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentApi
   */
  public documentControllerHasTrainingDocumentPermission(
    group: string,
    document: string,
    options?: AxiosRequestConfig
  ) {
    return DocumentApiFp(this.configuration)
      .documentControllerHasTrainingDocumentPermission(group, document, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} group
   * @param {string} document
   * @param {PatchDocumentRequest} patchDocumentRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentApi
   */
  public documentControllerPatchDocument(
    group: string,
    document: string,
    patchDocumentRequest: PatchDocumentRequest,
    options?: AxiosRequestConfig
  ) {
    return DocumentApiFp(this.configuration)
      .documentControllerPatchDocument(group, document, patchDocumentRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DocumentType} [ocrType]
   * @param {string} [userId]
   * @param {string} [since]
   * @param {string} [until]
   * @param {string} [fileName]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentApi
   */
  public documentControllerSearch(
    ocrType?: DocumentType,
    userId?: string,
    since?: string,
    until?: string,
    fileName?: string,
    options?: AxiosRequestConfig
  ) {
    return DocumentApiFp(this.configuration)
      .documentControllerSearch(ocrType, userId, since, until, fileName, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} group
   * @param {string} document
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentApi
   */
  public documentControllerSeeDocument(group: string, document: string, options?: AxiosRequestConfig) {
    return DocumentApiFp(this.configuration)
      .documentControllerSeeDocument(group, document, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CreateUserRequest} createUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerCreateUser: async (
      createUserRequest: CreateUserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createUserRequest' is not null or undefined
      assertParamExists('userControllerCreateUser', 'createUserRequest', createUserRequest);
      const localVarPath = `/users/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createUserRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerDeleteUser: async (user: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'user' is not null or undefined
      assertParamExists('userControllerDeleteUser', 'user', user);
      const localVarPath = `/users/{user}`.replace(`{${'user'}}`, encodeURIComponent(String(user)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} user
     * @param {EditUserRequest} editUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerEditUser: async (
      user: string,
      editUserRequest: EditUserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'user' is not null or undefined
      assertParamExists('userControllerEditUser', 'user', user);
      // verify required parameter 'editUserRequest' is not null or undefined
      assertParamExists('userControllerEditUser', 'editUserRequest', editUserRequest);
      const localVarPath = `/users/{user}`.replace(`{${'user'}}`, encodeURIComponent(String(user)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(editUserRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerGetMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/users/me`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerGetUser: async (user: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'user' is not null or undefined
      assertParamExists('userControllerGetUser', 'user', user);
      const localVarPath = `/users/{user}`.replace(`{${'user'}}`, encodeURIComponent(String(user)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {Array<string>} userIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerGetUsers: async (userIds: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'userIds' is not null or undefined
      assertParamExists('userControllerGetUsers', 'userIds', userIds);
      const localVarPath = `/users/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (userIds) {
        localVarQueryParameter['userIds'] = userIds;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateUserRequest} createUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userControllerCreateUser(
      createUserRequest: CreateUserRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateUserResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerCreateUser(createUserRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userControllerDeleteUser(
      user: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerDeleteUser(user, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} user
     * @param {EditUserRequest} editUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userControllerEditUser(
      user: string,
      editUserRequest: EditUserRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerEditUser(user, editUserRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userControllerGetMe(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMeResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetMe(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userControllerGetUser(
      user: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetUser(user, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {Array<string>} userIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userControllerGetUsers(
      userIds: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUsersResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetUsers(userIds, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = UsersApiFp(configuration);
  return {
    /**
     *
     * @param {CreateUserRequest} createUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerCreateUser(createUserRequest: CreateUserRequest, options?: any): AxiosPromise<CreateUserResponse> {
      return localVarFp
        .userControllerCreateUser(createUserRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerDeleteUser(user: string, options?: any): AxiosPromise<User> {
      return localVarFp.userControllerDeleteUser(user, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} user
     * @param {EditUserRequest} editUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerEditUser(user: string, editUserRequest: EditUserRequest, options?: any): AxiosPromise<void> {
      return localVarFp
        .userControllerEditUser(user, editUserRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerGetMe(options?: any): AxiosPromise<GetMeResponse> {
      return localVarFp.userControllerGetMe(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerGetUser(user: string, options?: any): AxiosPromise<User> {
      return localVarFp.userControllerGetUser(user, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {Array<string>} userIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerGetUsers(userIds: Array<string>, options?: any): AxiosPromise<GetUsersResponse> {
      return localVarFp.userControllerGetUsers(userIds, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
  /**
   *
   * @param {CreateUserRequest} createUserRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public userControllerCreateUser(createUserRequest: CreateUserRequest, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .userControllerCreateUser(createUserRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public userControllerDeleteUser(user: string, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .userControllerDeleteUser(user, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} user
   * @param {EditUserRequest} editUserRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public userControllerEditUser(user: string, editUserRequest: EditUserRequest, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .userControllerEditUser(user, editUserRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public userControllerGetMe(options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .userControllerGetMe(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public userControllerGetUser(user: string, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .userControllerGetUser(user, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {Array<string>} userIds
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public userControllerGetUsers(userIds: Array<string>, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .userControllerGetUsers(userIds, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
