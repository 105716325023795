import { Radio, RadioChangeEvent } from 'antd';
import { useCallback } from 'react';
import { useAsyncRetry } from 'react-use';
import styled from 'styled-components';

import { companiesApi } from '../../api/apiClient';
import { notifyError, notifySuccess } from '../../utils/notification';
import { SettingItemHeaderLabel } from './SettingItemHeaderLabel';
import { SettingItemLayout } from './SettingItemLayout';

const RadioButtonForm = styled.div`
  display: flex;
`;
const FormLabel = styled.p`
  margin-right: 16px;
`;

const TrainingPermissionDefaultSetting = () => {
  const { retry, value } = useAsyncRetry(async () => {
    const { data } = await companiesApi.companyControllerGetCompany();
    return data.enable_training ?? false;
  });
  const onChange = useCallback(
    async (e: RadioChangeEvent) => {
      const enable = e.target.value;
      try {
        if (enable) {
          await companiesApi.companyControllerEnableTraining();
        } else {
          await companiesApi.companyControllerDisableTraining();
        }
        notifySuccess('許可設定を変更しました');
        retry();
      } catch (e) {
        notifyError('許可設定の変更に失敗しました');
      }
    },
    [retry]
  );
  return (
    <SettingItemLayout>
      <SettingItemHeaderLabel>アップロードしたデータの学習への使用についての設定</SettingItemHeaderLabel>
      <p>この設定がデフォルトの設定となります。領収書毎に個別に設定することができます。</p>
      <RadioButtonForm>
        <FormLabel>領収書のデータ</FormLabel>
        <Radio.Group value={value} onChange={onChange}>
          <Radio value={true}>使用を許可する</Radio>
          <Radio value={false}>使用を許可しない</Radio>
        </Radio.Group>
      </RadioButtonForm>
    </SettingItemLayout>
  );
};

export default TrainingPermissionDefaultSetting;
