import { ReactElement } from 'react';
import styled from 'styled-components';

import { FailedIcon, SuccessIcon } from '../../components/Icons/StatusIcon';
import LoadingDot from '../../components/Loading/LoadingDot';
import LoadingIcon from '../../components/Loading/LoadingIcon';

export type DocumentStatusType = 'completed' | 'loading' | 'forbidden';

interface Props {
  status: DocumentStatusType;
}

const Status = styled.div`
  margin-top: 20px;
  height: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export default function DocumentStatus(props: Props): ReactElement {
  const { status } = props;
  switch (status) {
    case 'completed':
      return (
        <Status>
          <SuccessIcon /> ファイルの読み取りが完了しました。
        </Status>
      );
    case 'loading':
      return (
        <Status>
          <LoadingIcon style={{ color: '#ccc' }} /> ファイルを読み取っています <LoadingDot />
        </Status>
      );
    case 'forbidden':
      return (
        <Status>
          <FailedIcon /> 閲覧権限がありません。
        </Status>
      );
    default:
      return <></>;
  }
}
