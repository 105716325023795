import { useCallback, useEffect, useRef, useState } from 'react';

import { DocumentType } from '../../generated-api';
import { Paths } from '../../types/Paths';
import getPropertyOf from '../../utils/getPropertyOf';
import ocrResultPageDefinitions from './defs';
import { StrictColumnType } from './defs/types';

type PageDefinition = typeof ocrResultPageDefinitions[DocumentType];

export default function useResultFilter<ResultType = unknown>(
  ocrResults: ResultType[] | null,
  def: PageDefinition,
  tabIndex: number
) {
  const filterItems = useRef(
    def.tabs.map((tab) =>
      (tab.tableColumns as StrictColumnType<unknown>[])
        .filter((v) => v.searchFilter == true)
        .reduce<Record<string, string>>(
          (acc, curr) => ({
            ...acc,
            [curr.dataIndex.join()]: '',
          }),
          {}
        )
    )
  );

  const applyFilter = useCallback(
    <T,>(tabIndex: number, dataIndex: Paths<T> | undefined, value: string | undefined) => {
      if (dataIndex != null) {
        filterItems.current[tabIndex][dataIndex.join()] =
          value ?? filterItems.current[tabIndex][dataIndex.join()] ?? '';
      }

      const result = ocrResults?.filter((v) =>
        (def.tabs[tabIndex].tableColumns as StrictColumnType<unknown>[]).every((col) => {
          const filter = filterItems.current[tabIndex][col.dataIndex.join()];
          const value = getPropertyOf(v, col.dataIndex as never) as string;
          if (filter == null || filter.length === 0 || value == null) {
            return true;
          }
          return value.length > 0 && value.includes(filter);
        })
      );
      setFilteredResults(result);
    },
    [ocrResults, def.tabs]
  );

  const [filteredResults, setFilteredResults] = useState(ocrResults ?? undefined);

  useEffect(() => {
    // タブが切り替わったらフィルターをリセット
    filterItems.current.forEach((item, index) => {
      Object.keys(item).forEach((key) => {
        filterItems.current[index][key] = '';
      });
    });
  }, [tabIndex]);

  useEffect(() => {
    if (ocrResults != null && filteredResults == null) {
      setFilteredResults(ocrResults);
    }
  }, [filteredResults, ocrResults]);

  return { filteredResults, applyFilter, filterItems: filterItems.current };
}
